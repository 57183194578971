import axios from 'axios';

import { AUTH_ERROR, CLEAR_PROFILE, LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT, OTP_VERIFIY, OTP_VERIFIY_FAIL, REGISTER_FAIL, REGISTER_SUCCESS } from "../type/types";

import swal from 'sweetalert';
import { Url, saveTokenInLocalStorage, userLoginData } from '../../utils/Common';


// Otp Verification
export const OtpVerify = (mobileNo, registerType) => async dispatch => {

    const formData = {
        action: "Otp Send",
        mobileNo: mobileNo,
        registerType: registerType
    }
    axios.post(`${Url}login`, formData).then((response) => {
        swal(response?.data?.message, response?.data?.alert, "success");
        dispatch({
            payload: response,
            type: OTP_VERIFIY
        })
    }).catch((err) => {
        swal(err?.response.message, err?.response.alert, "info");
        dispatch({
            payload: err.response,
            type: OTP_VERIFIY_FAIL
        })
    })
}
// Login Verification
export const Login = (mobileNo, otp, registerType, history) => async dispatch => {
    const formData = {
        action: "Otp Verify",
        mobileNo: mobileNo,
        otp: otp,
        registerType: registerType
    }
    axios.post(`${Url}login`, formData).then((response) => {

        //console.log(`Login response = ${JSON.stringify(response)}`)

        if (response.data) {
            saveTokenInLocalStorage(response?.data?.token)
            userLoginData(response?.data?.data)
        }

        dispatch({
            payload: response,
            type: LOGIN_SUCCESS
        })

        if (response?.data?.message === "Success") {
            swal(response?.data?.message, "You have successfully logged in!", "success");
            history.push("/AdminMaincontent")
        } else {
            swal("Oops!", "Something went wrong!", "error");
        }
        // history.push("/Navigation")

        // if (response?.data?.data[0].registerType == 1) {
        //     history.push("/AdminMaincontent")
        // } else if (response?.data?.data[0].registerType == 2) {
        //     history.push("/LandOwnerMaincontent")
        // } else if (response?.data?.data[0].registerType == 3) {
        //     history.push("/UsersMaincontent")
        // } else if (response?.data?.data[0].registerType == 4) {
        //     history.push("/StaffMaincontent")
        // }

    }).catch((err) => {
        dispatch({
            payload: err,
            type: LOGIN_FAIL
        })
    })
}

// Employee Sign In
export const MemberSignIn = (registerType, userName, companyName, mobileNo, history) => async dispatch => {
    const formData = {
        companyName: companyName,
        userName: userName,
        mobileNo: mobileNo,
        registerType: registerType,
        inputSource: "Android",
        loginUserId: "12",
        remarks: "karthi",
        action: "Sign Up"
    }
    axios.post(`${Url}login`, formData).then((response) => {
        if (response.data) {
            // saveTokenInLocalStorage(response?.data?.data?.token)
            // userLoginData(response?.data?.data?.member)
        }
        dispatch({
            payload: response,
            type: LOGIN_SUCCESS
        })
        history("/demo/")
    }).catch((err) => {
        dispatch({
            payload: err,
            type: LOGIN_FAIL
        })
    })
}
