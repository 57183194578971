import React from 'react'
import VastuPlanListTable from './VastuPlanListTable'

const VastuPlan = () => {
    return (
        <>
            <p className='listings-heading'>Vastu Plan</p>
            <VastuPlanListTable />
        </>
    )
}

export default VastuPlan