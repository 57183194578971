import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { TiArrowBack } from 'react-icons/ti';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { memberSignUp } from '../../../../store/action/MemberAction';

export default function AddOwner(props, { setShowAddLandOwnerModel }) {

    const dispatch = useDispatch();
    const data = props?.location?.state?.memberData
    let history = useHistory();
    const [errors, setErrors] = useState({});
    const [bodyData, setBodyData] = useState({
        companyName: data?.companyName || "",
        userName: data?.userName || "",
        mobileNo: data?.mobileNo.toString() || "",
        registerType: data?.registerType || 2,
        inputSource: "Android",
        loginUserId: data?.loginUserId || "",
        remarks: "",
        action: "Sign Up",
        rfId: data?.rfId || ""
    });

    const { userName, mobileNo } = bodyData;

    const handleAddFormSubmit = async (e) => {
        await dispatch(memberSignUp(bodyData, history))

    }
    const handleError = (error, input) => {
        setErrors(prevState => ({ ...prevState, [input]: error }));
    };

    const validate = (e) => {
        e.preventDefault();
        let isValid = true;

        if (!bodyData.userName || bodyData.userName.length < 3) {
            handleError('Name must be min 3 character long', 'userName');
            isValid = false;
        }

        if (!bodyData.mobileNo || bodyData.mobileNo.length !== 10 || parseInt(bodyData.mobileNo.charAt(0)) < 5) {
            handleError('Enter a valid Phone Number', 'mobileNo');
            isValid = false;
        }

        if (isValid) {
            handleAddFormSubmit(e);
        }
    }

    const onChange = e => {
        setBodyData({ ...bodyData, [e.target.name]: e.target.value });
    }

    return (

        <>
            <div className='d-flex mt-2'>
                <Button className='p-0'><TiArrowBack onClick={() => history.goBack()} size={35} /></Button>
                <p className='listings-headingWithBack'>{data ? "Update Landowner" : "Add Landowner"}</p>
            </div>
            <form onSubmit={validate} className="row g-3 property-form ml-2 mr-2 mt-3">
                <div className="col mb-3">
                    {errors.userName ?
                        <label htmlFor="userName" className="error-text required">{errors.userName}</label> :
                        <label htmlFor="userName" className="text-black">Landowner Name<span className="required">*</span> </label>
                    }
                    <input
                        type="text"
                        className="form-control form-input-holder"
                        id="userName" name='userName'
                        value={userName}
                        onChange={e => onChange(e)}
                        placeholder="Enter Landowner Name"
                        noValidate
                        onFocus={() => handleError(null, 'userName')}
                    />
                </div>
                <div className="col mb-3">
                    {errors.mobileNo ?
                        <label htmlFor="mobileNo" className="error-text">{errors.mobileNo}</label> :
                        <label htmlFor="mobileNo" className="text-black">Mobile Number<span className="required">*</span> </label>
                    }
                    <input
                        type="number"
                        className="form-control form-input-holder"
                        id="mobileNo" name='mobileNo'
                        value={mobileNo}
                        onChange={e => onChange(e)}
                        placeholder="Enter Mobile Number"
                        noValidate
                        onFocus={() => handleError(null, 'mobileNo')}
                    />
                </div>

                <div className="col-md-12 text-center my-5">
                    <button className="btn btn-primary px-5" type="submit">{data ? "Update Landowner" : "Add Landowner"}</button>
                </div>
            </form>
        </>


    )
}
