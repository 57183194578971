import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import MenuIcon from '@mui/icons-material/Menu';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import SearchIcon from '@mui/icons-material/Search';
import { default as Box } from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { default as IconButton } from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import { default as Typography } from '@mui/material/Typography';
import { green } from '@mui/material/colors';
import { alpha, styled } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FcManager } from 'react-icons/fc';
import { IoMdAddCircle } from 'react-icons/io';
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from 'react-router-dom';
import { Dispatch } from 'redux';
import { getStaffDetails } from '../../../../store/action/StaffAction';
import { DtSentenceCase, GetLandUrl } from '../../../../utils/Common';
import AgentDetailPage from '../Agent/AgentDetailPage';
import swal from 'sweetalert';
import Loader1 from '../../../../utils/Loader1'
import { getBookingsData } from '../../../../store/action/BookingsActions';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'image',
        numeric: false,
        label: 'Image',
    },
    {
        id: 'layoutName',
        numeric: false,
        label: 'Property Name',
    },
    {
        id: 'address',
        numeric: false,
        label: 'Address',
    },
    {
        id: 'landOwnerUserName',
        numeric: false,
        label: 'Landowner',
    },
    {
        id: 'userName',
        numeric: false,
        label: 'Booked By',
    },
    {
        id: 'insertedDateTime',
        numeric: false,
        label: 'Booked Date & Time',
    },
];


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        border: '1px solid lightgrey',
        borderRadius: '5px',
        height: '15px',
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));


function ListingDataTableHead(props) {

    const { order, orderBy, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'center'}
                        sortDirection={orderBy === headCell.id ? order : false}

                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

ListingDataTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function ListingDataTableToolbar({ search, setSearch }) {

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
    };

    return (
        <div className='d-flex justify-content-between mx-5'>
            <Typography>
                <Search>
                    <SearchIconWrapper>
                        <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="Search…"
                        value={search}
                        onChange={handleSearchChange}
                    />
                </Search>
            </Typography>
        </div>
    );
}

ListingDataTableToolbar.propTypes = {
    search: PropTypes.string.isRequired,
    setSearch: PropTypes.func.isRequired,
};

export default function BookingsListTable() {

    const [open, setOpen] = useState(false);

    const dispatch = useDispatch();
    const selector = useSelector(state => state?.BookingsReducer);
    const loading = selector.loading;
    const history = useHistory();

    const [bookingsData, setBookingsData] = useState([]);
    const [search, setSearch] = useState('');

    const fetchData = async () => {
        await dispatch(getBookingsData())
    }

    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        if (selector?.viewList?.data?.message === "Success") {
            setBookingsData(selector?.viewList?.data?.data);
        }
    }, [selector?.viewList?.data]);


    //console.log(`bookingsData = ${JSON.stringify(bookingsData)}`)

    //Table Related States
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('sqft');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredData = bookingsData.filter((item) => {
        return (
            (item?.layoutName || '').toLowerCase().includes(search.toLowerCase()) ||
            (item?.landTypeName || '').toLowerCase().includes(search.toLowerCase()) ||
            (item?.districtName || '').toLowerCase().includes(search.toLowerCase()) ||
            (item?.insertedDateTime || '').toLowerCase().includes(search.toLowerCase()) ||
            (item?.landOwnerUserName || '').toLowerCase().includes(search.toLowerCase()) ||
            (item?.landOwnerMobileNo || '').toString().includes(search.toLowerCase()) ||
            (item?.userName || '').toLowerCase().includes(search.toLowerCase()) ||
            (item?.mobileNo || '').toString().includes(search.toLowerCase())
        );
    });



    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredData.length) : 0;

    return (

        <>
            <ListingDataTableToolbar search={search} setSearch={setSearch} />
            {loading ?
                <div className='mt-5'><Loader1 /></div> :
                <Box sx={{ width: '98%', display: 'flex', justifyContent: 'center' }}>
                    <Paper sx={{ width: '95%', mb: 2 }}>
                        <TableContainer>
                            <Table
                                sx={{ width: '100%', marginLeft: 5, justifyContent: 'center' }}
                            >
                                <ListingDataTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    rowCount={filteredData.length}
                                />
                                <TableBody>
                                    {stableSort(filteredData, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((item, index) => {
                                            return (
                                                <TableRow
                                                    tabIndex={-1}
                                                    key={item.rfId}
                                                    className='DataTable-row'
                                                    style={{ height: 70, justifyContent: 'center' }}
                                                >
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        padding="none"
                                                    >
                                                        <img
                                                            src={item?.siteMap == "null" || item?.siteMap == "" || item?.siteMap == null ? GetLandUrl(item?.layoutType, item?.landType) : JSON.parse(item?.siteMap)[0]}
                                                            alt="" className='PropertyListTable-img' />
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        padding="none"
                                                    >
                                                        {item.layoutName}
                                                        <div>({item?.landTypeName})</div>
                                                    </TableCell >
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        padding="none"
                                                    >{DtSentenceCase(item.districtName)}<br />{item?.blockName}
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        padding="none"
                                                    >
                                                        {item.landOwnerUserName}
                                                        <div>({item?.landOwnerMobileNo})</div>
                                                    </TableCell >
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        padding="none"
                                                    >
                                                        {item.userName}
                                                        <div>({item?.mobileNo})</div>
                                                    </TableCell >
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        padding="none"
                                                    >
                                                        {item.insertedDateTime}
                                                    </TableCell >
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={filteredData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Box>
            }
        </>
    );
}
