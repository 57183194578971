export const SET_LOADING = 'SET_LOADING';
export const USER_LOADED = "USER_LOADED"
export const AUTH_ERROR = "AUTH_ERROR"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const OTP_VERIFIY = "OTP_VERIFIY"
export const OTP_VERIFIY_FAIL = "OTP_VERIFIY_FAIL"
export const LOGIN_FAIL = "LOGIN_FAIL"
export const CLEAR_PROFILE = "CLEAR_PROFILE"
export const LOGOUT = "LOGOUT"
export const REGISTER_SUCCESS = "REGISTER_SUCCESS"
export const REGISTER_FAIL = "REGISTER_FAIL"
export const BIND_SUCCESS = "BIND_SUCCESS"
export const BIND_FAIL = "BIND_FAIL"
export const BIND_INSERT = "BIND_INSERT"
export const BIND_UPDATE = "BIND_UPDATE"
export const BIND_DELETE = "BIND_DELETE"
export const BIND_MEMBER_VIEW = "BIND_MEMBER_VIEW"
export const BIND_DISTRICT_SUCCESS = "BIND_DISTRICT_SUCCESS"
export const BIND_SUBZONE_SUCCESS = "BIND_SUBZONE_SUCCESS"
export const BIND_DTDCDATA_SUCCESS = "BIND_DTDCDATA_SUCCESS"
export const BIND_LANDTYPE_SUCCESS = "BIND_LANDTYPE_SUCCESS"
export const BIND_AREAUNIT_SUCCESS = "BIND_AREAUNIT_SUCCESS"
export const BIND_RADIUS_SUCCESS = "BIND_RADIUS_SUCCESS"
export const BIND_PROPERTY_AREA_REQUIRED_SUCCESS = "BIND_PROPERTY_AREA_REQUIRED_SUCCESS"
export const BIND_CHILDREN_SUCCESS = "BIND_CHILDREN_SUCCESS"
export const BIND_FOCUS_TYPE_SUCCESS = "BIND_FOCUS_TYPE_SUCCESS"
export const BIND_PURPOSE_TYPE_SUCCESS = "BIND_PURPOSE_TYPE_SUCCESS"
export const BIND_PAYMENT_TYPE_SUCCESS = "BIND_PAYMENT_TYPE_SUCCESS"
export const BIND_MARRIED_STATUS_SUCCESS = "BIND_MARRIED_STATUS_SUCCESS"
export const BIND_JOB_SECTOR_SUCCESS = "BIND_JOB_SECTOR_SUCCESS"
export const BIND_CURRENT_STATUS_SUCCESS = "BIND_CURRENT_STATUS_SUCCESS"
export const BIND_EMI_COMMITMENTS_SUCCESS = "BIND_EMI_COMMITMENTS_SUCCESS"
export const BIND_LIVING_SPACE_SUCCESS = "BIND_LIVING_SPACE_SUCCESS"
export const BIND_NEAR_BY_SERVICES_SUCCESS = "BIND_NEAR_BY_SERVICES_SUCCESS"
export const LAYOUT_SUCCESS = "LAYOUT_SUCCESS"
export const LAYOUT_FAIL = "LAYOUT_FAIL"
export const LAYOUT_VIEW = "LAYOUT_VIEW"
export const LAYOUT_INSERT = "LAYOUT_INSERT"
export const LAYOUT_UPDATE = "LAYOUT_UPDATE"
export const LAYOUT_DELETE = "LAYOUT_DELETE"
export const MEMBER_INSERT = "MEMBER_INSERT"
export const MEMBER_VIEW = "MEMBER_VIEW"
export const MEMBER_UPDATE = "MEMBER_UPDATE"
export const MEMBER_DELETE = "MEMBER_DELETE"
export const MEMBER_SUCCESS = "MEMBER_SUCCESS"
export const MEMBER_FAIL = "MEMBER_FAIL"
export const STAFF_SUCCESS = "STAFF_SUCCESS"
export const STAFF_FAIL = "STAFF_FAIL"
export const STAFF_INSERT = "STAFF_INSERT"
export const STAFF_UPDATE = "STAFF_UPDATE"
export const STAFF_DELETE = "STAFF_DELETE"
export const BOOKINGS_VIEW = "BOOKINGS_VIEW"
export const BOOKINGS_SUCCESS = "BOOKINGS_SUCCESS"
export const BOOKINGS_FAIL = "BOOKINGS_FAIL"
export const SITE_INSERT = "SITE_INSERT"
export const SITE_VIEW = "SITE_VIEW"
export const SITE_UPDATE = "SITE_UPDATE"
export const SITE_DELETE = "SITE_DELETE"
export const SITE_SUCCESS = "SITE_SUCCESS"
export const SITE_FAIL = "SITE_FAIL"
export const PERMISSION_VIEW = "PERMISSION_VIEW"
export const PERMISSION_SUCCESS = "PERMISSION_SUCCESS"
export const PERMISSION_FAIL = "PERMISSION_FAIL"
export const DASHBOARD_VIEW = "DASHBOARD_VIEW"
export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS"
export const DASHBOARD_FAIL = "DASHBOARD_FAIL"
export const DASHBOARD_DELETE = "DASHBOARD_DELETE"
export const PAYMENT_REQUEST_SUCCESS = 'PAYMENT_REQUEST_SUCCESS'
export const PAYMENT_REQUEST_FAIL = 'PAYMENT_REQUEST_FAIL'
export const VILLAGE_FINDER_VIEW = "VILLAGE_FINDER_VIEW"
export const VILLAGE_FINDER_SUCCESS = "VILLAGE_FINDER_SUCCESS"
export const VILLAGE_FINDER_FAIL = "VILLAGE_FINDER_FAIL"
export const VILLAGE_FINDER_DELETE = "VILLAGE_FINDER_DELETE"
export const BUILDING_APPROVAL_SUCCESS = "BUILDING_APPROVAL_SUCCESS"
export const BUILDING_APPROVAL_FAIL = "BUILDING_APPROVAL_VIEW_FAIL"
export const BUILDING_APPROVAL_VIEW = "BUILDING_APPROVAL_VIEW"
export const BUILDING_APPROVAL_INSERT = "BUILDING_APPROVAL_INSERT"
export const BUILDING_APPROVAL_UPDATE = "BUILDING_APPROVAL_UPDATE"
export const BUILDING_APPROVAL_DELETE = "BUILDING_APPROVAL_DELETE"
export const BUILDING_APPROVAL_FILE_DOWNLOAD_SUCCESS = "BUILDING_APPROVAL_FILE_DOWNLOAD_SUCCESS"
export const NEED_PROPERTY_SUCCESS = "NEED_PROPERTY_SUCCESS"
export const NEED_PROPERTY_FAIL = "NEED_PROPERTY_FAIL"
export const NEED_PROPERTY_VIEW = "NEED_PROPERTY_VIEW"
export const NEED_PROPERTY_INSERT = "NEED_PROPERTY_INSERT"
export const NEED_PROPERTY_DELETE = "NEED_PROPERTY_DELETE"
export const NEED_PROPERTY_UPDATE = "NEED_PROPERTY_UPDATE"
export const NEED_PROPERTY_MORE_INPUTS_SUCCESS = "NEED_PROPERTY_MORE_INPUTS_SUCCESS"
export const NEED_PROPERTY_MORE_INPUTS_FAIL = "NEED_PROPERTY_MORE_INPUTS_FAIL"
export const NEED_PROPERTY_MORE_INPUTS_VIEW = "NEED_PROPERTY_MORE_INPUTS_VIEW"
export const NEED_PROPERTY_MORE_INPUTS_INSERT = "NEED_PROPERTY_MORE_INPUTS_INSERT"
export const NEED_PROPERTY_MORE_INPUTS_UPDATE = "NEED_PROPERTY_MORE_INPUTS_UPDATE"
export const LEGAL_OPINION_SUCCESS = "LEGAL_OPINION_SUCCESS"
export const LEGAL_OPINION_FAIL = "LEGAL_OPINION_FAIL"
export const LEGAL_OPINION_VIEW = "LEGAL_OPINION_VIEW"
export const LEGAL_OPINION_INSERT = "LEGAL_OPINION_INSERT"
export const LEGAL_OPINION_DELETE = "LEGAL_OPINION_DELETE"
export const LEGAL_OPINION_UPDATE = "LEGAL_OPINION_UPDATE"
export const LEGAL_OPINION_FILE_DOWNLOAD_SUCCESS = "LEGAL_OPINION_FILE_DOWNLOAD_SUCCESS"
export const LAND_SURVEY_SUCCESS = "LAND_SURVEY_SUCCESS"
export const LAND_SURVEY_FAIL = "LAND_SURVEY_FAIL"
export const LAND_SURVEY_VIEW = "LAND_SURVEY_VIEW"
export const LAND_SURVEY_INSERT = "LAND_SURVEY_INSERT"
export const LAND_SURVEY_DELETE = "LAND_SURVEY_DELETE"
export const LAND_SURVEY_UPDATE = "LAND_SURVEY_UPDATE"
export const LAND_SURVEY_FILE_DOWNLOAD_SUCCESS = "LAND_SURVEY_FILE_DOWNLOAD_SUCCESS"
export const FMB_PATTA_SUCCESS = "FMB_PATTA_SUCCESS"
export const FMB_PATTA_FAIL = "FMB_PATTA_FAIL"
export const FMB_PATTA_VIEW = "FMB_PATTA_VIEW"
export const FMB_PATTA_INSERT = "FMB_PATTA_INSERT"
export const FMB_PATTA_DELETE = "FMB_PATTA_DELETE"
export const FMB_PATTA_UPDATE = "FMB_PATTA_UPDATE"
export const FMB_PATTA_FILE_DOWNLOAD_SUCCESS = "FMB_PATTA_FILE_DOWNLOAD_SUCCESS"
export const FMB_PATTA_SURVEY_NO = "FMB_PATTA_SURVEY_NO"
export const CRO_ENTRY_SUCCESS = "CRO_ENTRY_SUCCESS"
export const CRO_ENTRY_FAIL = "CRO_ENTRY_FAIL"
export const CRO_ENTRY_VIEW = "CRO_ENTRY_VIEW"
export const CRO_ENTRY_INSERT = "CRO_ENTRY_INSERT"
export const CRO_ENTRY_DELETE = "CRO_ENTRY_DELETE"
export const CRO_ENTRY_UPDATE = "CRO_ENTRY_UPDATE"
export const VASTU_PLAN_SUCCESS = "VASTU_PLAN_SUCCESS"
export const VASTU_PLAN_FAIL = "VASTU_PLAN_FAIL"
export const VASTU_PLAN_VIEW = "VASTU_PLAN_VIEW"
export const VASTU_PLAN_INSERT = "VASTU_PLAN_INSERT"
export const VASTU_PLAN_DELETE = "VASTU_PLAN_DELETE"
export const VASTU_PLAN_UPDATE = "VASTU_PLAN_UPDATE"
export const VASTU_PLAN_FILE_DOWNLOAD_SUCCESS = "VASTU_PLAN_FILE_DOWNLOAD_SUCCESS"




