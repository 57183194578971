import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { LuFileEdit } from 'react-icons/lu';
import { useDispatch, useSelector } from 'react-redux';
import { updateSiteData } from '../../../../store/action/LayoutAction';

const CreatePlot2List = ({ item }) => {

    const [selection, setSelection] = useState(2);
    const [data, setData] = useState(item);
    const dispatch = useDispatch();
    const onChange = e => {
        setData({ ...data, [e.target.name]: e.target.value });
    }
    const saveLayoutData = () => {
        //console.log(`data ${ JSON.stringify(data) }`)
        dispatch(updateSiteData(data, selection))
    }

    return (
        <div className='container createPlot2-Container'>
            <div className='row'>
                <div className='col d-flex justify-content-start'>
                    <p>Site No: {data?.siteNumber}</p>
                </div>
                <div className='col'>
                    <div className="btnGroup-Container ">
                        <div className="btnGroup">
                            <button
                                className={`btn ${selection === 1 ? 'active booked-btn' : ''}`}
                                onClick={() => setSelection(1)}
                            >
                                <span className={`btnText ${selection === 1 ? 'active' : ''}`}>Booked</span>
                            </button>
                            <button
                                className={`btn ${selection === 2 ? 'active available-btn' : ''}`}
                                onClick={() => setSelection(2)}
                            >
                                <span className={`btnText ${selection === 2 ? 'active' : ''}`}>Available</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className='col d-flex justify-content-end'>
                    <p >Total Area: {data?.length * data?.width}  Sqft</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3">
                    <label for="width" className="form-label">Width (Ft)</label>
                    <input type="text"
                        className="form-control form-input-holder"
                        placeholder="Enter Width"
                        id="width" name="width"
                        onChange={e => onChange(e)}
                        value={data?.width}
                    />
                </div>
                <div className="col-md-3">
                    <label for="length" className="form-label">Length (Ft)</label>
                    <input type="text"
                        className="form-control form-input-holder"
                        placeholder="Enter Length"
                        id="length" name="length"
                        onChange={e => onChange(e)}
                        value={data?.length} />
                </div>
                <div className="col-md-4">
                    <label for="totalSqft" className="form-label">Rate / Sq.Ft</label>
                    <input type="text"
                        className="form-control form-input-holder"
                        placeholder="Enter Total Sq.Ft"
                        id="squareFitPrice" name="squareFitPrice"
                        onChange={e => onChange(e)}
                        value={data?.squareFitPrice}
                    />
                </div>
                <div className='col-md-2 mt-4 align-self-end d-flex justify-content-end'>
                    <Button className='btn btn-success' style={{ height: 45, width: '100%', alignItems: 'flex-end' }} onClick={saveLayoutData} >  <LuFileEdit size="18px" style={{ marginBottom: 5, marginRight: 5 }} /> Save </Button>
                </div>
            </div>
        </div>
    )
}

export default CreatePlot2List