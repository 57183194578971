import axios from 'axios';
import { STAFF_SUCCESS, STAFF_FAIL, STAFF_INSERT, STAFF_UPDATE, STAFF_DELETE } from "../type/types";
import { Url, getTokenLocalStorage, getTokenLocalUserStorage } from '../../utils/Common';
import swal from 'sweetalert';
import { setLoading } from './LoadingAction';

export const getStaffDetails = (memberType = 4, subMemberType = 1, type = 0, search = 0, district = 0, blockId = 0, FromDate = 0, ToDate = 0) => async dispatch => {
    dispatch(setLoading(true));
    const token = getTokenLocalStorage();
    const formData = {
        "action": "View",
        "registerType": memberType,
        "rfId": "",
        "limit": "",
        "order": "",
        "mobileNo": "",
        "FromDate": FromDate == 0 ? "" : FromDate,
        "ToDate": ToDate == 0 ? "" : ToDate,
        "districtId": district == 0 ? "" : district,
        "blockId": blockId == 0 ? "" : blockId,
        "stateId": "",
        "search": search == 0 ? "" : search,
        "type": type == 1 ? "" : type,
        "subMemberType": subMemberType,
        "subMemberStatus": "",
    }
    const headers = { "x-auth-header": `${token}` }
    // console.log(`getStaffDetails agent formData= ${JSON.stringify(formData)}`)
    axios.post(`${Url}member`, formData, { headers }).then((response) => {
        // console.log(`getStaffDetails agent = ${JSON.stringify(response?.data?.data)}`)
        dispatch(setLoading(false));
        dispatch({
            payload: response,
            type: STAFF_SUCCESS
        })
    }).catch((err) => {
        dispatch(setLoading(false));
        dispatch({
            payload: err.response,
            type: STAFF_FAIL
        })
    })
}

export const saveMarketingStaff = (formData, history) => async dispatch => {

    //console.log(`saveMarketingStaff formData = ${JSON.stringify(formData)}`)

    const token = getTokenLocalStorage();
    const headers = { "x-auth-header": `${token}` }

    let staffType = formData.subMemberType === 1 ? 'My Property Staff' :
        formData.subMemberType === 2 ? 'Consultant' :
            formData.subMemberType === 3 ? 'Agent' : 'Document Writer';
    let actionType = formData?.rfId === "" ? 'added' : 'updated'

    axios.post(`${Url}member`, formData, { headers }).then((response) => {
        //console.log(`saveMarketingStaff = ${JSON.stringify(response?.data)}`)
        if (response?.data?.message === "Success") {
            swal("success", `${staffType} is ${actionType} successfully!`, "success");
            history.goBack();
            dispatch({
                payload: response,
                type: STAFF_INSERT
            })
        } else {
            history.goBack();
            swal("fail", `${staffType} is not added, Please try again`, "fail");
            dispatch({
                payload: response,
                type: STAFF_FAIL
            })
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: STAFF_FAIL
        })
    })
}

// Staff Update
export const staffSubMemberStatusUpdate = (rfId, subMemberType, subMemberStatus = 2) => async dispatch => {
    const token = getTokenLocalStorage();

    let staffType = subMemberType === 1 ? 'My Property Staff' :
        subMemberType === 2 ? 'Consultant' :
            subMemberType === 3 ? 'Agent' : 'Document Writer';

    let formData = {
        "rfId": rfId,
        "subMemberStatus": subMemberStatus,
        "action": "Sub Member Status Update"
    }

    const headers = { "x-auth-header": `${token}` }
    // console.log(`staffSubMemberStatusUpdate formData - ${JSON.stringify(formData)}`)

    axios.post(`${Url}member`, formData, { headers }).then((response) => {
        // console.log(`staffSubMemberStatusUpdate response data - ${JSON.stringify(response?.data)}`)
        if (response?.data?.message == "Success") {
            swal("success", `${staffType} is accepted successfully!`, "success");
            dispatch({
                payload: response,
                type: STAFF_UPDATE
            })
        } else {
            swal("Fail", `${staffType} is not accepted, Please try again`, "fail");
            dispatch({
                payload: response,
                type: STAFF_FAIL
            })
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: STAFF_FAIL
        })
    })
}



// Staff Delete
export const staffRegistrationDelete = (rfId, registerType = 1, subMemberType = 1) => async dispatch => {
    const token = getTokenLocalStorage();
    const loginUserData = await getTokenLocalUserStorage();
    const loginUserId = loginUserData[0].rfId;
    let staffType = subMemberType === 1 ? 'My Property Staff' :
        subMemberType === 2 ? 'Consultant' :
            subMemberType === 3 ? 'Agent' : 'Document Writer';

    const formData = {
        "action": "Delete",
        "registerType": registerType,
        "rfId": rfId,
        "statusId": "0",
        "loginUserId": loginUserId
    }
    const headers = { "x-auth-header": `${token}` }
    axios.post(`${Url}member`, formData, { headers }).then((response) => {
        //console.log(`response data - ${JSON.stringify(response?.data)}`)
        if (response?.data?.message == "Success") {
            dispatch({
                payload: response,
                type: STAFF_DELETE
            })
            swal("Deleted!", "Consultant is deleted Successfully", "success");
        } else {
            swal("Fail", `${staffType} is not deleted, Please try again`, "fail");
            dispatch({
                payload: response,
                type: STAFF_FAIL
            })
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: STAFF_FAIL
        })
    })
}
