import axios from 'axios';
import swal from "sweetalert";
import { Url, getTokenLocalStorage } from '../../utils/Common';
import { MEMBER_DELETE, MEMBER_FAIL, MEMBER_INSERT, MEMBER_SUCCESS, MEMBER_UPDATE, MEMBER_VIEW, STAFF_SUCCESS, STAFF_FAIL } from "../type/types";
import { setLoading } from './LoadingAction';

// Get Member Insert
export const memberRegistration = (formData) => async dispatch => {
    const token = getTokenLocalStorage();
    const headers = { "x-auth-header": `${token}` }
    axios.post(`${Url}member`, formData, { headers }).then((response) => {
        //console.log(`response - ${JSON.stringify(response)}`)
        swal("success", "Email already exists", "success");
        dispatch({
            payload: response,
            type: MEMBER_INSERT
        })
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: MEMBER_FAIL
        })
    })
}

//land owner register
export const memberSignUp = (formData, history) => async dispatch => {
    let formDatas = formData;
    // formDatas.loginUserId = userRfId        
    await axios.post(`${Url}login`, formDatas).then(async (response) => {

        if (response?.data?.message == "Success") {
            swal("success", "Land Owner Added", "success");
            history.push("/LandOwners")
            dispatch({
                payload: [],
                type: MEMBER_VIEW
            })
            await dispatch(getMemberData(2))
        } else {
            swal("Fail", "Land Owner Not Added", "Fail");
        }

    }).catch((error) => {
        console.log(error)
    })
}

// Get Member Data
export const getMemberData = (registerType = 0) => async dispatch => {
    dispatch(setLoading(true));
    const token = getTokenLocalStorage();
    const formData = {
        "action": "View",
        "registerType": registerType == 0 ? "" : registerType,
        "rfId": "",
        "limit": "",
        "order": "",
        "mobileNo": "",
        "userName": "",
        "FromDate": "",
        "ToDate": "",
        "districtId": "",
        "blockId": "",
        "stateId": "",
        "type": ""
    }
    const headers = { "x-auth-header": `${token}` }
    axios.post(`${Url}member`, formData, { headers }).then((response) => {
        if (response?.data?.message == 'Success') {
            dispatch(setLoading(false));
            dispatch({
                payload: response,
                type: MEMBER_VIEW
            })
        }
    }).catch((err) => {
        dispatch(setLoading(false));
        dispatch({
            payload: err.response,
            type: MEMBER_FAIL
        })
    })
}

