import { LAYOUT_VIEW, LAYOUT_INSERT, LAYOUT_SUCCESS, LAYOUT_FAIL, LAYOUT_UPDATE, LAYOUT_DELETE, SET_LOADING } from "../type/types";

const initialState = {
    loading: true,
    payload: [],
    editData: [],
    showHideStatus: [],
    error: {}
}

export const LayoutReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_LOADING:
            return {
                ...state,
                loading: payload
            };
        case LAYOUT_VIEW:
            return {
                ...state,
                loading: false,
                payload: payload
            }
        case LAYOUT_INSERT:
            return {
                ...state,
                loading: false,
                editData: payload
            }
        case LAYOUT_FAIL:
            return {
                ...state,
                loading: false,
                payload: [],
                error: payload
            }
        default:
            return state;

    }

}