import SearchIcon from '@mui/icons-material/Search';
import { default as Box } from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { default as Typography } from '@mui/material/Typography';
import { alpha, styled } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { IoMdAddCircle } from 'react-icons/io';
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from 'react-router-dom';
import { deleteBuildingApproval, getBuildingApproval, getBuildingApprovalZip, showBuildingApprovalStatus } from '../../../../store/action/BuildingApprovalAction';
import swal from 'sweetalert';
import { IoNavigate } from "react-icons/io5";
import EditBuildingApprovalStatus from './EditBuildingApprovalStatus';
import Loader1 from '../../../../utils/Loader1';
import { Modal } from 'react-bootstrap';
import CroEntryBuildingApproval from './CroEntryBuildingApproval';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'Sl.No',
        numeric: false,
        label: 'Sl.No',
    },
    {
        id: 'userName',
        numeric: false,
        label: 'Landowner',
    },
    {
        id: 'name',
        numeric: false,
        label: 'Land Name',
    },
    {
        id: 'mobileNo',
        numeric: true,
        label: 'Mobile No',
    },
    {
        id: 'insertedDateTime',
        numeric: false,
        label: 'Posted Date',
    },
    {
        id: 'navigation',
        numeric: false,
        label: 'Location',
    },
    {
        id: 'status',
        numeric: false,
        label: 'Status',
    },
    {
        id: 'action',
        numeric: true,
        label: 'Action',
    },


];


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        border: '1px solid lightgrey',
        borderRadius: '5px',
        height: '15px',
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));


function ListingDataTableHead(props) {

    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'center'}
                        sortDirection={orderBy === headCell.id ? order : false}

                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

ListingDataTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function ListingDataTableToolbar({ search, setSearch }) {

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
    };

    return (
        <div className='d-flex justify-content-between mx-5'>
            <Typography>
                <Search>
                    <SearchIconWrapper>
                        <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="Search…"
                        value={search}
                        onChange={handleSearchChange}
                    />
                </Search>
            </Typography>
            <Link to="/AddBuildingApproval" style={{ textDecoration: 'none' }}>
                <Button variant="contained" startIcon={<IoMdAddCircle />} size="medium" style={{ textTransform: 'none', color: "white", backgroundColor: '#3786FB' }} >Add Building Approval</Button>
            </Link>
        </div>
    );
}

ListingDataTableToolbar.propTypes = {
    search: PropTypes.string.isRequired,
    setSearch: PropTypes.func.isRequired,
};

export default function BuildingApprovalListTable() {

    const dispatch = useDispatch();
    const history = useHistory();
    const selector = useSelector(state => state?.BuildingApprovalReducer);
    const loading = selector.loading;

    const [buildingApprovalList, setBuildingApprovalList] = useState([]);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [zipUrl, setZipUrl] = useState("");
    const [croModalOpen, setCroModalOpen] = useState(false);
    const [modelData, setModelData] = useState({});
    const [search, setSearch] = useState('');

    const fetchData = async () => {
        await dispatch(getBuildingApproval())
    }

    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        if (selector?.viewList?.data?.message === "Success") {
            setBuildingApprovalList(selector?.viewList?.data?.data);
        }
        if (selector?.downloadFileUrl?.data?.message === "Success") {
            setZipUrl(selector?.downloadFileUrl?.data?.data?.Location);
        }
    }, [selector?.viewList?.data, selector?.downloadFileUrl?.data])

    const croHandler = (data) => {
        setModelData(data)
        setCroModalOpen(true);
    }
    const editHandler = (data) => {
        // console.log(`BuildingApprovalListTable editHandler - ${JSON.stringify(data)}`)
        setModelData(data)
        setEditModalOpen(true);
    }
    const editCloseHandler = () => setEditModalOpen(false);
    const croCloseHandler = () => setCroModalOpen(false);

    // console.log(`BuildingApprovalListTable Data - ${JSON.stringify(item)}`)


    //Table Related States
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('sqft');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const filteredData = buildingApprovalList.filter((item) => {
        return (
            (item?.userName || '').toString().toLowerCase().includes(search.toLowerCase()) ||
            (item?.name || '').toString().toLowerCase().includes(search.toLowerCase()) ||
            (item?.mobileNo || '').toString().includes(search.toLowerCase()) ||
            (item?.insertedDateTime || '').toString().toLowerCase().includes(search.toLowerCase()) ||
            (item?.currentStatusName || '').toLowerCase().includes(search.toLowerCase())
        );
    });
    //Delete Button Click
    const deleteButtonClick = async (item) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure that you want to delete this Building Approval?",
            icon: "warning",
            showCancelButton: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                await dispatch(deleteBuildingApproval(item?.rfId, item?.userRfId));
                fetchData();
            }
        })
    }
    //Show Button Click
    const HideButtonClick = async (item) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure that you want to Hide / Show this Building Approval?",
            icon: "warning",
            showCancelButton: true,
            dangerMode: true,
        }).then(async (willUpdate) => {
            if (willUpdate) {
                await dispatch(showBuildingApprovalStatus(item?.rfId, item?.userRfId, item?.visibleStatus));
                fetchData();
            }
        })
    }
    //Download Zip 
    const DownLoadFileUrl = async (rfId) => {
        await dispatch(getBuildingApprovalZip(rfId));
        await fetchData();
    }

    useEffect(() => {
        if (zipUrl != "") {
            window.open(zipUrl);
            fetchData();
        }
    }, [zipUrl])

    const showInMapClicked = (latitude, longitude) => {
        window.open("https://maps.google.com?q=" + latitude + "," + longitude);
    };

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredData.length) : 0;

    return (
        <>
            <ListingDataTableToolbar search={search} setSearch={setSearch} />
            {loading ? <div className='mt-5'> <Loader1 /></div> :
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Paper sx={{ width: '95%', mb: 2 }}>
                        <TableContainer>
                            <Table
                                sx={{ width: '100%', marginLeft: 5, justifyContent: 'center' }}
                            >
                                <ListingDataTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    rowCount={filteredData.length}
                                />
                                <TableBody>
                                    {stableSort(filteredData, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((item, index) => {
                                            return (
                                                <TableRow
                                                    tabIndex={-1}
                                                    key={item.rfId}
                                                    className='DataTable-row'
                                                    style={{ height: 70, justifyContent: 'center', opacity: item?.visibleStatus == 0 ? 0.5 : 1 }}
                                                >
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        padding="none"
                                                        align='center'
                                                    >
                                                        {filteredData.length - index}
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        padding="none"
                                                    >
                                                        {item.userName}
                                                    </TableCell >
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        padding="none"
                                                    >
                                                        {item.name}
                                                    </TableCell >
                                                    <TableCell align="center"
                                                        component="th"
                                                        scope="row"
                                                        padding="none"
                                                    >{item.mobileNo}</TableCell>
                                                    <TableCell align="center"
                                                        component="th"
                                                        scope="row"
                                                        padding="none"
                                                    >{item.insertedDateTime}</TableCell>
                                                    <TableCell align="center"
                                                        component="th"
                                                        scope="row"
                                                        padding="none"
                                                    >
                                                        <Button onClick={() => showInMapClicked(item?.latitude, item?.longitude)} variant="contained" size="small" style={{ textTransform: 'none', color: "white", backgroundColor: '#64b5e4' }} >
                                                            <IoNavigate size={18} />
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell align="center"
                                                        component="th"
                                                        scope="row"
                                                        padding="none"
                                                    >{item.currentStatusName}</TableCell>
                                                    <TableCell align="center"
                                                        component="th"
                                                        scope="row"
                                                        padding="none">
                                                        {item?.landDocument != "" && (
                                                            item?.zipurl == null ? (
                                                                <Button variant="contained" size="small" style={{ textTransform: 'none', color: "white", backgroundColor: 'red', marginRight: 10 }}
                                                                    onClick={() => DownLoadFileUrl(item?.rfId)}
                                                                >
                                                                    Get Docs
                                                                </Button>
                                                            ) : (
                                                                <a href={item?.zipurl}>
                                                                    <Button variant="contained" size="small" style={{ textTransform: 'none', color: "white", backgroundColor: '#28d7a0', marginRight: 10 }}
                                                                    >
                                                                        Download Docs
                                                                    </Button>
                                                                </a>
                                                            )
                                                        )}
                                                        <Button onClick={() => editHandler(item)} variant="contained" size="small" style={{ textTransform: 'none', color: "white", backgroundColor: '#FDB447', marginRight: 10 }}
                                                        >Edit</Button>
                                                        {/* <Button variant="contained" size="small" style={{ textTransform: 'none', color: "white", backgroundColor: '#e77777', marginRight: 10 }} onClick={() => deleteButtonClick(item)} >Delete</Button> */}
                                                        <Button variant="contained" size="small" style={{ textTransform: 'none', color: "white", backgroundColor: '#e777b6', marginRight: 10 }} onClick={() => HideButtonClick(item)}>{item?.visibleStatus == 0 ? 'Show' : 'Hide'}</Button>
                                                        <Button variant="contained" size="small" style={{ textTransform: 'none', color: "white", backgroundColor: '#81d06f' }} onClick={() => croHandler(item)}>CRO</Button>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={filteredData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                    <EditBuildingApprovalStatus item={modelData} open={editModalOpen} handleClose={editCloseHandler} />
                    <Modal
                        show={croModalOpen}
                        onHide={croCloseHandler}
                        backdrop="true"
                        keyboard={false}
                        size='lg'
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>CRO Entry Details - Building Approval</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <CroEntryBuildingApproval item={modelData} />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={croCloseHandler}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Box>
            }
        </>
    );
}