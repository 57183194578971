import { CRO_ENTRY_DELETE, CRO_ENTRY_FAIL, CRO_ENTRY_INSERT, CRO_ENTRY_SUCCESS, CRO_ENTRY_UPDATE, CRO_ENTRY_VIEW } from "../type/types";

const initialState = {
    loading: true,
    viewList: [],
    insertData: [],
    error: {}
}

export const CroEntryReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case CRO_ENTRY_VIEW:
            return {
                ...state,
                loading: false,
                viewList: payload
            }
        case CRO_ENTRY_INSERT:
            return {
                ...state,
                loading: false,
                insertData: payload
            }
        case CRO_ENTRY_FAIL:
            return {
                ...state,
                loading: false,
                error: payload
            }
        default:
            return state;

    }

}