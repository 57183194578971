import React, { useEffect, useState } from 'react'

import arrowupicon from '../../../../images/Dashboard/arrowup.png'
import barcharticon from '../../../../images/Dashboard/bar_chart.png'
import chartrevenue from '../../../../images/Dashboard/chartrevenue.png'
import arrowdownicon from '../../../../images/Dashboard/downarrow.png'
import soldchartstaff from '../../../../images/Dashboard/soldchartstaff.png'
import { useDispatch, useSelector } from 'react-redux';
import { getDashboardData } from "../../../../store/action/DashboardAction";
import plotIcon from '../../../../images/Services/plotIcon.png';
import landIcon from '../../../../images/Services/landIcon.png';
import houseIcon from '../../../../images/Services/houseIcon.png';
import policy1 from '../../../../images/Services/policy1.png';
import notaryClearance from '../../../../images/Services/notaryClearance.png';

export default function Dashboard() {
    const dispatch = useDispatch();
    const selector = useSelector(state => state?.DashboardReducer);
    const [dashBoardData, setDashboardData] = useState([])

    useEffect(() => {
        if (selector?.payload?.data?.alert === "Real Estate Dashboard Details") {
            setDashboardData(selector?.payload?.data?.data)
        }

    }, [selector?.payload?.data]);

    // console.log(`Dashboard data - ${JSON.stringify(dashBoardData)}`)

    return (
        <>
            <p className='total-listings-heading'>Property Posted Counts</p>
            <div className="dashboard-grid-container">
                <div className="row">
                    <div className="col-lg-4 col-md-4">
                        <div className="dashtotal-listings-col1">
                            <p>Property</p>
                            <div className='d-flex justify-content-between align-items-end'>
                                <div>
                                    <img src={plotIcon} style={{ width: 50, height: 50 }} alt="My Property" className='Services align-self-end' />
                                </div>
                                <div>
                                    <p className='total-listings-no'>{dashBoardData[0]?.plotsCount}</p>
                                    <div className='d-flex justify-content-end'>
                                        <span style={{ color: '#8c8c8c' }}>Plots</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <div className="dashtotal-listings-col1">
                            <p>Property</p>
                            <div className='d-flex justify-content-between align-items-end'>
                                <div>
                                    <img src={landIcon} style={{ width: 50, height: 50 }} alt="My Property" className='Services align-self-end' />
                                </div>
                                <div>
                                    <p className='total-listings-no'>{dashBoardData[0]?.landCount}</p>
                                    <div className='d-flex justify-content-end'>
                                        <span style={{ color: '#8c8c8c' }}>Land</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <div className="dashtotal-listings-col1">
                            <p>Property</p>
                            <div className='d-flex justify-content-between align-items-end'>
                                <div>
                                    <img src={houseIcon} style={{ width: 50, height: 50 }} alt="My Property" className='Services align-self-end' />
                                </div>
                                <div>
                                    <p className='total-listings-no'>{dashBoardData[0]?.houseCount}</p>
                                    <div className='d-flex justify-content-end'>
                                        <span style={{ color: '#8c8c8c' }}>Houses</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="dashboard-grid-container mt-lg-1">
                <div className="row">
                    <div className="col-lg-4 col-md-4">
                        <div className="dashtotal-listings-col1">
                            <p>Total Unsold</p>
                            <div className='d-flex justify-content-between align-items-end'>
                                <div>
                                    <img src={policy1} style={{ width: 50, height: 50 }} alt="My Property" className='Services align-self-end' />
                                </div>
                                <div>
                                    <p className='total-listings-no'>{dashBoardData[0]?.totalUnSold}</p>
                                    <div className='d-flex justify-content-end'>
                                        <span style={{ color: '#8c8c8c' }}>Unsold</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <div className="dashtotal-listings-col1">
                            <p>Total Booked</p>
                            <div className='d-flex justify-content-between align-items-end'>
                                <div>
                                    <img src={policy1} style={{ width: 50, height: 50 }} alt="My Property" className='Services align-self-end' />
                                </div>
                                <div>
                                    <p className='total-listings-no'>{dashBoardData[0]?.totalSold}</p>
                                    <div className='d-flex justify-content-end'>
                                        <span style={{ color: '#8c8c8c' }}>Booked</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <div className="dashtotal-listings-col1">
                            <p>View Count</p>
                            <div className='d-flex justify-content-between align-items-end'>
                                <div>
                                    <img src={policy1} style={{ width: 50, height: 50 }} alt="My Property" className='Services align-self-end' />
                                </div>
                                <div>
                                    <p className='total-listings-no'>{dashBoardData[0]?.viewCount}</p>
                                    <div className='d-flex justify-content-end'>
                                        <span style={{ color: '#8c8c8c' }}>Viewed</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p className='total-listings-heading'>App Counts</p>
            <div className="dashboard-grid-container mt-lg-1">
                <div className="row">
                    <div className="col-lg-4 col-md-4">
                        <div className="dashtotal-listings-col1">
                            <p>App Installation Count</p>
                            <div className='d-flex justify-content-between align-items-end'>
                                <div>
                                    <img src={notaryClearance} style={{ width: 50, height: 50 }} alt="My Property" className='Services align-self-end' />
                                </div>
                                <div>
                                    <p className='total-listings-no'>{dashBoardData[0]?.installationCount}</p>
                                    <div className='d-flex justify-content-end'>
                                        <span style={{ color: '#8c8c8c' }}>Android</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <div className="dashtotal-listings-col1">
                            <p>Uninstallation Count</p>
                            <div className='d-flex justify-content-between align-items-end'>
                                <div>
                                    <img src={notaryClearance} style={{ width: 50, height: 50 }} alt="My Property" className='Services align-self-end' />
                                </div>
                                <div>
                                    <p className='total-listings-no'>{dashBoardData[0]?.uninstallCount}</p>
                                    <div className='d-flex justify-content-end'>
                                        <span style={{ color: '#8c8c8c' }}>Android</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <div className="dashtotal-listings-col1">
                            <p>Active Users</p>
                            <div className='d-flex justify-content-between align-items-end'>
                                <div>
                                    <img src={notaryClearance} style={{ width: 50, height: 50 }} alt="My Property" className='Services align-self-end' />
                                </div>
                                <div>
                                    <p className='total-listings-no'>{dashBoardData[0]?.activeUserCount}</p>
                                    <div className='d-flex justify-content-end'>
                                        <span style={{ color: '#8c8c8c' }}>Android</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}



