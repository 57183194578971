import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { TiArrowBack } from 'react-icons/ti';
import { IoMdAddCircle } from 'react-icons/io';
import { getDistrictData, getBindMemberSingleData, getBindLandTypeData, getBindRadiusData } from '../../../../store/action/BindAction';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from "react-select";
import Modal from 'react-bootstrap/Modal';
import AddOwner from '../LandOwners/AddOwner';
import PickLocation from '../Others/PickLocation';
import ExpectedPrice from './ExpectedPrice';
import { addNeedProperty } from '../../../../store/action/NeedPropertyAction';
import LoadingButton from '@mui/lab/LoadingButton';
import { CircularProgress } from '@mui/material';

function AddNeedProperty(props) {

    const data = props?.location?.state?.editData

    //  console.log(`AddNeedProperty data = ${JSON.stringify(data)}`)

    let history = useHistory();
    const dispatch = useDispatch();
    const selector = useSelector(state => state?.BindReducer);

    const [sending, setSending] = useState(false);
    const [memberData, setMemberData] = useState([]);
    const [errors, setErrors] = useState({});
    const [districtData, setDistrictData] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [showAddLandOwnerModel, setShowAddLandOwnerModel] = useState(false);
    const [showMapModel, setShowMapModel] = useState(false);
    const [radiusData, setRadiusData] = useState([]);
    const [landTypeData, setLandTypeData] = useState([]);

    const [bodyData, setBodyData] = useState({
        "rfId": data?.rfId || "",
        "userRfId": data?.userRfId || "",
        "stateId": "1",
        "districtId": data?.districtId || "",
        'propertyRadiusType': 1,
        "districtName": data?.districtName || "",
        "talukId": data?.talukId || "",
        "talukName": data?.talukName || "",
        "villageId": data?.villageId || "",
        "villageName": data?.villageName || "",
        "address": data?.address || "",
        "location": data?.location || "",
        "latitude": data?.latitude || "",
        "longitude": data?.longitude || "",
        "minimumAmount": data?.minimumAmount || 0,
        "maximumAmount": data?.maximumAmount || 20000000,
        "layoutType": data?.layoutType || 1,
        "propertyType": data?.propertyType || 1,
        "expectedPriceId": data?.expectedPriceId || 1,
        "inputSource": "Android",
        "loginUserId": "12",
        "remarks": "",
        "action": "Insert"
    });

    console.log(`bodyData = ${JSON.stringify(bodyData)}`)

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(getDistrictData(1));
            await dispatch(getBindMemberSingleData());
            await dispatch(getBindLandTypeData());
            await dispatch(getBindRadiusData());
        }
        fetchData()
    }, []);

    useEffect(() => {
        if (selector?.districtData?.data?.alert == "District Details") {
            setDistrictData(selector?.districtData?.data?.data)
        }
        if (selector?.memberData?.data?.alert === "Real Estate Member Details") {
            let selected = null;
            const members = selector?.memberData?.data?.data?.map(res => {
                const member = {
                    label: res.combines,
                    value: res.rfId,
                    mobileNo: res.mobileNo,
                    rfId: res.rfId
                };
                if (data && res.rfId === data.userRfId) {
                    selected = member;
                }
                return member;
            });
            // console.log(`selector?.memberData?.data = ${JSON.stringify(members)}`)
            setMemberData(members);
            setSelectedOption(selected || null);
        }
        if (selector?.landTypeData?.data?.alert === "Land Survey Type") {
            setLandTypeData(selector?.landTypeData?.data?.data)
        }
        if (selector?.propertyRadiusData?.data?.alert === "Survey Radius Type") {
            setRadiusData(selector?.propertyRadiusData?.data?.data)
        }

    }, [selector?.districtData?.data, selector?.memberData?.data, selector?.landTypeData?.data, selector?.propertyRadiusData?.data])

    const onChangeLocation = (lat, lng, address, pincode, districtId, location) => {
        setBodyData({ ...bodyData, address: address, pincode: pincode, districtId: districtId, latitude: lat, longitude: lng, location: location });
    }

    const onChange = e => {
        setBodyData({ ...bodyData, [e.target.name]: e.target.value });
    }

    const handleError = (error, input) => {
        setErrors(prevState => ({ ...prevState, [input]: error }));
    };

    const { userRfId, address, location, districtId, pincode } = bodyData;

    const validate = (e) => {
        e.preventDefault();
        let isValid = true;

        if (!bodyData.userRfId) {
            handleError('Select Landowner Name', 'userRfId');
            isValid = false;
        }

        if (data == "") {
            if (!bodyData.address) {
                handleError('Enter address', 'address');
                isValid = false;
            }

            if (!bodyData.location) {
                handleError('Enter location', 'location');
                isValid = false;
            }

            if (!bodyData.districtId) {
                setErrors(prevState => ({ ...prevState, districtId: 'Select district' }));
                isValid = false;
            } else {
                setErrors(prevState => ({ ...prevState, districtId: '' }));
            }

            if (
                !bodyData.pincode ||
                isNaN(bodyData.pincode) ||
                (bodyData.pincode.toString().length !== 6 ||
                    bodyData.pincode.toString().includes('.'))
            ) {
                handleError('Enter Pincode', 'pincode');
                isValid = false;
            }
        }

        if (isValid) {
            handleAddFormSubmit();
        }
    }

    const handlePriceChange = (value) => {
        setBodyData({
            ...bodyData,
            minimumAmount: value[0],
            maximumAmount: value[1],
        });
    };

    const handleAddFormSubmit = async () => {
        await dispatch(addNeedProperty(bodyData, history, setSending));
    }

    const onChangeDistrict = async (districtId) => {
        setBodyData({ ...bodyData, districtId: districtId });
    };

    return (
        <>
            <div className='d-flex mt-2'>
                <Button className='p-0'><TiArrowBack onClick={() => history.goBack()} size={35} /></Button>
                <p className='listings-headingWithBack'> {data == "" ? 'Add' : 'Update'} I need Property</p>
            </div>

            <form className="row g-3 property-form ml-2 mr-2 mt-3"
                noValidate
            >
                <div className="col-md-5">
                    {errors.userRfId ?
                        <label htmlFor="userRfId" className="error-text required">{errors.userRfId}</label> :
                        <label htmlFor="userRfId" className="text-black">Land Owner Name<span className="required">*</span> </label>
                    }
                    <Select
                        value={selectedOption}
                        onChange={(selectedOption) => {
                            setSelectedOption(selectedOption);
                            setBodyData({ ...bodyData, userRfId: selectedOption?.value });
                        }}
                        options={memberData}
                        onFocus={() => handleError(null, 'userRfId')}
                        className='Landowner-select-container'
                        isDisabled={!!data}
                    />
                </div>
                <div className="col-md-1 btn-box mt-5" >
                    <Button className='btn btn-add' onClick={() => setShowAddLandOwnerModel(!showAddLandOwnerModel)}>
                        <IoMdAddCircle size={23} color='white' />
                    </Button>
                </div>
                <div className="col-md-4">
                    {errors.address ?
                        <label htmlFor="address" className="error-text">{errors.address}</label> :
                        <label htmlFor="address" className="text-black">Address<span className="required">*</span> </label>
                    }
                    <input
                        type="text"
                        className="form-control form-input-holder"
                        id="address" name='address'
                        value={address}
                        onChange={e => onChange(e)}
                        placeholder="Enter Address"
                        noValidate
                        onFocus={() => handleError(null, 'address')}
                    />
                </div>
                <div className="col-md-2 btn-box">
                    <button type="button" className="btn btn-info btn-pickLocation" onClick={() => setShowMapModel(!showMapModel)}>
                        Pick Location
                    </button>
                </div>

                <div className="col-md-6">
                    {errors.location ?
                        <label htmlFor="location" className="error-text">{errors.location}</label> :
                        <label htmlFor="location" className="text-black">Location<span className="required">*</span> </label>
                    }
                    <input
                        type="text"
                        className="form-control form-input-holder"
                        id="location" name='location'
                        value={location}
                        onChange={e => onChange(e)}
                        placeholder="Location"
                        noValidate
                        onFocus={() => handleError(null, 'location')}
                    />
                </div>
                <div className="col-md-4">
                    {errors.districtId ?
                        <label htmlFor="districtId" className="error-text required">{errors.districtId}</label> :
                        <label htmlFor="districtId" className="text-black">District Name<span className="required">*</span> </label>
                    }
                    <select className="form-select" name="districtId"
                        value={districtId}
                        onChange={e => onChange(e)}
                        onFocus={() => handleError(null, 'districtId')}>
                        <option value=""> Select District </option>
                        {
                            districtData.length > 0 && districtData?.map((data, index) => {
                                return <option key={index} value={data.rfId}> {data.districtName} </option>
                            })
                        }
                    </select>
                </div>
                <div className="col-md-2">
                    {errors.pincode ?
                        <label htmlFor="pincode" className="error-text">{errors.pincode}</label> :
                        <label htmlFor="pincode" className="text-black">Pincode<span className="required">*</span> </label>
                    }
                    <input
                        type="text"
                        className="form-control form-input-holder"
                        id="pincode" name='pincode'
                        value={pincode}
                        onChange={e => onChange(e)}
                        placeholder="Enter Pincode"
                        noValidate
                        onFocus={() => handleError(null, 'pincode')}
                    />
                </div>
                <div className="col-md-6">
                    <label htmlFor="propertyRadiusType" className="text-black">Select Radius<span className="required">*</span> </label>
                    <select className="form-select" name="propertyRadiusType"
                        value={bodyData?.propertyRadiusType}
                        onChange={e => onChange(e)}
                        onFocus={() => handleError(null, 'propertyRadiusType')}>
                        <option value=""> Select Radius </option>
                        {
                            radiusData.length > 0 && radiusData?.map((data, index) => {
                                return <option key={index} value={data.rfId}> {data.dataName} </option>
                            })
                        }
                    </select>
                </div>
                <div className="col-md-6">
                    <label htmlFor="propertyType" className="text-black">Type of Property<span className="required">*</span> </label>
                    <select className="form-select" name="propertyType"
                        value={bodyData?.propertyType}
                        style={{ fontSize: 14 }}
                        onChange={e => onChange(e)}
                        onFocus={() => handleError(null, 'propertyType')}>
                        <option value="">Type of Property</option>
                        {
                            landTypeData.length > 0 && landTypeData?.map((data, index) => {
                                return <option key={index} value={data.rfId}> {data.dataName} </option>
                            })
                        }
                    </select>
                </div>
                <ExpectedPrice
                    selectedValue={handlePriceChange}
                    minAmount={bodyData.minimumAmount}
                    maxAmount={bodyData.maximumAmount}
                />
                <Modal
                    show={showMapModel}
                    onHide={() => setShowMapModel(!showMapModel)}
                    backdrop="true"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title">Google Map</h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <PickLocation onChangeLocation={onChangeLocation} districtData={districtData} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => setShowMapModel(!showMapModel)}>
                            Confirm Location
                        </Button>
                        <Button variant="secondary" onClick={() => setShowMapModel(!showMapModel)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={showAddLandOwnerModel}
                    onHide={() => setShowAddLandOwnerModel(!setShowAddLandOwnerModel)}
                    backdrop="true"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title">Add Landowner</h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AddOwner setShowAddLandOwnerModel={setShowAddLandOwnerModel} />
                    </Modal.Body>
                    <Modal.Footer>
                        {/* <Button variant="primary" onClick={() => setShowAddLandOwnerModel(!setShowAddLandOwnerModel)}>
                            Add
                        </Button> */}
                        <Button variant="secondary" onClick={() => setShowAddLandOwnerModel(!setShowAddLandOwnerModel)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <div className="col-md-12 text-center my-5">
                    <LoadingButton
                        loading={sending}
                        onClick={validate}
                        variant="contained"
                        style={{ backgroundColor: '#3786FB', height: '45px', textTransform: 'none' }}
                        loadingIndicator={<CircularProgress size={15} sx={{ color: 'white' }} />}
                    >
                        {data == "" ? 'Add' : 'Update'} Need Property
                    </LoadingButton>
                </div>
            </form>
        </>
    )
}

export default AddNeedProperty