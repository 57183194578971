import React, { useState, useEffect } from "react";
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { getLayoutRegistrationData } from '../../store/action/LayoutAction';
import '../../css/design6.css'
import { commonFilter } from '../../utils/Common';


export default function Design6({ data }) {
    const dispatch = useDispatch();
    const selector = useSelector(state => state?.LayoutReducer);
    const [show, setShow] = useState(false);
    const [modelData, setModelData] = useState({});
    const [layoutData, setLayoutData] = useState([]);
    const handleClose = () => setShow(false);
    const handleShow = (modelLayoutData) => {
        setModelData(modelLayoutData)
        setShow(true);
    }
    useEffect(() => {
        dispatch(getLayoutRegistrationData("SRCLAND000039"));
    }, []);
    useEffect(() => {
        if (selector?.payload?.data?.message === "Success") {
            setLayoutData(selector?.payload?.data?.data)
        }
    }, [selector?.payload?.data])

    const design6 = {
        layout1: [
            {
                itemNo: 'item1',
                box_number: '1',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,

            },
            {
                itemNo: 'item2',
                box_number: '2',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 2) : 0,

            },
            {
                itemNo: 'item3',
                box_number: '3',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 3) : 0,

            },
            {
                itemNo: 'item4',
                box_number: '4',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 4) : 0,

            },
            {
                itemNo: 'item5',
                box_number: '5',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 5) : 0,

            },
            {
                itemNo: 'item6',
                box_number: '6',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 6) : 0,

            },
            {
                itemNo: 'item7',
                box_number: '7',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 7) : 0,

            },
            {
                itemNo: 'item8',
                box_number: '8',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 8) : 0,

            },
            {
                itemNo: 'item9',
                box_number: '9',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 9) : 0,

            },
            {
                itemNo: 'item10',
                box_number: '10',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 10) : 0,

            },
            {
                itemNo: 'item11',
                box_number: '11',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 11) : 0,

            },
            {
                itemNo: 'item12',
                box_number: '12',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 12) : 0,

            },
        ],
        layout2: [
            {
                itemNo: 'item139',
                box_number: '139',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 139) : 0,

            },
            {
                itemNo: 'item140',
                box_number: '140',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 140) : 0,

            },
            {
                itemNo: 'item141',
                box_number: '141',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 141) : 0,

            },
            {
                itemNo: 'item142',
                box_number: '142',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 142) : 0,

            },
            {
                itemNo: 'item143',
                box_number: '143',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 143) : 0,
            },
            {
                itemNo: 'item144',
                box_number: '144',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 144) : 0,
            },
            {
                itemNo: 'item133',
                box_number: '133',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 133) : 0,
            },
            {
                itemNo: 'item134',
                box_number: '134',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 134) : 0,
            },
            {
                itemNo: 'item135',
                box_number: '135',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 135) : 0,
            },
            {
                itemNo: 'item136',
                box_number: '136',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 136) : 0,
            },
            {
                itemNo: 'item137',
                box_number: '137',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 137) : 0,
            },
            {
                itemNo: 'item138',
                box_number: '138',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 138) : 0,
            },
        ],
        layout3: [
            {
                itemNo: 'item145',
                box_number: '145',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 145) : 0,
            },
            {
                itemNo: 'item146',
                box_number: '146',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 146) : 0,
            },
            {
                itemNo: 'item147',
                box_number: '147',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 147) : 0,
            },
            {
                itemNo: 'item148',
                box_number: '148',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 148) : 0,
            },
            {
                itemNo: 'item149',
                box_number: '149',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 149) : 0,
            },
            {
                itemNo: 'item150',
                box_number: '150',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 150) : 0,
            },
            {
                itemNo: 'item128',
                box_number: '128',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 128) : 0,
            },
            {
                itemNo: 'item129',
                box_number: '129',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 129) : 0,
            },
            {
                itemNo: 'item130',
                box_number: '130',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 130) : 0,
            },
            {
                itemNo: 'item131',
                box_number: '131',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 131) : 0,
            },
            {
                itemNo: 'item132',
                box_number: '132',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 132) : 0,
            },
        ],
        layout4: [
            {
                itemNo: 'item13',
                box_number: '13',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 13) : 0,
            },
            {
                itemNo: 'item14',
                box_number: '14',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 14) : 0,
            },
            {
                itemNo: 'item15',
                box_number: '15',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 15) : 0,
            },
            {
                itemNo: 'item16',
                box_number: '16',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 16) : 0,
            },
            {
                itemNo: 'item17',
                box_number: '17',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 17) : 0,
            },
            {
                itemNo: 'item18',
                box_number: '18',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 18) : 0,
            },
            {
                itemNo: 'item19',
                box_number: '19',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 19) : 0,
            },
            {
                itemNo: 'item20',
                box_number: '20',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 20) : 0,
            },
            {
                itemNo: 'item21',
                box_number: '21',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 21) : 0,
            },
            {
                itemNo: 'item22',
                box_number: '22',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 22) : 0,
            },
            {
                itemNo: 'item23',
                box_number: '23',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 23) : 0,
            },
            {
                itemNo: 'item24',
                box_number: '24',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 24) : 0,
            },
            {
                itemNo: 'item25',
                box_number: '25',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 25) : 0,
            },
            {
                itemNo: 'item26',
                box_number: '26',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 26) : 0,
            },
            {
                itemNo: 'item27',
                box_number: '27',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 27) : 0,
            },
            {
                itemNo: 'item28',
                box_number: '28',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 28) : 0,
            },
            {
                itemNo: 'item29',
                box_number: '29',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 29) : 0,
            },
            {
                itemNo: 'item30',
                box_number: '30',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 30) : 0,
            },
        ],
        layout5: [
            {
                itemNo: 'item35',
                box_number: '35',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 35) : 0,
            },
            {
                itemNo: 'item36',
                box_number: '36',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 36) : 0,
            },
            {
                itemNo: 'item37',
                box_number: '37',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 37) : 0,
            },
            {
                itemNo: 'item38',
                box_number: '38',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 38) : 0,
            },
            {
                itemNo: 'item39',
                box_number: '39',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 39) : 0,
            },
            {
                itemNo: 'item40',
                box_number: '40',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 40) : 0,
            },
            {
                itemNo: 'item41',
                box_number: '41',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 41) : 0,
            },
            {
                itemNo: 'item42',
                box_number: '42',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 42) : 0,
            },
            {
                itemNo: 'item43',
                box_number: '43',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 43) : 0,
            },
            {
                itemNo: 'item44',
                box_number: '44',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 44) : 0,
            },
            {
                itemNo: 'item45',
                box_number: '45',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 45) : 0,
            },
            {
                itemNo: 'item46',
                box_number: '46',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 46) : 0,
            },
            {
                itemNo: 'item47',
                box_number: '47',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 47) : 0,
            },
            {
                itemNo: 'item48',
                box_number: '48',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 48) : 0,
            },
            {
                itemNo: 'item49',
                box_number: '49',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 49) : 0,
            },
            {
                itemNo: 'item50',
                box_number: '50',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 50) : 0,
            },
            {
                itemNo: 'item51',
                box_number: '51',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 51) : 0,
            },
            {
                itemNo: 'item52',
                box_number: '52',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 52) : 0,
            },
            {
                itemNo: 'item53',
                box_number: '53',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 53) : 0,
            },
            {
                itemNo: 'item54',
                box_number: '54',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 54) : 0,
            },
        ],
        layout6: [
            {
                itemNo: 'item58',
                box_number: '58',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 58) : 0,
            },
            {
                itemNo: 'item59',
                box_number: '59',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 59) : 0,
            },
            {
                itemNo: 'item60',
                box_number: '60',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 60) : 0,
            },
            {
                itemNo: 'item61',
                box_number: '61',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 61) : 0,
            },
            {
                itemNo: 'item62',
                box_number: '62',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 62) : 0,
            },
            {
                itemNo: 'item63',
                box_number: '63',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 63) : 0,
            },
            {
                itemNo: 'item64',
                box_number: '64',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 64) : 0,
            },
            {
                itemNo: 'item65',
                box_number: '65',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 65) : 0,
            },
            {
                itemNo: 'item66',
                box_number: '66',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 66) : 0,
            },
            {
                itemNo: 'item67',
                box_number: '67',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 67) : 0,
            },
            {
                itemNo: 'item68',
                box_number: '68',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 68) : 0,
            },
            {
                itemNo: 'item69',
                box_number: '69',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 69) : 0,
            },
            {
                itemNo: 'item70',
                box_number: '70',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 70) : 0,
            },
        ],
        layout7: [
            {
                itemNo: 'item71',
                box_number: '71',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 71) : 0,
            },
            {
                itemNo: 'item72',
                box_number: '72',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 72) : 0,
            },
            {
                itemNo: 'item73',
                box_number: '73',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 73) : 0,
            },
            {
                itemNo: 'item74',
                box_number: '74',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 74) : 0,
            },
            {
                itemNo: 'item75',
                box_number: '75',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 75) : 0,
            },
            {
                itemNo: 'item76',
                box_number: '76',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 76) : 0,
            },
            {
                itemNo: 'item77',
                box_number: '77',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 77) : 0,
            },
            {
                itemNo: 'item78',
                box_number: '78',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 78) : 0,
            },
            {
                itemNo: 'item79',
                box_number: '79',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 79) : 0,
            },
            {
                itemNo: 'item80',
                box_number: '80',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 80) : 0,
            },
            {
                itemNo: 'item81',
                box_number: '81',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 81) : 0,
            },
            {
                itemNo: 'item82',
                box_number: '82',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 82) : 0,
            },
            {
                itemNo: 'item83',
                box_number: '83',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 83) : 0,
            },
            {
                itemNo: 'item84',
                box_number: '84',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 84) : 0,
            },
            {
                itemNo: 'item85',
                box_number: '85',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 85) : 0,
            },
            {
                itemNo: 'item86',
                box_number: '86',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 86) : 0,
            },
            {
                itemNo: 'item87',
                box_number: '87',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 87) : 0,
            },
            {
                itemNo: 'item88',
                box_number: '88',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 88) : 0,
            },
        ],
        layout8: [

            {
                itemNo: 'item117',
                box_number: '117',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 117) : 0,
            },
            {
                itemNo: 'item118',
                box_number: '118',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 118) : 0,
            },
            {
                itemNo: 'item119',
                box_number: '119',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 119) : 0,
            },
            {
                itemNo: 'item120',
                box_number: '120',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 120) : 0,
            },
            {
                itemNo: 'item121',
                box_number: '121',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 121) : 0,
            },
        ],
        layout9: [
            {
                itemNo: 'item105',
                box_number: '105',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 105) : 0,
            },
            {
                itemNo: 'item106',
                box_number: '106',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 106) : 0,
            },
            {
                itemNo: 'item107',
                box_number: '107',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 107) : 0,
            },
            {
                itemNo: 'item108',
                box_number: '108',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 108) : 0,
            },
            {
                itemNo: 'item109',
                box_number: '109',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 109) : 0,
            },
            {
                itemNo: 'item110',
                box_number: '110',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 110) : 0,
            },
            {
                itemNo: 'item111',
                box_number: '111',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 111) : 0,
            },
            {
                itemNo: 'item112',
                box_number: '112',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 112) : 0,
            },
            {
                itemNo: 'item113',
                box_number: '113',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 113) : 0,
            },
            {
                itemNo: 'item114',
                box_number: '114',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 114) : 0,
            },
            {
                itemNo: 'item115',
                box_number: '115',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 115) : 0,
            },
            {
                itemNo: 'item116',
                box_number: '116',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 116) : 0,
            },
        ],
        layout10: [
            {
                itemNo: 'item31',
                box_number: '31',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 31) : 0,
            },
            {
                itemNo: 'item32',
                box_number: '32',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 32) : 0,
            },
            {
                itemNo: 'item33',
                box_number: '33',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 33) : 0,
            },
            {
                itemNo: 'item34',
                box_number: '34',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 34) : 0,
            },
        ],
        layout11: [
            {
                itemNo: 'item55',
                box_number: '55',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 55) : 0,
            },
            {
                itemNo: 'item56',
                box_number: '56',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 56) : 0,
            },
        ],
        layout12: [
            {
                itemNo: 'item57',
                box_number: '57',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 57) : 0,
            },
            {
                itemNo: 'item89',
                box_number: '89',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 89) : 0,
            },
            {
                itemNo: 'item90',
                box_number: '90',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 90) : 0,
            },
            {
                itemNo: 'item91',
                box_number: '91',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 91) : 0,
            },
            {
                itemNo: 'item92',
                box_number: '92',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 92) : 0,
            },
            {
                itemNo: 'item93',
                box_number: '93',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 93) : 0,
            },
            {
                itemNo: 'item94',
                box_number: '94',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 94) : 0,
            },
            {
                itemNo: 'item95',
                box_number: '95',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 95) : 0,
            },
        ],
        layout13: [
            {
                itemNo: 'item99',
                box_number: '99',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 99) : 0,
            },
            {
                itemNo: 'item100',
                box_number: '100',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 100) : 0,
            },
            {
                itemNo: 'item101',
                box_number: '101',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 101) : 0,
            },
            {
                itemNo: 'item102',
                box_number: '102',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 102) : 0,
            },
            {
                itemNo: 'item103',
                box_number: '103',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 103) : 0,
            },
            {
                itemNo: 'item104',
                box_number: '104',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 104) : 0,
            },
        ],
        layout14: [
            {
                itemNo: 'item96',
                box_number: '98',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 96) : 0,
            },
            {
                itemNo: 'item97',
                box_number: 'TANGETCO',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 97) : 0,
            },
            {
                itemNo: 'item97A',
                box_number: '97',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 97) : 0,
            },
            {
                itemNo: 'item98',
                box_number: '96',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 98) : 0,
            },
        ],
        layout15: [
            {
                itemNo: 'item122',
                box_number: '127',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 122) : 0,
            },
            {
                itemNo: 'item123',
                box_number: '126',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 123) : 0,
            },
            {
                itemNo: 'item124',
                box_number: '125',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 124) : 0,
            },
            {
                itemNo: 'item125',
                box_number: '124',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 125) : 0,
            },
            {
                itemNo: 'item126',
                box_number: '',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 126) : 0,
            },
            {
                itemNo: 'item127',
                box_number: '123',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 127) : 0,
            },
            {
                itemNo: 'item128A',
                box_number: '122',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 128) : 0,
            },
        ]

    }

    return (
        <>
            {
                data === "true" && <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="true"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Land Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Site Identification No: {modelData?.box_number} <br />
                        Site Size Details:{modelData?.boxSize_t} {modelData?.boxSize_r} {modelData?.boxSize_b} {modelData?.boxSize_l}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            }

            <div className="container">
                <div className="">
                    <div className='design-6'>
                        <div className='main_layout'>
                            <div className='layout-1'>
                                {
                                    design6.layout1.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemNo} ${data.status == 1 ? 'background-color-success' : 'background-color-fail'} design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxSize_t ? "boxSize_t" : ""}> {data.boxSize_t ? data.boxSize_t : ""}</div>
                                                <div className={data.boxSize_b ? "boxSize_b" : ""}> {data.boxSize_b ? data.boxSize_b : ""}</div>
                                                <div className={data.boxSize_l ? "boxSize_l" : ""}> {data.boxSize_l ? data.boxSize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                {/* <div className="roadcross1"></div> */}
                            </div>
                            <div className='layout-2'>
                                {
                                    design6.layout2.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemNo} ${data.status == 1 ? 'background-color-success' : 'background-color-fail'} design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxSize_t ? "boxSize_t" : ""}> {data.boxSize_t ? data.boxSize_t : ""}</div>
                                                <div className={data.boxSize_b ? "boxSize_b" : ""}> {data.boxSize_b ? data.boxSize_b : ""}</div>
                                                <div className={data.boxSize_l ? "boxSize_l" : ""}> {data.boxSize_l ? data.boxSize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                {/* <div className="roadcross1"></div> */}
                            </div>
                            <div className='layout-3'>
                                {
                                    design6.layout3.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemNo} ${data.status == 1 ? 'background-color-success' : 'background-color-fail'} design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxSize_t ? "boxSize_t" : ""}> {data.boxSize_t ? data.boxSize_t : ""}</div>
                                                <div className={data.boxSize_b ? "boxSize_b" : ""}> {data.boxSize_b ? data.boxSize_b : ""}</div>
                                                <div className={data.boxSize_l ? "boxSize_l" : ""}> {data.boxSize_l ? data.boxSize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                {/* <div className="roadcross1"></div> */}
                            </div>
                            <div className='layout-4'>
                                {
                                    design6.layout4.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemNo} ${data.status == 1 ? 'background-color-success' : 'background-color-fail'} design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxSize_t ? "boxSize_t" : ""}> {data.boxSize_t ? data.boxSize_t : ""}</div>
                                                <div className={data.boxSize_b ? "boxSize_b" : ""}> {data.boxSize_b ? data.boxSize_b : ""}</div>
                                                <div className={data.boxSize_l ? "boxSize_l" : ""}> {data.boxSize_l ? data.boxSize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                {/* <div className="roadcross1"></div> */}
                            </div>
                            <div className='layout-5'>
                                {
                                    design6.layout5.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemNo} ${data.status == 1 ? 'background-color-success' : 'background-color-fail'} design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxSize_t ? "boxSize_t" : ""}> {data.boxSize_t ? data.boxSize_t : ""}</div>
                                                <div className={data.boxSize_b ? "boxSize_b" : ""}> {data.boxSize_b ? data.boxSize_b : ""}</div>
                                                <div className={data.boxSize_l ? "boxSize_l" : ""}> {data.boxSize_l ? data.boxSize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                {/* <div className="roadcross1"></div> */}
                            </div>
                            <div className='layout-6'>
                                {
                                    design6.layout6.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemNo} ${data.status == 1 ? 'background-color-success' : 'background-color-fail'} design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxSize_t ? "boxSize_t" : ""}> {data.boxSize_t ? data.boxSize_t : ""}</div>
                                                <div className={data.boxSize_b ? "boxSize_b" : ""}> {data.boxSize_b ? data.boxSize_b : ""}</div>
                                                <div className={data.boxSize_l ? "boxSize_l" : ""}> {data.boxSize_l ? data.boxSize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                {/* <div className="roadcross1"></div> */}
                            </div>
                            <div className='layout-7'>
                                {
                                    design6.layout7.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemNo} ${data.status == 1 ? 'background-color-success' : 'background-color-fail'} design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxSize_t ? "boxSize_t" : ""}> {data.boxSize_t ? data.boxSize_t : ""}</div>
                                                <div className={data.boxSize_b ? "boxSize_b" : ""}> {data.boxSize_b ? data.boxSize_b : ""}</div>
                                                <div className={data.boxSize_l ? "boxSize_l" : ""}> {data.boxSize_l ? data.boxSize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                {/* <div className="roadcross1"></div> */}
                            </div>
                            <div className='layout-8'>
                                {
                                    design6.layout8.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemNo} ${data.status == 1 ? 'background-color-success' : 'background-color-fail'} design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxSize_t ? "boxSize_t" : ""}> {data.boxSize_t ? data.boxSize_t : ""}</div>
                                                <div className={data.boxSize_b ? "boxSize_b" : ""}> {data.boxSize_b ? data.boxSize_b : ""}</div>
                                                <div className={data.boxSize_l ? "boxSize_l" : ""}> {data.boxSize_l ? data.boxSize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                {/* <div className="roadcross1"></div> */}
                            </div>
                            <div className='layout-9'>
                                {
                                    design6.layout9.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemNo} ${data.status == 1 ? 'background-color-success' : 'background-color-fail'} design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxSize_t ? "boxSize_t" : ""}> {data.boxSize_t ? data.boxSize_t : ""}</div>
                                                <div className={data.boxSize_b ? "boxSize_b" : ""}> {data.boxSize_b ? data.boxSize_b : ""}</div>
                                                <div className={data.boxSize_l ? "boxSize_l" : ""}> {data.boxSize_l ? data.boxSize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                {/* <div className="roadcross1"></div> */}
                            </div>
                            <div className='layout-10'>
                                {
                                    design6.layout10.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemNo} ${data.status == 1 ? 'background-color-success' : 'background-color-fail'} design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxSize_t ? "boxSize_t" : ""}> {data.boxSize_t ? data.boxSize_t : ""}</div>
                                                <div className={data.boxSize_b ? "boxSize_b" : ""}> {data.boxSize_b ? data.boxSize_b : ""}</div>
                                                <div className={data.boxSize_l ? "boxSize_l" : ""}> {data.boxSize_l ? data.boxSize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                {/* <div className="roadcross1"></div> */}
                            </div>
                            <div className='layout-11'>
                                {
                                    design6.layout11.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemNo} ${data.status == 1 ? 'background-color-success' : 'background-color-fail'} design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxSize_t ? "boxSize_t" : ""}> {data.boxSize_t ? data.boxSize_t : ""}</div>
                                                <div className={data.boxSize_b ? "boxSize_b" : ""}> {data.boxSize_b ? data.boxSize_b : ""}</div>
                                                <div className={data.boxSize_l ? "boxSize_l" : ""}> {data.boxSize_l ? data.boxSize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                {/* <div className="roadcross1"></div> */}
                            </div>
                            <div className='layout-12'>
                                {
                                    design6.layout12.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemNo} ${data.status == 1 ? 'background-color-success' : 'background-color-fail'} design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxSize_t ? "boxSize_t" : ""}> {data.boxSize_t ? data.boxSize_t : ""}</div>
                                                <div className={data.boxSize_b ? "boxSize_b" : ""}> {data.boxSize_b ? data.boxSize_b : ""}</div>
                                                <div className={data.boxSize_l ? "boxSize_l" : ""}> {data.boxSize_l ? data.boxSize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                {/* <div className="roadcross1"></div> */}
                            </div>
                            <div className='layout-13'>
                                {
                                    design6.layout13.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemNo} ${data.status == 1 ? 'background-color-success' : 'background-color-fail'} design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxSize_t ? "boxSize_t" : ""}> {data.boxSize_t ? data.boxSize_t : ""}</div>
                                                <div className={data.boxSize_b ? "boxSize_b" : ""}> {data.boxSize_b ? data.boxSize_b : ""}</div>
                                                <div className={data.boxSize_l ? "boxSize_l" : ""}> {data.boxSize_l ? data.boxSize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                {/* <div className="roadcross1"></div> */}
                            </div>
                            <div className='layout-14'>
                                {
                                    design6.layout14.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemNo} ${data.status == 1 ? 'background-color-success' : 'background-color-fail'} design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxSize_t ? "boxSize_t" : ""}> {data.boxSize_t ? data.boxSize_t : ""}</div>
                                                <div className={data.boxSize_b ? "boxSize_b" : ""}> {data.boxSize_b ? data.boxSize_b : ""}</div>
                                                <div className={data.boxSize_l ? "boxSize_l" : ""}> {data.boxSize_l ? data.boxSize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                {/* <div className="roadcross1"></div> */}
                            </div>
                            <div className='layout-15'>
                                {
                                    design6.layout15.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemNo} ${data.status == 1 ? 'background-color-success' : 'background-color-fail'} design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxSize_t ? "boxSize_t" : ""}> {data.boxSize_t ? data.boxSize_t : ""}</div>
                                                <div className={data.boxSize_b ? "boxSize_b" : ""}> {data.boxSize_b ? data.boxSize_b : ""}</div>
                                                <div className={data.boxSize_l ? "boxSize_l" : ""}> {data.boxSize_l ? data.boxSize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                {/* <div className="roadcross1"></div> */}
                            </div>


                            <div className="road-1">12.0 M Panchyat Road </div>
                            <div className="road-2">9.0 M Layout Road </div>
                            <div className="road-3">10.0 M Layout Road </div>
                            <div className="road-4">10.0 M Layout Road </div>
                            <div className="road-5">9.0 M Layout Road </div>
                            <div className="road-6">12.0 M Layout Road </div>
                            <div className="road-7">9.0 M Layout Road </div>
                            <div className="road-8">10.0 M Layout Road </div>
                            <div className="road-9">9.0 M Layout Road </div>
                            <div className="road-10">9.0 M Layout Road </div>
                            <div className="road-11"></div>


                            <div className="whitebox1"></div>
                            <div className="whitebox2"></div>
                            <div className="whitebox3"></div>


                            <div className="sf1">PARK-1</div>

                            <div className="sm1">24.2M</div>



                            <div className="rs1">R.S NO:466</div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}