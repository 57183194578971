import React from 'react';
import LandSurveyListTable from './LandSurveyListTable'

const LandSurvey = () => {
    return (
        <>
            <p className='listings-heading'>Land Survey</p>
            <LandSurveyListTable />
        </>
    )
}

export default LandSurvey