import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from "react-redux";
import '../../css/design2.css';
import { getLayoutRegistrationData } from '../../store/action/LayoutAction';
import { commonFilter } from '../../utils/Common';
import { Link } from 'react-router-dom';

export default function Design2({ data }) {

    const dispatch = useDispatch();
    const selector = useSelector(state => state?.LayoutReducer);
    const [show, setShow] = useState(false);
    const [modelData, setModelData] = useState({});

    const handleClose = () => setShow(false);
    const handleShow = (modelLayoutData) => {
        setModelData(modelLayoutData)
        setShow(true);
    }

    const design2 = {
        layout1: [
            {
                itemNo: 'item1',
                box_number: '1',
                boxSize_r: "31'",
                boxSize_b: "36'-6''",
            },
            {
                itemNo: 'item2',
                box_number: '2',
                boxSize_r: "30'-3''",
                boxSize_b: "40'",
            },
            {
                itemNo: 'item3',
                box_number: '3',
                boxSize_t: "29'",
                boxSize_r: "29'-3''",
                boxSize_b: "40'",
            },

        ],
        layout2: [
            {
                itemNo: 'item6',
                box_number: '6',
                boxSize_r: "41'-9''",
                boxSize_b: "43'-9''",
            },
            {
                itemNo: 'item5',
                box_number: '5',
                boxSize_r: "30'",
                boxSize_b: "42'",
            },
            {
                itemNo: 'item4',
                box_number: '4',
                boxSize_r: "40'",
                boxSize_b: "25'",
            },

        ],
        layout3: [
            {
                itemNo: 'item7',
                box_number: '7',
                boxSize_l: "23'",
                boxSize_r: "25'-6''",
                boxSize_b: "40'",
            },
            {
                itemNo: 'item8',
                box_number: '8',
                boxSize_l: "30'",
                boxSize_r: "30'",
                boxSize_b: "40'",
            },
            {
                itemNo: 'item9',
                box_number: '9',
                boxSize_l: "30'",
                boxSize_r: "30'",
                boxSize_b: "40'",
            },
            {
                itemNo: 'item10',
                box_number: '10',
                boxSize_l: "30'",
                boxSize_r: "35'",
                boxSize_b: "35'",
            },
        ],
        layout3a: [
            {
                itemNo: 'item14',
                box_number: '14',
                boxSize_r: "28'",
                boxSize_b: "45'",
            },
            {
                itemNo: 'item13',
                box_number: '13',
                boxSize_r: "30'",
                boxSize_b: "45'",
            },
            {
                itemNo: 'item12',
                box_number: '12',
                boxSize_r: "30'",
                boxSize_b: "45'",
            },
            {
                itemNo: 'item11',
                box_number: '11',
                boxSize_r: "30'",
                boxSize_b: "40'",
            },

        ],
        layout4: [
            {
                itemNo: 'item15',
                box_number: '15',
                boxSize_l: "30'",
                boxSize_r: "32'-3''",
                boxSize_b: "36'",
            },
            {
                itemNo: 'item16',
                box_number: '16',
                boxSize_l: "30'",
                boxSize_r: "30'",
                boxSize_b: "40'",
            },
            {
                itemNo: 'item17',
                box_number: '17',
                boxSize_l: "30'",
                boxSize_r: "30'",
                boxSize_b: "40'",
            },
            {
                itemNo: 'item18',
                box_number: '18',
                boxSize_l: "30'",
                boxSize_r: "35'",
                boxSize_b: "35'",
            },
        ],
        layout4A: [
            {
                itemNo: 'item22',
                box_number: '22',
                boxSize_r: "35'",
                boxSize_b: "42'-6''",
            },
            {
                itemNo: 'item21',
                box_number: '21',
                boxSize_r: "30'",
                boxSize_b: "45'",
            },
            {
                itemNo: 'item20',
                box_number: '20',
                boxSize_r: "30'",
                boxSize_b: "45'",
            },
            {
                itemNo: 'item19',
                box_number: '19',
                boxSize_r: "30'",
                boxSize_b: "40'",
            },

        ],
        layout5: [
            {
                itemNo: 'item23',
                box_number: '23',
                boxSize_l: "36'-6''",
                boxSize_r: "38'-9''",
                boxSize_b: "42'-3''",
            },
            {
                itemNo: 'item24',
                box_number: '24',
                boxSize_l: "30'",
                boxSize_r: "30'",
                boxSize_b: "40'",
            },
            {
                itemNo: 'item25',
                box_number: '25',
                boxSize_l: "30'",
                boxSize_r: "30'",
                boxSize_b: "40'",
            },
            {
                itemNo: 'item26',
                box_number: '26',
                boxSize_l: "30'",
                boxSize_r: "35'",
                boxSize_b: "35'",
            },
        ],
        layout5A: [
            {
                itemNo: 'item30',
                box_number: '30',
                boxSize_r: "41'-6''",
                boxSize_b: "42'-3''",
            },
            {
                itemNo: 'item29',
                box_number: '29',
                boxSize_r: '30',
                boxSize_b: "36'-6''",
            },
            {
                itemNo: 'item28',
                box_number: '28',
                boxSize_r: "30'",
                boxSize_b: "44'-6''",
            },
            {
                itemNo: 'item27',
                box_number: '27',
                boxSize_r: "30",
                boxSize_b: "39'-6''",
            },

        ],

    }

    return (
        <>
            {
                data === "true" && <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="true"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Land Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Site Identification No: {modelData?.box_number} <br />
                        Site Size Details:{modelData?.boxSize_t} {modelData?.boxSize_r} {modelData?.boxSize_b} {modelData?.boxSize_l}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
            <div className="container">
                <div className="">
                    <div className='design-2'>
                        <div className='main_layout'>
                            <div className='layout-1'>
                                {
                                    design2.layout1.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemNo} design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxSize_t ? "boxSize_t" : ""}> {data.boxSize_t ? data.boxSize_t : ""}</div>
                                                <div className={data.boxSize_r ? "boxSize_r" : ""}> {data.boxSize_r ? data.boxSize_r : ""}</div>
                                                <div className={data.boxSize_b ? "boxSize_b" : ""}> {data.boxSize_b ? data.boxSize_b : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                            </div>
                            <div className='layout-2'>
                                {
                                    design2.layout2.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemNo} design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxSize_r ? "boxSize_r" : ""}> {data.boxSize_r ? data.boxSize_r : ""}</div>
                                                <div className={data.boxSize_b ? "boxSize_b" : ""}> {data.boxSize_b ? data.boxSize_b : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                <div className="roadcross1"></div>
                            </div>
                            <div className='layout-3'>
                                {
                                    design2.layout3.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemNo} design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxSize_l ? "boxSize_l" : ""}> {data.boxSize_l ? data.boxSize_l : ""}</div>
                                                <div className={data.boxSize_r ? "boxSize_r" : ""}> {data.boxSize_r ? data.boxSize_r : ""}</div>
                                                <div className={data.boxSize_b ? "boxSize_b" : ""}> {data.boxSize_b ? data.boxSize_b : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                <div className="roadcross5"></div>
                            </div>
                            <div className='layout-3a'>
                                {
                                    design2.layout3a.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemNo} design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxSize_r ? "boxSize_r" : ""}> {data.boxSize_r ? data.boxSize_r : ""}</div>
                                                <div className={data.boxSize_b ? "boxSize_b" : ""}> {data.boxSize_b ? data.boxSize_b : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                <div className="roadcross2"></div>
                            </div>
                            <div className='layout-4'>
                                {
                                    design2.layout4.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemNo} design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxSize_l ? "boxSize_l" : ""}> {data.boxSize_l ? data.boxSize_l : ""}</div>
                                                <div className={data.boxSize_r ? "boxSize_r" : ""}> {data.boxSize_r ? data.boxSize_r : ""}</div>
                                                <div className={data.boxSize_b ? "boxSize_b" : ""}> {data.boxSize_b ? data.boxSize_b : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                <div className="roadcross6"></div>
                            </div>
                            <div className='layout-4A'>
                                {
                                    design2.layout4A.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemNo} design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxSize_r ? "boxSize_r" : ""}> {data.boxSize_r ? data.boxSize_r : ""}</div>
                                                <div className={data.boxSize_b ? "boxSize_b" : ""}> {data.boxSize_b ? data.boxSize_b : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                <div className="roadcross3"></div>
                            </div>
                            <div className='layout-5'>
                                {
                                    design2.layout5.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemNo} design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxSize_l ? "boxSize_l" : ""}> {data.boxSize_l ? data.boxSize_l : ""}</div>
                                                <div className={data.boxSize_r ? "boxSize_r" : ""}> {data.boxSize_r ? data.boxSize_r : ""}</div>
                                                <div className={data.boxSize_b ? "boxSize_b" : ""}> {data.boxSize_b ? data.boxSize_b : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                <div className="roadcross7"></div>
                            </div>
                            <div className='layout-5A'>
                                {
                                    design2.layout5A.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemNo} design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxSize_r ? "boxSize_r" : ""}> {data.boxSize_r ? data.boxSize_r : ""}</div>
                                                <div className={data.boxSize_b ? "boxSize_b" : ""}> {data.boxSize_b ? data.boxSize_b : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                <div className="roadcross4"></div>
                            </div>
                            <div className="layout-6"> 538 / 2A3 PART</div>
                            <div className="layout-7"> 539 / 6A </div>
                            <div className="layout-9"> 546 </div>
                            <div className="road-1">
                                <div className="left">EXISTING ROAD </div>
                                <div className="right">EXISTING ROAD </div>
                            </div>
                            <div className="road-2">
                                <div className="roadtext5">23 FEET LAYOUT ROAD</div>
                            </div>
                            <div className="road-3">
                                <div className="roadtext5">23 FEET LAYOUT ROAD</div>
                            </div>
                            <div className="road-4">
                                <div className="roadtext5">33 FEET LAYOUT ROAD</div>
                                <div className="left">539 / 1A PART</div>
                            </div>
                            <div className="road-5">
                                <div className="roadtext5">23 FEET LAYOUT ROAD</div>
                            </div>
                            <div className="road-6">
                                <div className="roadtext6">532 / 2C1</div>
                                <div className="left">ROAD</div>
                                <div className="right">ROAD</div>
                            </div>
                            <div className="road-7">
                                <div className="roadtext7"> ROAD</div>
                            </div>
                            <div className="whitebox1">
                                <div className="layout-8"> 545 </div>
                            </div>
                            <div className="whitebox2"></div>
                        </div>
                    </div>
                </div>
                <div className="row mb-lg-4">
                    <div className="col-lg-9 col-md-12 site-details-info">
                        <div className="row text-left pl-3">
                            <div className="col">
                                <h6>Budget</h6>
                                <p>₹ 4100 / Sq.Ft</p>
                            </div>
                            <div className="col">
                                <h6>Size</h6>
                                <p>1500 <span>&#13217;</span></p>
                            </div>
                            <div className="col">
                                <h6>Type</h6>
                                <p>Home</p>
                            </div>
                            <div className="col">
                                <h6>Status</h6>
                                <p>Available</p>
                            </div>
                            <div className="col">
                                <h6>Location</h6>
                                <p>Coimbatore</p>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-3  col-md-12 col-sm-12 mb-sm-3 mb-md-5 pr-md-3">
                        <Link to={'/Login-page'}>
                            <button type="button" className='btn btn-primary'>YES! I WANT TO BOOK</button>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}
