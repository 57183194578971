import React from 'react';
import { Link } from 'react-router-dom';
import facebook from '../../images/footer/facebook.svg';
import insta from '../../images/footer/instagram-brands.svg';
import twitter from '../../images/footer/twitter-brands.svg';

import AppLogo from '../../images/website-logo.png';

function Footer() {
	return (
		<footer className="site-footer">
			<div className="footer-top">
				<div className="container">
					<div className="row">
						<div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
							<div className='logo'>
								<Link to={"/"}>
									<img src={AppLogo} alt="My Property" className='appiconFooter' />
								</Link>
								<p>We simplify real estate transactions by providing an easy-to-use platform for property owners to list their plots, houses, apartments, and lands. Our mission is to connect sellers with buyers efficiently, ensuring a seamless and transparent process. Join us to experience the future of real estate.</p>
							</div>
							<div >
								<ul className='connect-button'>
									<li className='site_button facebook'>
										<a href="https://www.facebook.com/profile.php?id=100094404713048">
											<img src={facebook} alt="facebook" width={12} height={12} />
										</a>
									</li>
									<li className='site_button insta'>
										<a href="https://www.instagram.com/mypropertyqr/">
											<img src={insta} alt="insta" width={20} height={20} />
										</a>
									</li>
									{/* <li className='site_button twitter'> <img src={twitter} alt="twitter" width={20} height={20} /> </li> */}
								</ul>
							</div>

						</div>
						<div className="col-xl-2 col-lg-3 col-md-3 col-sm-12 row-container1">
							<h5>Community</h5>
							<ul className='footer-ul'>
								<li className='footer-list'>For Rent</li>
								<li className='footer-list'> For Seller</li>
								<li className='footer-list'>For Buyer</li>
								<li className='footer-list'>
									<Link to={'/Login-page'}>Search home
									</Link>
								</li>
							</ul>
						</div>
						<div className="col-xl-3 col-lg-3 col-md-2 col-sm-12 row-container1">
							<h5>Frequently Asked Questions</h5>
							<ul className='footer-ul'>
								<li className='footer-list'><Link to={'/Privacy-Policy'} >Privacy Policy</Link></li>
								<li className='footer-list'><Link to={'/Terms-And-Condition'} >Terms and Condition</Link></li>
							</ul>
						</div>
						<div className="col-xl-3  col-lg-3 col-md-3 col-sm-12 row-container1">
							<h5>Address</h5>
							<ul className='footer-ul'>
								<li className='footer-list'>My Property QR,</li>
								<li className='footer-list'>No. 2/84, Morasappatti Post,</li>
								<li className='footer-list'>Veppamarathupatti,</li>
								<li className='footer-list'>Edappadi, Salem</li>
								<li className='footer-list'>TamilNadu - 637101</li>
								<li className='footer-list'>GST - 33ABWFM4624P1ZI</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className="footer-btm">
				<div className="container-footer">
					<div className="row">
						<div className="col-lg-12 text-center">
							<p>© Copyright by My Property QR</p>
						</div>
					</div>
				</div>
			</div>
		</footer>
	)
}

export default Footer;