import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from 'react-router-dom';
import frame from '../../images/login/frame.png';
import { Login, OtpVerify } from '../../store/action/LoginAction';
import Header from '../Layout/Header';

const LoginData = () => {

	const history = useHistory();
	const dispatch = useDispatch();
	const [errors, setErrors] = useState({});
	const [buttonShow, setButtonShow] = useState(false);

	const [formData, setFormData] = useState({
		registerType: 1,
		otp: "",
		mobileNo: ""
	});

	const onChange = e => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	}

	const handleError = (error, input) => {
		setErrors(prevState => ({ ...prevState, [input]: error }));
	};

	const { registerType, userName, otp, mobileNo } = formData;

	// Otp Verification
	const onSubmit = async data => {
		await dispatch(Login(mobileNo, otp, registerType, history))
		setButtonShow(false)
	};
	// On Otp Send Submit
	const onOtpSubmit = async data => {
		await dispatch(OtpVerify(mobileNo, registerType));
		setButtonShow(true);
	};

	const validateMobile = (e) => {
		e.preventDefault();
		let isValid = true;

		if (
			!formData.mobileNo ||
			isNaN(formData.mobileNo) ||
			(formData.mobileNo.toString().length !== 10 ||
				formData.mobileNo.toString().includes('.'))
		) {
			handleError('Enter Valid Mobile number', 'mobileNo');
			isValid = false;
		}

		if (isValid) {
			onOtpSubmit();
		}
	}

	const validateOtp = (e) => {
		e.preventDefault();
		let isValid = true;

		if (
			!formData.otp ||
			isNaN(formData.otp) ||
			(formData.otp.toString().length !== 5 ||
				formData.otp.toString().includes('.'))
		) {
			handleError('Enter valid OTP', 'otp');
			isValid = false;
		}

		if (isValid) {
			onSubmit();
		}
	}

	return (
		<>
			<Header />
			<div className="row" style={{ backgroundColor: '#e1e1e1' }}>
				<div className="col-lg-6 col-md-6 text-center login-form-container1" >
					<h4 className='mt-5'>Login To You Now</h4>
					<img src={frame} alt="" className='img-fluid mb-5' />
				</div>
				<div className="col-lg-6 col-md-6 align-self-center login-form-container2">
					<form onSubmit={buttonShow === false ? validateMobile : validateOtp} className="col-12 login-form px-sm-5 ">
						<p className="font-weight-600">If you have an account with us, please log in.</p>
						<div className="form-group">
							{errors.mobileNo ?
								<label htmlFor="mobileNo" className="error-text required">{errors.mobileNo}</label> :
								<label htmlFor="mobileNo" className="text-black">Mobile No<span className="required">*</span> </label>
							}
							<div className="input-group">
								<input
									type="tel"
									className="form-control pl-3"
									name="mobileNo" id="mobileNo"
									onChange={e => onChange(e)}
									noValidate
									maxLength={10}
									onFocus={() => handleError(null, 'mobileNo')}
									placeholder="Mobile No" />
								<div className="input-group-append">
									<button className="input-group-text bg-black text-white"> Send OTP </button>
								</div>
							</div>
						</div>
						{
							buttonShow === true && <div className="form-group">
								{errors.otp ?
									<label htmlFor="otp" className="error-text required">{errors.otp}</label> :
									<label htmlFor="otp" className="text-black">OTP<span className="required">*</span> </label>
								}
								<div className="input-group">
									<input
										type="tel"
										className="form-control pl-3"
										name="otp" id="otp"
										maxLength={5}
										onChange={e => onChange(e)}
										noValidate
										onFocus={() => handleError(null, 'otp')}
										placeholder="OTP" />
								</div>
							</div>
						}
						<div className="text-center">
							{buttonShow === true && <button className="btn btn-primary float-left pl-4 pr-4">LOGIN</button>}
							{/* <Link to="/SignUp" className="site-button-link btn-signup forget-pass m-t10 float-right text-black"> Sign up</Link> */}
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default LoginData
