import React from 'react';
import ListOfProperty from '../Property/ListOfProperty';

export default function LandOwnerDetailPage({ item }) {

    //console.log(`LandOwnerDetailPage Data - ${JSON.stringify(item)}`)

    return (
        <div className='DetailPage-container'>
            <div className="row d-flex align-items-center">
                <div className="col-md-3 my-3 d-flex align-items-center justify-content-center">
                    <img src={require('../../../../images/staff/staffImage.jpg')} alt="" style={{ width: 100, height: 100 }} />
                </div>
                <div className='col-md-9 text-left '>
                    <div className='mb-1'>
                        <span className='PropertyName-heading'>LandOwner Name:  </span><span className='PropertyName-details'>{item.userName}</span>
                    </div>
                    <div className='mb-1'>
                        <span className='PropertyName-heading'>Mobile No:  </span><span className='PropertyName-details'>{item.mobileNo}</span>
                    </div>
                </div>
            </div>
            <ListOfProperty userRfId={item?.rfId} />
        </div>
    )
}
