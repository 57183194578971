import { FMB_PATTA_DELETE, FMB_PATTA_FAIL, FMB_PATTA_FILE_DOWNLOAD_SUCCESS, FMB_PATTA_INSERT, FMB_PATTA_SUCCESS, FMB_PATTA_UPDATE, FMB_PATTA_VIEW, SET_LOADING, FMB_PATTA_SURVEY_NO } from "../type/types";


const initialState = {
    loading: true,
    viewList: [],
    insertData: [],
    downloadFileUrl: [],
    updateStatus: [],
    surveyNumber: [],
    error: {}
}

export const FmbPattaReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_LOADING:
            return {
                ...state,
                loading: payload
            };
        case FMB_PATTA_VIEW:
            return {
                ...state,
                loading: false,
                viewList: payload
            }
        case FMB_PATTA_INSERT:
            return {
                ...state,
                loading: false,
                insertData: payload
            }
        case FMB_PATTA_FILE_DOWNLOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                downloadFileUrl: payload
            }
        case FMB_PATTA_UPDATE:
            return {
                ...state,
                loading: false,
                updateStatus: payload
            }
        case FMB_PATTA_SURVEY_NO:
            return {
                ...state,
                loading: false,
                surveyNumber: payload
            }
        case FMB_PATTA_FAIL:
            return {
                ...state,
                loading: false,
                error: payload
            }
        default:
            return state;

    }

}