import axios from 'axios';
import swal from "sweetalert";
import { Url, getTokenLocalStorage, getTokenLocalUserStorage } from '../../utils/Common';
import { VASTU_PLAN_SUCCESS, VASTU_PLAN_FAIL, VASTU_PLAN_VIEW, VASTU_PLAN_INSERT, VASTU_PLAN_UPDATE, VASTU_PLAN_DELETE, VASTU_PLAN_FILE_DOWNLOAD_SUCCESS, SET_LOADING } from "../type/types";
import { setLoading } from './LoadingAction';

export const getVastuPlanList = () => async dispatch => {
    dispatch(setLoading(true));
    const token = getTokenLocalStorage();
    const formData = {
        "action": "View",
        "rfId": "",
        "userRfId": "",
        "order": "desc",
        "fromDate": "",
        "toDate": "",
        "search": ""
    }
    const headers = { "x-auth-header": `${token}` }
    axios.post(`${Url}vasthu`, formData, { headers }).then((response) => {
        // console.log(`getVastuPlanList response = ${JSON.stringify(response?.data?.data)}`)
        if (response?.data?.message == 'Success') {
            dispatch(setLoading(false));
            dispatch({
                payload: response,
                type: VASTU_PLAN_VIEW
            })
        }
    }).catch((err) => {
        dispatch(setLoading(false));
        dispatch({
            payload: err.response,
            type: VASTU_PLAN_FAIL
        })
    })
};


export const addVastuPlan = (formData, history, setSending) => async dispatch => {

    //console.log(`addVastuPlan formData = ${JSON.stringify(formData)}`)
    setSending(true);
    const token = getTokenLocalStorage();
    const headers = { "x-auth-header": `${token}` }

    axios.post(`${Url}vasthu`, formData, { headers }).then((response) => {
        //console.log(`addVastuPlan response = ${JSON.stringify(response?.data)}`)
        if (response?.data?.message === "Success") {
            swal("success", `Vastu plan request is added successfully!`, "success");
            history.goBack();
            dispatch({
                payload: response,
                type: VASTU_PLAN_INSERT
            });
            setSending(false);
        } else {
            history.goBack();
            swal("fail", `Legal Opinion is not added, Please try again`, "fail");
            dispatch({
                payload: response,
                type: VASTU_PLAN_FAIL
            });
            setSending(false);
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: VASTU_PLAN_FAIL
        });
        setSending(false);
    })
};

export const showHideLegalVastuPlan = (rfId, userRfId, visibleStatus) => async dispatch => {

    const loginUserData = await getTokenLocalUserStorage();
    const loginUserId = loginUserData[0].rfId;

    let formData = {
        "action": "Show",
        "rfId": rfId,
        "visibleStatus": visibleStatus == 1 ? 0 : 1,
        "userRfId": userRfId,
        "loginUserId": loginUserId
    }

    await axios.post(`${Url}vasthu`, formData).then((response) => {
        //  console.log(`site data response - ${JSON.stringify(response)}`)
        if (response?.data?.message == "Success") {
            swal("Status Changed!", "The selected Vastu Plan status is changed successfully!", "success");
        } else {
            swal("Fail", `The selected Vastu Plan status cannot be updated!, Please try again`, "fail");
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: VASTU_PLAN_FAIL
        })
    })
}

export const deleteVastuPlan = (rfId, userRfId) => async dispatch => {

    const token = getTokenLocalStorage();
    const headers = { "x-auth-header": `${token}` }
    const loginUserData = await getTokenLocalUserStorage();
    const loginUserId = loginUserData[0].rfId;

    const formData = {
        "action": "Delete",
        "rfId": rfId,
        "userRfId": userRfId,
        "loginUserId": loginUserId
    };
    axios.post(`${Url}vasthu`, formData, { headers }).then((response) => {
        // console.log(`deleteVastuPlan response = ${JSON.stringify(response?.data)}`)
        if (response?.data?.message == 'Success') {
            swal("success", `Vastu Plan is deleted successfully!`, "success");
            dispatch({
                payload: response,
                type: VASTU_PLAN_DELETE
            })
        } else {
            swal("fail", `Vastu Plan is not deleted, Please try again`, "fail");
            dispatch({
                payload: response,
                type: VASTU_PLAN_FAIL
            });
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: VASTU_PLAN_FAIL
        })
    })
}


// Download File Url
export const getVastuPlanZip = (rfId) => async dispatch => {
    const token = getTokenLocalStorage();
    let formData = {
        "action": "Download",
        "rfId": rfId
    }
    const headers = { "x-auth-header": `${token}` }
    axios.post(`${Url}vasthu`, formData, { headers }).then((response) => {
        // console.log(`getVastuPlanZip response = ${JSON.stringify(response)}`)
        if (response?.data?.message == 'Success') {
            dispatch({
                payload: response,
                type: VASTU_PLAN_FILE_DOWNLOAD_SUCCESS
            })
        } else {
            swal("fail", `Couldn't able to download the Vastu Plan, Please try again`, "fail");
            dispatch({
                payload: response,
                type: VASTU_PLAN_FAIL
            });
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: VASTU_PLAN_FAIL
        })
    })
}

// Status Update
export const updateVastuPlanStatus = (itemRfId, statusRfId, status, approvalType, setSending) => async dispatch => {

    setSending(true);
    const token = getTokenLocalStorage();
    const loginUserData = await getTokenLocalUserStorage();
    const loginUserId = loginUserData[0].rfId;

    let formData = {
        "action": "Update",
        "propertyRfId": itemRfId,
        "statusRfId": statusRfId,
        "propertyStatus": status == true ? 2 : 1,
        "approvalType": approvalType,
        "loginUserId": loginUserId
    }

    console.log(`updateVastuPlanStatus formData = ${JSON.stringify(formData)}`);

    const headers = { "x-auth-header": `${token}` }
    axios.post(`${Url}vasthu`, formData, { headers }).then((response) => {

        console.log(`updateVastuPlanStatus response = ${JSON.stringify(response)}`);

        if (response?.data?.message == 'Success') {
            swal("success", `Status is updated successfully!`, "success");
            dispatch({
                payload: response,
                type: VASTU_PLAN_UPDATE
            });
            setSending(false);
        } else {
            swal("fail", `Status is not updated, Please try again`, "error");
            dispatch({
                payload: response,
                type: VASTU_PLAN_FAIL
            });
            setSending(false);
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: VASTU_PLAN_FAIL
        });
        setSending(false);
    })
}
