import { VASTU_PLAN_SUCCESS, VASTU_PLAN_FAIL, VASTU_PLAN_VIEW, VASTU_PLAN_INSERT, VASTU_PLAN_UPDATE, VASTU_PLAN_DELETE, VASTU_PLAN_FILE_DOWNLOAD_SUCCESS, SET_LOADING } from "../type/types";

const initialState = {
    loading: true,
    viewList: [],
    insertData: [],
    downloadFileUrl: [],
    updateStatus: [],
    error: {}
}

export const VastuPlanReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_LOADING:
            return {
                ...state,
                loading: payload
            };
        case VASTU_PLAN_VIEW:
            return {
                ...state,
                loading: false,
                viewList: payload
            }
        case VASTU_PLAN_INSERT:
            return {
                ...state,
                loading: false,
                insertData: payload
            }
        case VASTU_PLAN_FILE_DOWNLOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                downloadFileUrl: payload
            }
        case VASTU_PLAN_UPDATE:
            return {
                ...state,
                loading: false,
                updateStatus: payload
            }
        case VASTU_PLAN_FAIL:
            return {
                ...state,
                loading: false,
                error: payload
            }
        default:
            return state;

    }

}