import React from 'react';
import WriterListTable from './WriterListTable'

const WriterHome = () => {
    return (
        <>
            <p className='listings-heading'>Document Writer</p>
            <WriterListTable />
        </>
    )
}

export default WriterHome