import React from 'react';
import { useHistory } from 'react-router-dom';
import PropertyListTable from './PropertyListTable';

export default function Property({ addListing, addStaff, viewListingData }) {

    const history = useHistory();

    const pageSet = (data, list) => {
        history.push("/CreateProperty")
        // addStaff(data, list)
    }

    return (
        <>
            <p className='listings-heading'>List of Property</p>
            <PropertyListTable addStaff={pageSet} addListing={addListing} />
        </>
    )
}

