import React, { useEffect, useState } from 'react';
import FeatureCities from '../Element/FeatureCities';
import GetConnected from '../Element/GetConnected';
import IndexBanner from '../Element/IndexBanner';
import CustomerReview from '../Element/CustomerReview';
import RecentListing from '../Element/RecentListing';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import { useDispatch, useSelector } from "react-redux";
import { getLayoutData } from '../../store/action/LayoutAction';
import swal from 'sweetalert';
import CarouselProperty from '../Element/CarouselProperty';

var bnr5 = require('./../../images/banner/bnr5.png');
var arrow = require('./../../images/banner/arrow.png');

export default function HomePage() {

	const dispatch = useDispatch();

	const [errors, setErrors] = useState({});
	const [bodyData, setBodyData] = useState({
		"mailId": ""
	})

	const fetchData = async () => {
		await dispatch(getLayoutData("", 0, 0, 0, 0, 10));
	}

	useEffect(() => {
		fetchData();
	}, []);

	const { mailId } = bodyData;

	const validate = (e) => {
		e.preventDefault();
		let isValid = true;

		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

		if (!bodyData.mailId || bodyData.mailId.length < 3) {
			handleError('Mail must be min 3 characters long', 'mailId');
			isValid = false;
		}

		if (!emailRegex.test(bodyData.mailId)) {
			handleError('Please enter a valid email address', 'mailId');
			isValid = false;
		}

		if (isValid) {
			mailHandler();
		}
	}

	const onChange = e => {
		setBodyData({ ...bodyData, [e.target.name]: e.target.value });
	}

	const handleError = (error, input) => {
		setErrors(prevState => ({ ...prevState, [input]: error }));
	};

	const mailHandler = async () => {
		swal("Good job!", "You have Subscribed for Latest News & Updates!", "success");
		setBodyData({
			mailId: ""
		})

	}

	return (
		<>
			<Header />
			<IndexBanner />
			<FeatureCities />
			<RecentListing />
			<div style={{ backgroundImage: "url( " + bnr5 + ")", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
				<div className="row mb-5 p-4">
					<div className="col-lg-2"></div>
					<div className="col-lg-4">
						<p className='Subscribe-email'>Subscribe Our Email Address <br /> For Future Latest News & Updates </p>
					</div>
					<div className="col-lg-3">
						<div className="form-group">
							<div className="input-group">
								<input
									type="text"
									className="form-control subscribe-input"
									placeholder="Type your email address"
									name="mailId"
									value={bodyData.mailId}
									onChange={e => onChange(e)}
									onFocus={() => handleError(null, 'mailId')}
								/>
								<div className="input-group-append append-arrow" onClick={validate}>
									<span className="input-group-text bg-white">
										<img src={arrow} alt="" width={15} height={15} className='img-fluid' />
									</span>
								</div>
							</div>
							{errors.mailId && <div className="text-danger">{errors.mailId}</div>}
						</div>
					</div>
				</div>
			</div>
			<CarouselProperty />
			<GetConnected />
			<CustomerReview />
			<Footer />
		</>
	)
}
