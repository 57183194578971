import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { getSiteData } from "../../../../store/action/SiteAction";
import CreatePlot2 from './CreatePlot2';
import PropertyDetailPage from './PropertyDetailPage';
import { TiArrowBack } from 'react-icons/ti';
import { Link } from 'react-router-dom';

const EditPlot = (props) => {

    const dispatch = useDispatch();
    const history = useHistory()

    const selector = useSelector(state => state?.SiteReducer);
    const [siteData, setSiteData] = useState([]);

    const item = props?.location?.state?.layoutData

    useEffect(() => {
        dispatch(getSiteData(item?.rollNo));
    }, []);

    useEffect(() => {
        if (selector?.payload?.data?.alert === "Real Estate Site Details") {
            setSiteData(selector?.payload?.data?.data)
        }
    }, [selector?.payload?.data])

    // const editPage = () => {
    //     history.push({ pathname: "/CreateProperty", state: { layoutData: item } })
    // }

    return (
        <div className='container'>
            <div className='d-flex mt-2'>
                <Button className='p-0'>
                    <TiArrowBack onClick={() => history.goBack()} size={35} />
                </Button>
                <p className='listings-headingWithBack'>Edit Plot</p>
            </div>
            <PropertyDetailPage item={item == "" ? "" : item} />
            <div className='align-self-end d-flex justify-content-center mt-3 mb-3'>
                <Link
                    to={{ pathname: '/CreateProperty', state: { layoutData: item } }}
                    style={{ textDecoration: 'none' }}>
                    <Button variant="contained" size="large" style={{ textTransform: 'none', color: "white", backgroundColor: '#FDB447', marginLeft: '10px', justifyContent: 'center' }} >Edit other Details</Button>
                </Link>
            </div>
            <CreatePlot2 siteData={siteData} />
        </div>
    )
}

export default EditPlot