import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getTokenLocalUserStorage } from '../../../../utils/Common';
import { addCroEntry, getCroEntry } from '../../../../store/action/CroEntryActions';

export default function CroEntryProperty({ item }) {

    const CroSelector = useSelector(state => state?.CroEntryReducer);
    const dispatch = useDispatch();
    const loginUserData = getTokenLocalUserStorage();
    const userRfId = loginUserData[0].rfId;

    const [croDataView, setCroDataView] = useState([]);

    const [croData, setCroData] = useState({
        "rfId": "",
        "userRfId": "",
        "croType": "1",
        "conversation": "",
        "conversationDateTime": "",
        "propertyRfId": item?.rfId || "",
        "audioUrl": "",
        "inputSource": "Web",
        "loginUserId": "12",
        "remarks": "",
        "action": "Insert"
    });

    useEffect(() => {
        dispatch(getCroEntry(1, item?.rfId));
    }, []);

    useEffect(() => {
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const dd = String(today.getDate()).padStart(2, '0');
        const hh = String(today.getHours()).padStart(2, '0');
        const min = String(today.getMinutes()).padStart(2, '0');
        const formattedDateTime = `${yyyy}-${mm}-${dd}T${hh}:${min}`;

        setCroData((prevState) => ({
            ...prevState,
            userRfId: userRfId,
            conversationDateTime: formattedDateTime
        }));
    }, []);

    useEffect(() => {
        if (CroSelector?.viewList?.data?.message === "Success") {
            setCroDataView(CroSelector?.viewList?.data?.data)
        } else if (CroSelector?.viewList?.data?.alert == "No More Data Real Estate Site CRO Details") {
            setCroDataView([croData])
        }
    }, [CroSelector?.viewList?.data]);


    const addRow = async () => {
        setCroDataView([...croDataView, croData])
        // await dispatch(addCroEntry(croData));

    };

    const onChangeCroData = (e, index) => {
        const { name, value } = e.target;
        setCroDataView(prevData =>
            prevData.map((item, i) =>
                i === index ? { ...item, [name]: value } : item
            )
        );
    }

    const AddCroHandler = async () => {
        croDataView?.length > 0 &&
            await croDataView?.map(async (data, index) => {
                if (data?.rfId === "") {
                    console.log("data", JSON.stringify(data))
                    await dispatch(addCroEntry(data));
                }
            })
    }

    return (
        <div>
            <div>
                <p className='PropertyName-heading mt-3' style={{ color: 'red', fontSize: 18, fontWeight: 600 }}>Customer Conversation</p>
            </div>
            {croDataView?.map((item, index) => (
                <div key={item?.rfId} className='row property-form'>
                    <div className="form-group col-md-3">
                        <label htmlFor="conversationDateTime">Date</label>
                        <input
                            type="datetime-local"
                            className="form-control border rounded-3"
                            id="conversationDateTime"
                            value={item?.conversationDateTime}
                            readOnly={item?.rfId !== "" ? true : false}
                        />
                    </div>
                    <div className="col-md-5">
                        <label htmlFor="conversation" className="form-label">Conversation</label>
                        <textarea type="text"
                            className="form-control form-input-holder"
                            id="conversation"
                            name="conversation"
                            value={item?.conversation}
                            onChange={e => onChangeCroData(e, index)}
                            placeholder="Enter Conversation"
                            readOnly={item?.rfId != "" ? true : false}
                            rows="7"
                        />
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="remarks" className="form-label">Remarks</label>
                        <textarea type="text"
                            className="form-control form-input-holder"
                            id="remarks"
                            name="remarks"
                            placeholder="Remarks"
                            value={item?.remarks}
                            onChange={e => onChangeCroData(e, index)}
                            readOnly={item?.rfId != "" ? true : false}
                            rows="7"
                        />
                    </div>
                    <div className="col-md-1 d-flex align-items-center">
                        {index + 1 == croDataView?.length &&
                            <Button variant="contained" type="submit" onClick={addRow}
                                size="small"
                                style={{ textTransform: 'none', color: "white", backgroundColor: '#81d06f', marginRight: '10px' }} >Add</Button>
                        }
                    </div>
                </div>
            ))
            }
            <div>
                <div className="col-md-12 text-center">
                    <button className="btn btn-primary px-5" type="submit" onClick={() => AddCroHandler()}>Update</button>
                </div>
            </div>
        </div>
    )
}
