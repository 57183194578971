import { LAND_SURVEY_SUCCESS, LAND_SURVEY_FAIL, LAND_SURVEY_VIEW, LAND_SURVEY_INSERT, LAND_SURVEY_UPDATE, LAND_SURVEY_DELETE, LAND_SURVEY_FILE_DOWNLOAD_SUCCESS, SET_LOADING } from "../type/types";

const initialState = {
    loading: true,
    viewList: [],
    insertData: [],
    downloadFileUrl: [],
    updateStatus: [],
    error: {}
}

export const LandSurveyReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_LOADING:
            return {
                ...state,
                loading: payload
            };
        case LAND_SURVEY_VIEW:
            return {
                ...state,
                loading: false,
                viewList: payload
            }
        case LAND_SURVEY_INSERT:
            return {
                ...state,
                loading: false,
                insertData: payload
            }
        case LAND_SURVEY_FILE_DOWNLOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                downloadFileUrl: payload
            }
        case LAND_SURVEY_UPDATE:
            return {
                ...state,
                loading: false,
                updateStatus: payload
            }
        case LAND_SURVEY_FAIL:
            return {
                ...state,
                loading: false,
                error: payload
            }
        default:
            return state;

    }

}