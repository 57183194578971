import React, { useEffect, useState } from 'react';
import { Button, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { AiFillDelete, AiFillEye, AiOutlineEdit } from 'react-icons/ai';
import { layOutUrl } from '../../../../utils/Common';
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from 'react-router-dom';
import { getLayoutData } from '../../../../store/action/LayoutAction';
import { GetLandUrl } from '../../../../utils/Common';

export default function ListOfProperty({ userRfId }) {

    const dispatch = useDispatch();
    const selector = useSelector(state => state?.LayoutReducer);
    const history = useHistory();

    const [item, setItem] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchData = async () => {
        setIsLoading(true);
        await dispatch(getLayoutData(userRfId))
    }

    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        if (selector?.payload?.data?.message === "Success") {
            setItem(selector?.payload?.data?.data);
            setIsLoading(false);
        } else {
            setItem([]);
            setIsLoading(false);
        }
    }, [selector?.payload?.data]);

    // console.log(`selector?.payload?.data? = ${JSON.stringify(selector?.payload?.data)}`)

    return (
        <>
            {
                item?.length > 0 ?
                    <div className='d-flex flex-wrap justify-content-center'>
                        {
                            item?.map((item, index) => {
                                return <div key={index} value={item.rfId}>
                                    <div className='d-flex justify-content-center'>
                                        <div className="ListOfProperty">
                                            <Card className="card">
                                                <Card.Body>
                                                    <Card.Title>
                                                        <img src={item?.siteMap == "null" || item?.siteMap == "" || item?.siteMap == null ? GetLandUrl(item?.layoutType, item?.landType) : JSON.parse(item?.siteMap)[0]} alt="" className='ListOfProperty-img' />
                                                    </Card.Title>
                                                    <Card.Text>
                                                        <div>
                                                            <span className='PropertyName-heading'>{item?.layoutName}</span>
                                                        </div>
                                                        <span className='PropertyName-details'>{item?.districtName}</span>
                                                        <div className='d-flex justify-content-between mt-2'>
                                                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">View Property</Tooltip>}>
                                                                <Button variant="contained" size="small" style={{ textTransform: 'none', color: "white", backgroundColor: '#64b5e4' }} >
                                                                    <AiFillEye size={21} style={{ marginBottom: 2 }} />
                                                                </Button>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Edit Property</Tooltip>}>
                                                                <Button variant="contained" size="small" style={{ textTransform: 'none', color: "white", backgroundColor: '#FDB447' }} >
                                                                    <AiOutlineEdit size={21} style={{ marginBottom: 2 }} />
                                                                </Button>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Delete Property</Tooltip>}>
                                                                <Button variant="contained" size="small" style={{ textTransform: 'none', color: "white", backgroundColor: '#e77777', }} >
                                                                    <AiFillDelete size={20} style={{ marginBottom: 2 }} />
                                                                </Button>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </div>

                                </div>
                            })
                        }
                    </div> :
                    <div className='d-flex justify-content-center mt-5'>
                        <p className='font-weight-bold'>No Property Posted Yet</p>
                    </div>
            }

        </>

    )
}
