import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { TiArrowBack } from 'react-icons/ti';

function AddLandSurvey1() {

    const history = useHistory();

    const [selectedRfId, setSelectedRfId] = useState(null);

    const handleNext = () => {
        history.push('/AddLandSurvey2', { selectedRfId: selectedRfId }
        );
    };

    const handleSelect = (rfId) => {
        setSelectedRfId(rfId);
    };

    const data = [
        {
            rfId: 2,
            dataName: "We have purchased a new land or else we have our own nature land. How would you bifurcate such land based on our need.",
            dataNameTamil: "நாங்கள் ஒரு நிலம் வாங்கியுள்ளோம் அல்லது ஒரு பூர்வீக பாத்தியம் உள்ளது அவற்றை எங்களுக்கு ஏற்றவாறு பிரித்து தர வேண்டும்"
        },
        {
            rfId: 3,
            dataName: "We have purchased a land. Conduct a survey and provide us exact boundaries and area of the land.",
            dataNameTamil: "நாங்கள் ஒரு நிலம் வாங்கி உள்ளோம் அவற்றின் எல்லை மற்றும் பரப்பளவு சரியாக உள்ளதா என சர்வே செய்து தர வேண்டும்."
        },
        {
            rfId: 4,
            dataName: "We have already done Surveying for our Land. As it is not satisfactory, we request you to once again conduct Surveying for our Land.",
            dataNameTamil: "எங்களது நிலத்தை முன்கூட்டியே சர்வே செய்து வைத்துளோம் அதில் எங்களுக்கு திருப்தி அளிக்காததால் மீண்டும் சர்வே செய்து தர வேண்டும்."
        },
        {
            rfId: 5,
            dataName: "We have purchased a land. Conduct Survey on that land and provide us a report whether the land is suitable for bifurcating into layouts.",
            dataNameTamil: "நாங்கள் ஒரு நிலம் வாங்கியுள்ளோம் அதை மனைப்பிரிவுகளாக பிரிப்பதற்கும் மற்றும் பிரிக்க ஏற்றவாறு உள்ளதா என அறிய சர்வே செய்து தர வேண்டும்."
        },
        {
            rfId: 6,
            dataName: "Boundaries should be established within our land circumference.",
            dataNameTamil: "எங்களது நிலதைசுற்றி எல்லை நிர்ணயம் செய்து தர வேண்டும்."
        },
        {
            rfId: 7,
            dataName: "Boundaries should be established on the either side of our land.",
            dataNameTamil: "எங்களது நிலத்தின் ஒரு பக்கம் மட்டும் எல்லை நிர்ணயம் செய்து தர வேண்டும்."
        },
        {
            rfId: 8,
            dataName: "We cannot able to identify our own land's location. Kindly support us to identify the exact location.",
            dataNameTamil: "எங்களுக்கு சொந்தமான நிலம் எங்குள்ளது என்பது தெரியவில்லை. அவற்றை கண்டறிந்து தருமாறு கேட்டுக் கொள்கிறோம்."
        },
        {
            rfId: 9,
            dataName: "other reasons.",
            dataNameTamil: "மற்ற காரணங்கள்."
        }
    ]

    return (
        <div className='AddLandSurvey'>
            <div className='d-flex mt-2 mb-3'>
                <Button className='p-0'><TiArrowBack onClick={() => history.goBack()} size={35} /></Button>
                <p className='listings-headingWithBack'>Add Land Survey</p>
            </div>
            <div className='subHeading_Container'>
                <p className='listings-headingWithBack'>Select a reason for Survey</p>
            </div>
            {data.map((item, index) => (
                <div
                    key={item.rfId}
                    onClick={() => handleSelect(item.rfId)}
                    className={selectedRfId === item.rfId ? 'selected-item' : 'AddLandSurvey_item'}
                >
                    <div className='AddLandSurvey_index'>{index + 1}</div>
                    <div>
                        <div className='AddLandSurvey_content'>{item?.dataName}</div>
                        <div className='AddLandSurvey_content'>{item?.dataNameTamil}</div>
                    </div>


                </div>
            ))}
            <div className="col-md-12 text-center my-5">
                <Button className="btn btn-primary px-5"
                    onClick={handleNext}
                    disabled={!selectedRfId}
                >
                    Next</Button>
            </div>
        </div>
    )
}

export default AddLandSurvey1