import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { MemberSignIn } from '../../store/action/LoginAction';
import frame from '../../images/login/frame.png';
import Header from '../Layout/Header';

const LoginData = () => {

    const { register, handleSubmit, formState: { errors } } = useForm();

    const history = useHistory()

    // const dispatch = useDispatch()

    // const selector = useSelector(state => state?.LoginReducer); //{}

    const [formData, setFormData] = useState({
        registerType: 1,
        userName: "",
        companyName: "",
        mobileNo: ""
    });


    const { registerType, userName, companyName, mobileNo } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = data => {
        let formResponse = {
            companyName: companyName,
            userName: userName,
            mobileNo: mobileNo,
            registerType: registerType,
            inputSource: "Android",
            loginUserId: "12",
            remarks: "karthi",
            action: "Sign Up"
        }
        axios.post("https://www.sreeragu.com/api/login", formResponse).then((response) => {

            history.push("/Login-page")

        }).catch((Err) => {

            console.log(`response -${Err}`)

        })
    };

    const onOtpSubmit = data => {
        // dispatch(Login(mobileNo,otp , history))           
    };

    // useEffect(()=>{    
    //     if(selector?.payload?.data?.status_code == 400){
    //         setButtonShow(false)
    //         setLoader(false);
    //         setAlert({ ...alert, type :  selector?.payload?.data?.alert , color :  "warning"})  
    //         // history("/demo/employer-sign")       
    //     }
    //     if(selector?.payload?.data?.status_code == 200){
    //         setButtonShow(true)
    //         setLoader(false);
    //         setAlert({ ...alert, type :  selector?.payload?.data?.alert , color :  "success" })         
    //     }

    // },[selector?.payload?.data?.status_code == 200 || selector?.payload?.data?.status_code == 400])

    return (
        <>
            <Header />
            <div className='container-fluid'>
                {/* { loader && <Loader /> } */}
                <div className="row login-row-con ">
                    <div className="col-lg-6 col-md-6" style={{ backgroundColor: '#e1e1e1' }}>
                        <div className="row">
                            <div className="col text-center">
                                <img src={frame} alt="" className='img-fluid mt-sm-5' width={550} />
                            </div>
                        </div>
                        <div className="row login-text-con">
                            <div className="col ">
                                <h2 className='login-text-con-h2'>Sign Up To You Now</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6  align-self-center pt-3">
                        <form onSubmit={handleSubmit(onSubmit)} className="col-12 login-form px-sm-5 ">
                            <p className="font-weight-600">If you have an account with us, please log in.</p>
                            <label>Land Owner*</label><input type="radio" name="registerType" id="registerType" value="2" onChange={e => onChange(e)} className="" /> <label>User</label><input type="radio" value="3" onChange={e => onChange(e)} name="registerType" id="registerType" className="" />
                            <div className="form-group">
                                <label>User Name*</label>
                                {errors?.userName && <label className="control-label error-alert" for="inputError">Please Fill Valid User Name</label>}
                                <div className="input-group">
                                    <input type="text" name="userName" id="userName" className="form-control pb-md-4 pt-md-4 pb-sm-1 pl-sm-1"
                                        value={userName}  {...register("userName", { minLength: 3, maxLength: 15, })} onChange={e => onChange(e)} placeholder="User Name" />
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Company Name*</label>
                                {errors?.companyName && <label className="control-label error-alert" for="inputError">Please Fill Valid Company Name</label>}
                                <div className="input-group">
                                    <input type="text" name="companyName" id="companyName" {...register("companyName", { minLength: 3, maxLength: 15, })} value={companyName} onChange={e => onChange(e)} className="form-control pb-md-4 pt-md-4 pb-sm-1 pl-sm-1" placeholder="Company Name" />
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Mobile Number*</label>
                                {errors?.mobileNo && <label className="control-label error-alert" for="inputError">Please Fill Valid Mobile No</label>}
                                <div className="input-group">
                                    <input type="text" name="mobileNo" id="mobileNo"  {...register("mobileNo", { required: true, minLength: 10, maxLength: 10, })} value={mobileNo} onChange={e => onChange(e)}
                                        className="form-control pb-md-4 pt-md-4 pb-sm-1 pl-sm-1" placeholder="Mobile No" />
                                </div>
                            </div>
                            <div className="text-center">
                                <button className="btn btn-primary float-left pl-4 pr-4">Sign Up</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LoginData
