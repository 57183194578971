import React from 'react';
import AgentListTable from './AgentListTable'

const AgentHome = () => {
    return (
        <>
            <p className='listings-heading'>Agent</p>
            <AgentListTable />
        </>
    )
}

export default AgentHome