import swal from 'sweetalert';
import {
    formatError,
    login,
    runLogoutTimer,
    saveTokenInLocalStorage,
    signUp,
} from '../../services/AuthService';

export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const OTP_SEND_ACTION = 'Otp Send SuccessFully';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';

export function signupAction(formData, history) {

    const { name, companyName, mobileNo, memberType, email } = formData;
    return (dispatch) => {
        signUp(name, companyName, mobileNo, memberType, email)
            .then((response) => {
                history.push('/user-management');
            })
            .catch((error) => {
                // const errorMessage = SignUpformatError(error.response.data);
                history.push('/user-management');
            });
    };
}

export function logout(history, data = 0) {
    localStorage.removeItem('user');
    data == 0 ? history.push('/#/Login-page') : history.push('/Login-page');
    swal("Success", "Logged Out Successfully", "success");
    return {
        type: LOGOUT_ACTION,
    };
}

// export function loginActionOtpSend(mailId, history) {
//     return (dispatch) => {
//         sendOtp(mailId)
//             .then((response) => {                   
//                 formatError(response.data);
//                	dispatch(otpSendConfirmedAction(OTP_SEND_ACTION))
//             })
//             .catch((error) => {                
//                 const errorMessage = formatError(error.response.data);
//                 dispatch(loginFailedAction(errorMessage));
//             });
//     };
// }
export function loginAction(mailId, otp, history) {
    return (dispatch) => {
        login(mailId, otp)
            .then((response) => {
                saveTokenInLocalStorage(response.data);
                // runLogoutTimer(
                //     dispatch,
                //     response.data.expiresIn * 1000,
                //     history,
                // );
                dispatch(loginConfirmedAction(response.data.data));
                history.push('/dashboard');
            })
            .catch((error) => {
                const errorMessage = formatError(error.response.data);
                dispatch(loginFailedAction(errorMessage));
            });
    };
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}
export function otpSendConfirmedAction(data) {
    return {
        type: OTP_SEND_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
