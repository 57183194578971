import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import Modal from 'react-bootstrap/Modal';
import '../../css/design10.css';
import { useEffect } from 'react';
import { getLayoutRegistrationData } from "../../store/action/LayoutAction";
import { commonFilter } from "../../utils/Common";


export default function Design10({ data }) {
    const dispatch = useDispatch();
    const selector = useSelector(state => state?.LayoutReducer);
    const [show, setShow] = useState(false);
    const [modelData, setModelData] = useState({});
    const [layoutData, setLayoutData] = useState([]);
    const handleClose = () => setShow(false);
    const handleShow = (modelLayoutData) => {
        setModelData(modelLayoutData)
        setShow(true);
    }
    useEffect(() => {
        dispatch(getLayoutRegistrationData("SRCLAND00001"));
    }, []);
    useEffect(() => {
        if (selector?.payload?.data?.message === "Success") {
            setLayoutData(selector?.payload?.data?.data)
        }
    }, [selector?.payload?.data])

    const design10 = {
        layout1: [
            {
                itemNo: 'item1',
                box_number: 1,
                boxSize_t: '30',
                boxSize_r: '50',
                boxSize_b: '30',
                boxSize_l: '50',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item2',
                box_number: 2,
                boxSize_t: '15.3+14.9',
                boxSize_r: '5+45',
                boxSize_b: '30',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item3',
                box_number: 3,
                boxSize_t: '',
                boxSize_r: '30',
                boxSize_b: '60',
                boxSize_l: '30',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item4',
                box_number: 4,
                boxSize_t: '',
                boxSize_r: '30',
                boxSize_b: '60',
                boxSize_l: '30',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item5',
                box_number: 5,
                boxSize_t: '',
                boxSize_r: '29.6',
                boxSize_b: '60',
                boxSize_l: '29.6',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item6',
                box_number: 6,
                boxSize_t: '',
                boxSize_r: '30',
                boxSize_b: '60',
                boxSize_l: '30',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item7',
                box_number: 7,
                boxSize_t: '',
                boxSize_r: '30',
                boxSize_b: '60',
                boxSize_l: '30',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item8',
                box_number: 8,
                boxSize_t: '',
                boxSize_r: '35',
                boxSize_b: '60',
                boxSize_l: '35',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
        ],
        layout2: [
            {
                itemNo: 'item51',
                box_number: 51,
                boxSize_t: '14.9+20.3',
                boxSize_r: '',
                boxSize_b: '35',
                boxSize_l: '5.65',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item52',
                box_number: 52,
                boxSize_t: '35.2',
                boxSize_r: '',
                boxSize_b: '35',
                boxSize_l: '70',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item53',
                box_number: 53,
                boxSize_t: '30.3',
                boxSize_r: '',
                boxSize_b: '30',
                boxSize_l: '73.7',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item54',
                box_number: 54,
                boxSize_t: '30.1',
                boxSize_r: '',
                boxSize_b: '30',
                boxSize_l: '77.3',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item55',
                box_number: 55,
                boxSize_t: '30.1',
                boxSize_r: '',
                boxSize_b: '30',
                boxSize_l: '75.1',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item56',
                box_number: 56,
                boxSize_t: '35',
                boxSize_r: '',
                boxSize_b: '35',
                boxSize_l: '72.11',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item57',
                box_number: 57,
                boxSize_t: '48.8',
                boxSize_r: '69.7',
                boxSize_b: '29.9',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item50',
                box_number: 50,
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '35',
                boxSize_l: '35',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item49',
                box_number: 49,
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '35',
                boxSize_l: '40',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item48',
                box_number: 48,
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '30',
                boxSize_l: '40',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item47',
                box_number: 47,
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '30',
                boxSize_l: '40',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item46',
                box_number: 46,
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '30',
                boxSize_l: '40',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item45',
                box_number: 45,
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '30',
                boxSize_l: '40',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item44',
                box_number: 44,
                boxSize_t: '34.9',
                boxSize_r: '41.7',
                boxSize_b: '23.6',
                boxSize_l: '40',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
        ],
        layout3: [
            {
                itemNo: 'item38',
                box_number: 38,
                boxSize_t: '35',
                boxSize_r: '',
                boxSize_b: '35',
                boxSize_l: '55',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item39',
                box_number: 39,
                boxSize_t: '35',
                boxSize_r: '',
                boxSize_b: '35',
                boxSize_l: '55',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item40',
                box_number: 40,
                boxSize_t: '35',
                boxSize_r: '',
                boxSize_b: '35',
                boxSize_l: '55',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item41',
                box_number: 41,
                boxSize_t: '30',
                boxSize_r: '',
                boxSize_b: '30',
                boxSize_l: '55',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item42',
                box_number: 42,
                boxSize_t: '30',
                boxSize_r: '',
                boxSize_b: '30',
                boxSize_l: '55',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item43',
                box_number: 43,
                boxSize_t: '40.2',
                boxSize_r: '57.2',
                boxSize_b: '24.9',
                boxSize_l: '55',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item37',
                box_number: 37,
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '35',
                boxSize_l: '40',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item36',
                box_number: 36,
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '19',
                boxSize_l: '40',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item35',
                box_number: 35,
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '19',
                boxSize_l: '40',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item34',
                box_number: 34,
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '19',
                boxSize_l: '40',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item33',
                box_number: 33,
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '19',
                boxSize_l: '40',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item32',
                box_number: 32,
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '19',
                boxSize_l: '40',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item31',
                box_number: 31,
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '19',
                boxSize_l: '40',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item30',
                box_number: 30,
                boxSize_t: '40.3',
                boxSize_r: '41.7',
                boxSize_b: '29.6',
                boxSize_l: '40',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
        ],
        layout4: [
            {
                itemNo: 'item9',
                box_number: 9,
                boxSize_t: '60',
                boxSize_r: '30',
                boxSize_b: '',
                boxSize_l: '30',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item10',
                box_number: 10,
                boxSize_t: '60',
                boxSize_r: '30',
                boxSize_b: '',
                boxSize_l: '30',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item11',
                box_number: 11,
                boxSize_t: '60',
                boxSize_r: '30',
                boxSize_b: '',
                boxSize_l: '30',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item12',
                box_number: 12,
                boxSize_t: '60',
                boxSize_r: '29.6',
                boxSize_b: '',
                boxSize_l: '29.6',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item13',
                box_number: 13,
                boxSize_t: '60',
                boxSize_r: '35',
                boxSize_b: '',
                boxSize_l: '29.6',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'itempp',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
        ],
        layout5: [
            {
                itemNo: 'item25',
                box_number: 25,
                boxSize_t: '35',
                boxSize_r: '',
                boxSize_b: '35',
                boxSize_l: '50',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item26',
                box_number: 26,
                boxSize_t: '35',
                boxSize_r: '',
                boxSize_b: '35',
                boxSize_l: '50',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item27',
                box_number: 27,
                boxSize_t: '30',
                boxSize_r: '',
                boxSize_b: '30',
                boxSize_l: '50',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item28',
                box_number: 28,
                boxSize_t: '30',
                boxSize_r: '',
                boxSize_b: '30',
                boxSize_l: '50',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item29',
                box_number: 29,
                boxSize_t: '40.3',
                boxSize_r: '51.11',
                boxSize_b: '26.3',
                boxSize_l: '50',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item24',
                box_number: 24,
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '19',
                boxSize_l: '45',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item23',
                box_number: 23,
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '19',
                boxSize_l: '40',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item22',
                box_number: 22,
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '19',
                boxSize_l: '40',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item21',
                box_number: 21,
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '19',
                boxSize_l: '40',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item20',
                box_number: 20,
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '19',
                boxSize_l: '40',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item19',
                box_number: 19,
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '19',
                boxSize_l: '40',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item18',
                box_number: 18,
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '19',
                boxSize_l: '40',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'itemEB',
                boxSize_t: '23.2',
                boxSize_r: '41.7',
                boxSize_b: '12',
                boxSize_l: '40',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
        ],
        layout6: [
            {
                itemNo: 'item14',
                box_number: 14,
                boxSize_t: '35',
                boxSize_r: '50',
                boxSize_b: '35',
                boxSize_l: '50',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item15',
                box_number: 15,
                boxSize_t: '35',
                boxSize_r: '50',
                boxSize_b: '35',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item16',
                box_number: 16,
                boxSize_t: '35',
                boxSize_r: '50',
                boxSize_b: '35',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item17',
                box_number: 17,
                boxSize_t: '36.9',
                boxSize_r: '52',
                boxSize_b: '22.8',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
        ]

    }

    return (
        <>
            {
                data === "true" && <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="true"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Land Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Site Identification No: {modelData?.box_number} <br />
                        Site Size Details:{modelData?.boxSize_t} {modelData?.boxSize_r} {modelData?.boxSize_b} {modelData?.boxSize_l}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            }

            <div className="container">
                <div className="">
                    <div className='design-10'>
                        <div className='main_layout'>
                            <div className='layout-1'>
                                {
                                    design10.layout1.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemNo} ${data.status == 1 ? 'background-color-success' : 'background-color-fail'} design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxSize_t ? "boxSize_t" : ""}> {data.boxSize_t ? data.boxSize_t : ""}</div>
                                                <div className={data.boxSize_b ? "boxSize_b" : ""}> {data.boxSize_b ? data.boxSize_b : ""}</div>
                                                <div className={data.boxSize_l ? "boxSize_l" : ""}> {data.boxSize_l ? data.boxSize_l : ""}</div>
                                                <div className={data.boxSize_r ? "boxSize_r" : ""}> {data.boxSize_r ? data.boxSize_r : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                            </div>
                            <div className='layout-2'>
                                {
                                    design10.layout2.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemNo} ${data.status == 1 ? 'background-color-success' : 'background-color-fail'} design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxSize_t ? "boxSize_t" : ""}> {data.boxSize_t ? data.boxSize_t : ""}</div>
                                                <div className={data.boxSize_b ? "boxSize_b" : ""}> {data.boxSize_b ? data.boxSize_b : ""}</div>
                                                <div className={data.boxSize_l ? "boxSize_l" : ""}> {data.boxSize_l ? data.boxSize_l : ""}</div>
                                                <div className={data.boxSize_r ? "boxSize_r" : ""}> {data.boxSize_r ? data.boxSize_r : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                            </div>
                            <div className='layout-3'>
                                {
                                    design10.layout3.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemNo} ${data.status == 1 ? 'background-color-success' : 'background-color-fail'} design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxSize_t ? "boxSize_t" : ""}> {data.boxSize_t ? data.boxSize_t : ""}</div>
                                                <div className={data.boxSize_b ? "boxSize_b" : ""}> {data.boxSize_b ? data.boxSize_b : ""}</div>
                                                <div className={data.boxSize_l ? "boxSize_l" : ""}> {data.boxSize_l ? data.boxSize_l : ""}</div>
                                                <div className={data.boxSize_r ? "boxSize_r" : ""}> {data.boxSize_r ? data.boxSize_r : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                            </div>
                            <div className='layout-4'>
                                {
                                    design10.layout4.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemNo} ${data.status == 1 ? 'background-color-success' : 'background-color-fail'} design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxSize_t ? "boxSize_t" : ""}> {data.boxSize_t ? data.boxSize_t : ""}</div>
                                                <div className={data.boxSize_b ? "boxSize_b" : ""}> {data.boxSize_b ? data.boxSize_b : ""}</div>
                                                <div className={data.boxSize_l ? "boxSize_l" : ""}> {data.boxSize_l ? data.boxSize_l : ""}</div>
                                                <div className={data.boxSize_r ? "boxSize_r" : ""}> {data.boxSize_r ? data.boxSize_r : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                            </div>
                            <div className='layout-5'>
                                {
                                    design10.layout5.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemNo} ${data.status == 1 ? 'background-color-success' : 'background-color-fail'} design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxSize_t ? "boxSize_t" : ""}> {data.boxSize_t ? data.boxSize_t : ""}</div>
                                                <div className={data.boxSize_b ? "boxSize_b" : ""}> {data.boxSize_b ? data.boxSize_b : ""}</div>
                                                <div className={data.boxSize_l ? "boxSize_l" : ""}> {data.boxSize_l ? data.boxSize_l : ""}</div>
                                                <div className={data.boxSize_r ? "boxSize_r" : ""}> {data.boxSize_r ? data.boxSize_r : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                            </div>
                            <div className='layout-6'>
                                {
                                    design10.layout6.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemNo} ${data.status == 1 ? 'background-color-success' : 'background-color-fail'} design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxSize_t ? "boxSize_t" : ""}> {data.boxSize_t ? data.boxSize_t : ""}</div>
                                                <div className={data.boxSize_b ? "boxSize_b" : ""}> {data.boxSize_b ? data.boxSize_b : ""}</div>
                                                <div className={data.boxSize_l ? "boxSize_l" : ""}> {data.boxSize_l ? data.boxSize_l : ""}</div>
                                                <div className={data.boxSize_r ? "boxSize_r" : ""}> {data.boxSize_r ? data.boxSize_r : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                            </div>


                            <div className="road-1">10 M layout Road </div>
                            <div className="road-2">9 M layout Road </div>
                            <div className="road-3">9 M layout Road </div>
                            <div className="road-4">9 M layout Road </div>
                            <div className="road-5"> </div>
                            <div className="road-6">19.6 M Panchyat Road </div>




                            <div className="whitebox1"></div>
                            <div className="whitebox2"></div>
                            <div className="whitebox3"></div>



                            <div className="sf1">PARK</div>
                            <div className="sf2">PP</div>
                            <div className="sf3">EB</div>

                            <div className="sm1">43.8M</div>
                            <div className="sm2">18.2M</div>
                            <div className="sm3">40.8M</div>
                            <div className="sm4">39.8(46.6)M</div>



                            {/* <div className="rs1">177'</div>    */}


                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}