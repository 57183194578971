import axios from 'axios';
import { PERMISSION_FAIL, PERMISSION_SUCCESS, PERMISSION_VIEW } from  "../type/types";
import { Url, getTokenLocalStorage } from '../../utils/Common';
import swal from 'sweetalert';

// Otp Verification
export const getPermissionData = () => async dispatch =>{
    const token  = getTokenLocalStorage();
    const formData = {
        "action" : "View",
        "type" : "Permission",
        "order" : "asc"
    }
    const headers = {"x-auth-header" : `${token}` }         
    axios.post(`${Url}bind`,formData , { headers } ).then((response)=>{     
        dispatch({
            payload : response,
            type : PERMISSION_SUCCESS
        })      
    }).catch((err)=>{                  
        dispatch({
            payload : err.response,
            type : PERMISSION_FAIL
        })
    })   
}
