import React from 'react';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const bnr1 = require('./../../images/main-slider/slide2.png');

const IndexBanner = () => {
	return (
		<div className="dez-bnr-inr dez-bnr-inr-md" style={{ backgroundImage: `url(${bnr1})` }}>
			<div className="container">
				<div className="dez-bnr-inr-entry align-m">
					<div className="find-job-bx">
						<Link to="/" className="btn btn-primary mb-5">
							Find Sites, Open & Empty land, Houses & Appartments
						</Link>
						<h2>
							Search Between More Then <br />2000 Properties
						</h2>
					</div>
				</div>
			</div>
		</div>
	);
};

export default IndexBanner;
