import SearchIcon from '@mui/icons-material/Search';
import { default as Box } from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { default as IconButton } from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { default as Typography } from '@mui/material/Typography';
import { green } from '@mui/material/colors';
import { alpha, styled } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { IoMdAddCircle } from 'react-icons/io';
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from 'react-router-dom';
import { Dispatch } from 'redux';
import { getLayoutData } from '../../../../store/action/LayoutAction';
import { DtSentenceCase, GetLandUrl } from '../../../../utils/Common';
import PropertyDetailPage from './PropertyDetailPage';
import swal from 'sweetalert';
import { deleteLayoutDeleteData, showHidePropertyStatus } from '../../../../store/action/LayoutAction';
import Loader1 from '../../../../utils/Loader1';
import CroEntryProperty from './CroEntryProperty';
import { IoNavigate } from "react-icons/io5";
import RateUnit from '../../../../utils/RateUnit';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'layoutImage',
        numeric: false,
        label: 'Image',
    },
    {
        id: 'layoutName',
        numeric: false,
        label: 'Property Name',
    },
    {
        id: 'landTypeName',
        numeric: false,
        label: 'Type',
    },
    {
        id: 'sitearea',
        numeric: false,
        label: 'Area',
    },
    {
        id: 'navigation',
        numeric: false,
        label: 'Location',
    },
    {
        id: 'approximateRate',
        numeric: false,
        label: 'Price',
    },
    {
        id: 'insertedDateTime',
        numeric: false,
        label: 'Posted Date & Time',
    },
    {
        id: 'action',
        numeric: false,
        label: 'Action',
    },

];


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        border: '1px solid lightgrey',
        borderRadius: '5px',
        height: '15px',
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));


function ListingDataTableHead(props) {

    const { order, orderBy, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{ width: headCell.width }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

ListingDataTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function ListingDataTableToolbar({ addStaff, setLayoutType, search, setSearch }) {

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
    };

    const [activeButton, setActiveButton] = useState('all');

    const handleButtonClick = (buttonId, type) => {
        setActiveButton(buttonId);
        setLayoutType(type)
    };

    return (
        <div className='d-flex justify-content-between mx-5'>
            <Typography>
                <Search>
                    <SearchIconWrapper>
                        <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="Search…"
                        value={search}
                        onChange={handleSearchChange}
                    />
                </Search>
            </Typography>
            <ButtonGroup variant="contained" aria-label="primary button group" size="medium">
                <Button
                    onClick={() => handleButtonClick('all', 0)}
                    style={{ textTransform: 'none', backgroundColor: activeButton === 'all' ? '#3786FB' : '#64b5e4' }}
                >
                    All
                </Button>
                <Button
                    onClick={() => handleButtonClick('plots', 1)}
                    style={{ textTransform: 'none', backgroundColor: activeButton === 'plots' ? '#3786FB' : '#64b5e4' }}
                >
                    Plots
                </Button>
                <Button
                    style={{ textTransform: 'none', backgroundColor: activeButton === 'land' ? '#3786FB' : '#64b5e4' }}
                    onClick={() => handleButtonClick('land', 3)}
                >
                    Lands
                </Button>
                <Button
                    style={{ textTransform: 'none', backgroundColor: activeButton === 'house' ? '#3786FB' : '#64b5e4' }}
                    onClick={() => handleButtonClick('house', 2)}
                >
                    Houses
                </Button>
            </ButtonGroup>
            <Button variant="contained" startIcon={<IoMdAddCircle />} onClick={() => addStaff("staffAdd")} size="medium" style={{ textTransform: 'none', color: "white", backgroundColor: '#3786FB' }} >Add Property</Button>
        </div>
    );
}

ListingDataTableToolbar.propTypes = {
    search: PropTypes.string.isRequired,
    setSearch: PropTypes.func.isRequired,
};


export default function PropertyListTable(props) {
    //Model related States
    const { addStaff } = props;
    const history = useHistory();
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [croModalOpen, setCroModalOpen] = useState(false);
    const dispatch = useDispatch();
    const selector = useSelector(state => state?.LayoutReducer);
    const loading = selector.loading;
    const [layoutType, setLayoutType] = useState(0)
    const [layoutData, setLayoutData] = useState([])
    const [modelData, setModelData] = useState({});
    const [search, setSearch] = useState('');

    //Table Related States
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('sqft');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(() => {

        fetchData();
    }, [layoutType]);

    const fetchData = async () => {
        dispatch(getLayoutData("", 0, layoutType));
    }

    useEffect(() => {
        if (selector?.payload?.data?.message === "Success") {
            //console.log(`response -${JSON.stringify(selector?.payload?.data?.data)}`)
            setLayoutData(selector?.payload?.data?.data)
        }
    }, [selector?.payload?.data])

    const editDetailsHandler = async (layoutData) => {
        // console.log(`EditPlot Data -${JSON.stringify(layoutData)}`)
        // await addStaff("staffAdd", layoutData)
        if (layoutData.layoutTypeName === 'Site Wise Plot') {
            history.push({ pathname: "/EditPlot", state: { layoutData: layoutData } });
        } else if (layoutData.layoutTypeName === 'Land') {
            history.push({ pathname: "/EditLand", state: { layoutData: layoutData } });
        } else if (layoutData.layoutTypeName === 'House') {
            history.push({ pathname: "/EditHouse", state: { layoutData: layoutData } });
        }
    }

    const filteredData = layoutData.filter((item) => {
        return (
            (item?.layoutName || '').toString().toLowerCase().includes(search.toLowerCase()) ||
            (item?.landTypeName || '').toString().toLowerCase().includes(search.toLowerCase()) ||
            (item?.districtName || '').toString().toLowerCase().includes(search.toLowerCase()) ||
            (item.insertedDateTime || '').toString().toLowerCase().includes(search.toLowerCase()) ||
            (item?.landAreaTypeName || '').toString().toLowerCase().includes(search.toLowerCase()) ||
            (item?.approximateRate || '').toString().includes(search.toLowerCase()) ||
            (item?.totalLandArea || '').toString().includes(search.toLowerCase()) ||
            (item?.insertedDateTime || '').toString().includes(search.toLowerCase())
        );
    });

    //Delete Button Click
    const deleteButtonClick = async (rfId, userRfId) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure that you want to delete this Property?",
            icon: "warning",
            showCancelButton: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                await dispatch(deleteLayoutDeleteData(rfId, userRfId));
                fetchData();
            }
        })
    }

    //Show Button Click
    const HideButtonClick = async (item) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure that you want to Hide / Show this Property?",
            icon: "warning",
            showCancelButton: true,
            dangerMode: true,
        }).then(async (willUpdate) => {
            if (willUpdate) {
                await dispatch(showHidePropertyStatus(item?.rfId, item?.userRfId, item?.layoutStatus));
                fetchData();
            }
        })
    }

    const croHandler = (data) => {
        //history.push("/CroEntryProperty")
        setModelData(data)
        setCroModalOpen(true);
    }

    const ViewHandler = (data) => {
        setModelData(data)
        setViewModalOpen(true);
    }

    const viewCloseHandler = () => setViewModalOpen(false);
    const croCloseHandler = () => setCroModalOpen(false);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const showInMapClicked = (latitude, longitude) => {
        window.open("https://maps.google.com?q=" + latitude + "," + longitude);
    };

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredData.length) : 0;


    return (
        <>
            <ListingDataTableToolbar addStaff={addStaff} setLayoutType={setLayoutType} search={search} setSearch={setSearch} />
            {loading ? <div className='mt-5'> <Loader1 /></div> :
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Paper sx={{ width: '95%', mb: 2 }}>
                        <TableContainer>
                            <Table
                                sx={{ width: '100%', marginLeft: 5, justifyContent: 'center' }}
                            >

                                <ListingDataTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    rowCount={filteredData.length}
                                />

                                <TableBody>
                                    {stableSort(filteredData, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((item, index) => {
                                            return (
                                                <TableRow
                                                    tabIndex={-1}
                                                    key={item.rfId}
                                                    className='DataTable-row'
                                                    style={{ height: 70, justifyContent: 'center', opacity: item?.layoutStatus === 1 ? 1 : 0.5 }}
                                                >
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        padding="none"
                                                        align='left'
                                                        sx={{ minWidth: 50 }}

                                                    >
                                                        <img
                                                            src={item?.siteMap == "null" || item?.siteMap == "" || item?.siteMap == null ? GetLandUrl(item?.layoutType, item?.landType) : JSON.parse(item?.siteMap)[0]}
                                                            alt="" className='PropertyListTable-img' />
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        padding="none"
                                                        sx={{ minWidth: 100 }}
                                                    >
                                                        {item.layoutName} <br /> {DtSentenceCase(item.districtName)}
                                                    </TableCell >
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        padding="none"
                                                        sx={{ minWidth: 100 }}
                                                    >{item.landTypeName}</TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        padding="none"
                                                        sx={{ minWidth: 70 }}
                                                    >{item?.totalLandArea} {item.landAreaTypeName}</TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        padding="none"
                                                        sx={{ minWidth: 80 }}
                                                    >
                                                        <Button onClick={() => showInMapClicked(item?.latitude, item?.longitude)} variant="contained" size="small" style={{ textTransform: 'none', color: "white", backgroundColor: '#64b5e4' }} >
                                                            <IoNavigate size={18} />
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        padding="none"
                                                        sx={{ minWidth: 80 }}
                                                    >{RateUnit(item)}</TableCell>

                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        padding="none"
                                                        sx={{ minWidth: 120 }}
                                                    >{item.insertedDateTime}</TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        padding="none"
                                                        sx={{ minWidth: 200 }}>
                                                        <Button variant="contained" size="small" style={{ textTransform: 'none', color: "white", backgroundColor: '#64b5e4', marginRight: 10 }} onClick={() => ViewHandler(item)} >View</Button>
                                                        <Button variant="contained" size="small" style={{ textTransform: 'none', color: "white", backgroundColor: '#FDB447', marginRight: 10 }} onClick={() => editDetailsHandler(item)}>Edit</Button>
                                                        {/* <Button variant="contained" size="small" style={{ textTransform: 'none', color: "white", backgroundColor: '#e77777', marginRight: 10 }} onClick={() => deleteButtonClick(item?.rfId, item?.userRfId)}>Delete</Button> */}
                                                        <Button variant="contained" size="small" style={{ textTransform: 'none', color: "white", backgroundColor: '#e777b6', marginRight: 10 }} onClick={() => HideButtonClick(item)}>{item?.layoutStatus === 1 ? 'Hide' : 'Show'}</Button>
                                                        <Button variant="contained" size="small" style={{ textTransform: 'none', color: "white", backgroundColor: '#81d06f' }} onClick={() => croHandler(item)}>CRO</Button>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={filteredData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                    {/* Popup Model starts for View */}
                    <Modal
                        show={viewModalOpen}
                        onHide={viewCloseHandler}
                        backdrop="true"
                        keyboard={false}
                        size='lg'
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Property Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <PropertyDetailPage item={modelData} />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={viewCloseHandler}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    {/* Popup Model starts for CRO */}
                    <Modal
                        show={croModalOpen}
                        onHide={croCloseHandler}
                        backdrop="true"
                        keyboard={false}
                        size='lg'
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>CRO Entry Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <PropertyDetailPage item={modelData} />
                            <CroEntryProperty item={modelData} />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={croCloseHandler}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Box>
            }
        </>

    );
}