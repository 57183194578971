import { SITE_VIEW, SITE_INSERT, SITE_SUCCESS, SITE_FAIL, SITE_UPDATE, SITE_DELETE  } from  "../type/types";

const initialState = {       
    loading : true,
    payload : [],
    editData : [],
    error : {}
}

export const SiteReducer = ( state = initialState , action ) =>{
    const {type , payload} = action;
    switch(type){
        case SITE_VIEW :
            return{
                ...state,               
                loading:false,
                payload:payload
            }      
        case SITE_INSERT :
            return{
                ...state,               
                loading:false,
                editData:payload
            }      
        case SITE_UPDATE :
            return{
                ...state,               
                loading:false,
                editData:payload
            }      
        case SITE_FAIL :
            return{
                ...state,               
                loading:false,
                error:payload
            }      
            default:
                return state;

    }

}