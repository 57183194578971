import axios from 'axios';
import { Url, getTokenLocalStorage } from '../../utils/Common';
import { BIND_SUCCESS, BIND_FAIL, BIND_MEMBER_VIEW, BIND_DISTRICT_SUCCESS, BIND_SUBZONE_SUCCESS, BIND_DTDCDATA_SUCCESS, BIND_LANDTYPE_SUCCESS, BIND_AREAUNIT_SUCCESS, BIND_RADIUS_SUCCESS, BIND_FOCUS_TYPE_SUCCESS, BIND_PURPOSE_TYPE_SUCCESS, BIND_PAYMENT_TYPE_SUCCESS, BIND_MARRIED_STATUS_SUCCESS, BIND_JOB_SECTOR_SUCCESS, BIND_CURRENT_STATUS_SUCCESS, BIND_CHILDREN_SUCCESS, BIND_PROPERTY_AREA_REQUIRED_SUCCESS, BIND_EMI_COMMITMENTS_SUCCESS, BIND_LIVING_SPACE_SUCCESS, BIND_NEAR_BY_SERVICES_SUCCESS } from "../type/types";


// Bind Table Data
export const getBindData = (actionData) => async dispatch => {
    const token = getTokenLocalStorage();
    const formData = {
        "action": "View",
        "type": actionData
    }
    const headers = { "x-auth-header": `${token}` }
    axios.post(`${Url}bind`, formData, { headers }).then((response) => {
        dispatch({
            payload: response,
            type: BIND_SUCCESS
        })
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: BIND_FAIL
        })
    })
}

// Bind Table Get District Data
export const getDistrictData = (zone = 2) => async dispatch => {
    const token = getTokenLocalStorage();
    let formData = {
        "action": "View",
        "type": "District",
        "stateId": 1,
        "orderName": "districtName",
        "order": "asc",
        "zone": zone
    }
    const headers = { "x-auth-header": `${token}` }
    axios.post(`${Url}bind`, formData, { headers }).then((response) => {
        //console.log(`getDistrictData = ${JSON.stringify(getDistrictData)}`)
        if (response?.data?.message == 'Success') {
            dispatch({
                payload: response,
                type: BIND_DISTRICT_SUCCESS
            })
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: BIND_FAIL
        })
    })
}

// Bind Table Get Sub Zone Data
export const getSubZoneData = (districtId) => async dispatch => {
    const token = getTokenLocalStorage();
    let formData = {
        "action": "View",
        "type": "Sub Zone",
        "districtId": districtId
    }
    const headers = { "x-auth-header": `${token}` }
    axios.post(`${Url}bind`, formData, { headers }).then((response) => {
        //console.log(`getSubZoneData = ${JSON.stringify(getSubZoneData)}`)
        if (response?.data?.message == 'Success') {
            dispatch({
                payload: response,
                type: BIND_SUBZONE_SUCCESS
            })
        }
    }).catch((err) => {

        dispatch({
            payload: err.response,
            type: BIND_FAIL
        })
    })
}

// Bind Table DTDC Consultant  Data
export const getDTCPApprovalData = () => async dispatch => {
    const token = getTokenLocalStorage();
    let formData = {
        "action": "View",
        "type": "Dtcp Consult",
        "order": "asc"
    }
    const headers = { "x-auth-header": `${token}` }
    axios.post(`${Url}bind`, formData, { headers }).then((response) => {
        // console.log(`getDTCPApprovalData = ${JSON.stringify(response)}`)
        if (response?.data?.message == 'Success') {
            dispatch({
                payload: response,
                type: BIND_DTDCDATA_SUCCESS
            })
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: BIND_FAIL
        })
    })
}

// Bind Table Get District Data
export const getBindMemberSingleData = () => async dispatch => {
    const token = getTokenLocalStorage();
    const headers = { "x-auth-header": `${token}` }
    let formData = {
        "action": "View",
        "registerType": 2,
        "rfId": "",
        "limit": "",
        "order": "",
        "mobileNo": "",
        "FromDate": "",
        "ToDate": "",
        "districtId": "",
        "blockId": "",
        "stateId": "",
        "type": "Single View"
    }
    axios.post(`${Url}member`, formData, { headers }).then((response) => {
        if (response?.data?.message == 'Success') {
            dispatch({
                payload: response,
                type: BIND_MEMBER_VIEW
            })
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: BIND_FAIL
        })
    })
}

// Bind Table Get LandType Data
export const getBindLandTypeData = () => async dispatch => {
    const token = getTokenLocalStorage();
    const headers = { "x-auth-header": `${token}` }
    let formData = {
        "action": "View",
        "type": "Survey Land Type",
        "order": "asc"
    }
    axios.post(`${Url}bind`, formData, { headers }).then((response) => {
        // console.log(`getBindLandTypeData = ${JSON.stringify(response)}`)
        if (response?.data?.message == 'Success') {
            dispatch({
                payload: response,
                type: BIND_LANDTYPE_SUCCESS
            })
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: BIND_FAIL
        })
    })
}

// Bind Table Get Area Unit Data
export const getBindAreaUnitData = () => async dispatch => {
    const token = getTokenLocalStorage();
    const headers = { "x-auth-header": `${token}` }
    let formData = {
        "action": "View",
        "type": "Land Area Type"
    }
    axios.post(`${Url}bind`, formData, { headers }).then((response) => {
        // console.log(`getBindLandTypeData = ${JSON.stringify(response)}`)
        if (response?.data?.message == 'Success') {
            dispatch({
                payload: response,
                type: BIND_AREAUNIT_SUCCESS
            })
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: BIND_FAIL
        })
    })
}

// Bind Table Get Area Unit Data
export const getBindRadiusData = () => async dispatch => {
    const token = getTokenLocalStorage();
    const headers = { "x-auth-header": `${token}` }
    let formData = {
        "action": "View",
        "type": "Survey Radius Type",
        "order": "asc"
    }
    axios.post(`${Url}bind`, formData, { headers }).then((response) => {
        // console.log(`getBindLandTypeData = ${JSON.stringify(response)}`)
        if (response?.data?.message == 'Success') {
            dispatch({
                payload: response,
                type: BIND_RADIUS_SUCCESS
            })
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: BIND_FAIL
        })
    })
}

// Bind Table get Property Area Required
export const getPropertyAreaRequired = () => async dispatch => {
    const token = getTokenLocalStorage();
    const headers = { "x-auth-header": `${token}` }
    let formData = {
        "action": "View",
        "type": "Prepared Area Required"
    }
    axios.post(`${Url}bind`, formData, { headers }).then((response) => {
        // console.log(`getPropertyAreaRequired = ${JSON.stringify(response)}`)
        if (response?.data?.message == 'Success') {
            dispatch({
                payload: response,
                type: BIND_PROPERTY_AREA_REQUIRED_SUCCESS
            })
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: BIND_FAIL
        })
    })
}

// Bind Table Get Focus Type
export const getBindFocusTypeData = () => async dispatch => {
    const token = getTokenLocalStorage();
    const headers = { "x-auth-header": `${token}` }
    let formData = {
        "action": "View",
        "type": "Focus Type"
    }
    axios.post(`${Url}bind`, formData, { headers }).then((response) => {
        // console.log(`getBindFocusTypeData = ${JSON.stringify(response)}`)
        if (response?.data?.message == 'Success') {
            dispatch({
                payload: response,
                type: BIND_FOCUS_TYPE_SUCCESS
            })
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: BIND_FAIL
        })
    })
}

// Get Purpose Type
export const getBindPurposeTypeData = () => async dispatch => {
    const token = getTokenLocalStorage();
    const headers = { "x-auth-header": `${token}` }
    let formData = {
        "action": "View",
        "type": "Purpose Type"
    }
    axios.post(`${Url}bind`, formData, { headers }).then((response) => {
        //console.log(`getBindPurposeTypeData = ${JSON.stringify(response)}`)
        if (response?.data?.message == 'Success') {
            dispatch({
                payload: response,
                type: BIND_PURPOSE_TYPE_SUCCESS
            })
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: BIND_FAIL
        })
    })
}

// Get Payment Type
export const getBindPaymentTypeData = () => async dispatch => {
    const token = getTokenLocalStorage();
    const headers = { "x-auth-header": `${token}` }
    let formData = {
        "action": "View",
        "type": "Payment Type"
    }
    axios.post(`${Url}bind`, formData, { headers }).then((response) => {
        // console.log(`getBindPaymentTypeData = ${JSON.stringify(response)}`)
        if (response?.data?.message == 'Success') {
            dispatch({
                payload: response,
                type: BIND_PAYMENT_TYPE_SUCCESS
            })
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: BIND_FAIL
        })
    })
}

// Get Married Status 
export const getBindMarriedStatusData = () => async dispatch => {
    const token = getTokenLocalStorage();
    const headers = { "x-auth-header": `${token}` }
    let formData = {
        "action": "View",
        "type": "Married Status"
    }
    axios.post(`${Url}bind`, formData, { headers }).then((response) => {
        // console.log(`getBindMarriedStatusData = ${JSON.stringify(response)}`)
        if (response?.data?.message == 'Success') {
            dispatch({
                payload: response,
                type: BIND_MARRIED_STATUS_SUCCESS
            })
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: BIND_FAIL
        })
    })
}

// Get Job Sector Status 
export const getBindJobSectorData = () => async dispatch => {
    const token = getTokenLocalStorage();
    const headers = { "x-auth-header": `${token}` }
    let formData = {
        "action": "View",
        "type": "Job Selector"
    }
    axios.post(`${Url}bind`, formData, { headers }).then((response) => {
        // console.log(`getBindJobSectorData = ${JSON.stringify(response)}`)
        if (response?.data?.message == 'Success') {
            dispatch({
                payload: response,
                type: BIND_JOB_SECTOR_SUCCESS
            })
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: BIND_FAIL
        })
    })
}

// Get Current Status 
export const getBindCurrentStatusData = () => async dispatch => {
    const token = getTokenLocalStorage();
    const headers = { "x-auth-header": `${token}` }
    let formData = {
        "action": "View",
        "type": "Property Status"
    }
    axios.post(`${Url}bind`, formData, { headers }).then((response) => {
        // console.log(`getBindCurrentStatusData = ${JSON.stringify(response)}`)
        if (response?.data?.message == 'Success') {
            dispatch({
                payload: response,
                type: BIND_CURRENT_STATUS_SUCCESS
            })
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: BIND_FAIL
        })
    })
}

// Bind Table Number of Children Required
export const getNoOfChildren = () => async dispatch => {
    const token = getTokenLocalStorage();
    const headers = { "x-auth-header": `${token}` }
    let formData = {
        "action": "View",
        "type": "No Of Children"
    }
    axios.post(`${Url}bind`, formData, { headers }).then((response) => {
        // console.log(`getNoOfChildren = ${JSON.stringify(response)}`)
        if (response?.data?.message == 'Success') {
            dispatch({
                payload: response,
                type: BIND_CHILDREN_SUCCESS
            })
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: BIND_FAIL
        })
    })
}

// Bind Table EMI commitments
export const getEmiCommitments = () => async dispatch => {
    const token = getTokenLocalStorage();
    const headers = { "x-auth-header": `${token}` }
    let formData = {
        "action": "View",
        "type": "Add On Commitments"
    }
    axios.post(`${Url}bind`, formData, { headers }).then((response) => {
        // console.log(`getNoOfChildren = ${JSON.stringify(response)}`)
        if (response?.data?.message == 'Success') {
            dispatch({
                payload: response,
                type: BIND_EMI_COMMITMENTS_SUCCESS
            })
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: BIND_FAIL
        })
    })
}

// Bind Table Living Space
export const getLivingSpace = () => async dispatch => {
    const token = getTokenLocalStorage();
    const headers = { "x-auth-header": `${token}` }
    let formData = {
        "action": "View",
        "type": "Currently Living Space"
    }
    axios.post(`${Url}bind`, formData, { headers }).then((response) => {
        // console.log(`getNoOfChildren = ${JSON.stringify(response)}`)
        if (response?.data?.message == 'Success') {
            dispatch({
                payload: response,
                type: BIND_LIVING_SPACE_SUCCESS
            })
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: BIND_FAIL
        })
    })
}

// Bind Table near by services
export const getNearByServices = () => async dispatch => {
    const token = getTokenLocalStorage();
    const headers = { "x-auth-header": `${token}` }
    let formData = {
        "action": "View",
        "type": "Near By Service"
    }
    axios.post(`${Url}bind`, formData, { headers }).then((response) => {
        // console.log(`getNoOfChildren = ${JSON.stringify(response)}`)
        if (response?.data?.message == 'Success') {
            dispatch({
                payload: response,
                type: BIND_NEAR_BY_SERVICES_SUCCESS
            })
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: BIND_FAIL
        })
    })
}
