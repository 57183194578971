import React from 'react';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router, HashRouter, Routes, Switch, useLocation, withRouter } from 'react-router-dom';
import './css/Admin.css';
import './css/panelboard.css';
import './css/plugins.css';
import './css/skin/skin-1.css';
import './css/style.css';
import './css/templete.css';
import './css/uploadimage.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import AdminMaincontent from './markup/Element/Admin/AdminMaincontent';
import AddOwner from './markup/Element/Admin/LandOwners/AddOwner';
import LandOwners from './markup/Element/Admin/LandOwners/LandOwners';
import Payments from './markup/Element/Admin/Payments/Payments';
import CreatePlot2 from './markup/Element/Admin/Property/CreatePlot2';
import CreateProperty from './markup/Element/Admin/Property/CreateProperty';
import EditHouse from './markup/Element/Admin/Property/EditHouse';
import EditLand from './markup/Element/Admin/Property/EditLand';
import EditPlot from './markup/Element/Admin/Property/EditPlot';
import Settings from './markup/Element/Admin/Settings/Settings';
import AddStaff from './markup/Element/Admin/Staff/AddStaff';
import Staff from './markup/Element/Admin/Staff/Staff';
import StaffDetailPage from './markup/Element/Admin/Staff/StaffDetailPage';
import AboutUs from './markup/Pages/AboutUs';
import Contact from './markup/Pages/Contact';
import Buyers from './markup/Pages/Buyers';
import HomePage from './markup/Pages/HomePage';
import Loginpage2 from './markup/Pages/Loginpage';
import SignUp from "./markup/Pages/SignUp";
import BuildingApproval from './markup/Element/Admin/BuildingApproval/BuildingApproval';
import AddBuildingApproval from './markup/Element/Admin/BuildingApproval/AddBuildingApproval.js';
import VillageFinder from './markup/Element/Admin/VillageFinder/VillageFinder';
import LandSurvey from './markup/Element/Admin/LandSurvey/LandSurvey';
import LegalOpinion from './markup/Element/Admin/LegalOpinion/LegalOpinion';
import NeedProperty from './markup/Element/Admin/NeedProperty/NeedProperty';
import AgentHome from './markup/Element/Admin/Agent/AgentHome.js';
import ConsultantHome from './markup/Element/Admin/Consultant/ConsultantHome.js';
import WriterHome from './markup/Element/Admin/Writer/WriterHome.js';
import AddNeedProperty from './markup/Element/Admin/NeedProperty/AddNeedProperty.js';
import AddLegalOpinion from './markup/Element/Admin/LegalOpinion/AddLegalOpinion.js'
import AddLandSurvey2 from './markup/Element/Admin/LandSurvey/AddLandSurvey2.js';
import AddAgent from './markup/Element/Admin/Agent/AddAgent.js';
import AddConsultant from './markup/Element/Admin/Consultant/AddConsultant.js';
import AddWriter from './markup/Element/Admin/Writer/AddWriter.js';
import PickLocation from './markup/Element/Admin/Others/PickLocation.js';
import Fmb_Patta from './markup/Element/Admin/Fmb_Patta/Fmb_Patta.js';
import CroEntryProperty from './markup/Element/Admin/Property/CroEntryProperty.js';
import PlotFinderHome from './markup/Element/Admin/PlotFinder/PlotFinderHome.js';
import BookingsHome from './markup/Element/Admin/Bookings/BookingsHome.js';
import VastuPlan from './markup/Element/Admin/VastuPlan/VastuPlan.js';
import AddVastuPlan from './markup/Element/Admin/VastuPlan/AddVastuPlan.js';

import Dashboard from './markup/Element/Admin/Dashboard/Dashboard';
import Property from './markup/Element/Admin/Property/Property';
import store from './store/store';
import AddLandSurvey1 from './markup/Element/Admin/LandSurvey/AddLandSurvey1.js';

import { createBrowserHistory } from 'history';
import Privacy from './markup/Element/Privacy';
import AgentCondition from './markup/Element/Admin/Agent/AgentCondition.js';
import Terms from './markup/Element/Terms';
import Design1 from './markup/Layout/Design1';
import Design10 from './markup/Layout/Design10';
import Design2 from './markup/Layout/Design2';
import Design3 from './markup/Layout/Design3';
import Design4 from './markup/Layout/Design4';
import Design5 from './markup/Layout/Design5';
import Design6 from './markup/Layout/Design6';
import Design7 from './markup/Layout/Design7';
import Design8 from './markup/Layout/Design8';
import Design9 from './markup/Layout/Design9';


const history = createBrowserHistory();

const Main = withRouter(({ location }) => {

    return (<Provider store={store}>
        <div className="page-wraper">
            <Switch>
                <Route exact path='/' component={HomePage} />
                <Route exact path='/home' component={HomePage} />
                <Route exact path='/about-us' component={AboutUs} />
                <Route exact path='/contact' component={Contact} />
                <Route exact path='/CreatePlot2' component={CreatePlot2} />
                <Route exact path='/Buyers' component={Buyers} />
                <Route exact path='/Login-page' component={Loginpage2} />
                <Route exact path='/Privacy-Policy' component={Privacy} />
                <Route exact path='/Agent-Condition' component={AgentCondition} />
                <Route exact path='/Terms-And-Condition' component={Terms} />
                <Route exact path='/SignUp' component={SignUp} />
                <Route exact path='/Design1' component={Design1} />
                <Route exact path='/Design2' component={Design2} />
                <Route exact path='/Design3' component={Design3} />
                <Route exact path='/Design4' component={Design4} />
                <Route exact path='/Design5' component={Design5} />
                <Route exact path='/Design6' component={Design6} />
                <Route exact path='/Design7' component={Design7} />
                <Route exact path='/Design8' component={Design8} />
                <Route exact path='/Design9' component={Design9} />
                <Route exact path='/Design10' component={Design10} />

                <AdminMaincontent>
                    <Route exact path='/Dashboard' component={Dashboard} />
                    <Route exact path='/Property' component={Property} />
                    <Route exact path='/CreateProperty' component={CreateProperty} />
                    <Route exact path='/AddStaff' component={AddStaff} />
                    <Route exact path='/AddOwner' component={AddOwner} />
                    <Route exact path='/EditHouse' component={EditHouse} />
                    <Route exact path='/EditLand' component={EditLand} />
                    <Route exact path='/EditPlot' component={EditPlot} />
                    <Route exact path='/StaffDetailPage' component={StaffDetailPage} />
                    <Route exact path='/Staff' component={Staff} />
                    <Route exact path='/LandOwners' component={LandOwners} />
                    <Route exact path='/Settings' component={Settings} />
                    <Route exact path='/Payments' component={Payments} />
                    <Route exact path='/BuildingApproval' component={BuildingApproval} />
                    <Route exact path='/AddBuildingApproval' component={AddBuildingApproval} />
                    <Route exact path='/VillageFinder' component={VillageFinder} />
                    <Route exact path='/LandSurvey' component={LandSurvey} />
                    <Route exact path='/AddLandSurvey1' component={AddLandSurvey1} />
                    <Route exact path='/AddLandSurvey2' component={AddLandSurvey2} />
                    <Route exact path='/LegalOpinion' component={LegalOpinion} />
                    <Route exact path='/AddLegalOpinion' component={AddLegalOpinion} />
                    <Route exact path='/NeedProperty' component={NeedProperty} />
                    <Route exact path='/AddNeedProperty' component={AddNeedProperty} />
                    <Route exact path='/AgentHome' component={AgentHome} />
                    <Route exact path='/AddAgent' component={AddAgent} />
                    <Route exact path='/ConsultantHome' component={ConsultantHome} />
                    <Route exact path='/AddConsultant' component={AddConsultant} />
                    <Route exact path='/WriterHome' component={WriterHome} />
                    <Route exact path='/AddWriter' component={AddWriter} />
                    <Route exact path='/PickLocation' component={PickLocation} />
                    <Route exact path='/Fmb_Patta' component={Fmb_Patta} />
                    <Route exact path='/CroEntryProperty' component={CroEntryProperty} />
                    <Route exact path='/PlotFinderHome' component={PlotFinderHome} />
                    <Route exact path='/BookingsHome' component={BookingsHome} />
                    <Route exact path='/AddVastuPlan' component={AddVastuPlan} />
                    <Route exact path='/VastuPlan' component={VastuPlan} />
                </AdminMaincontent>

            </Switch>
        </div>
    </Provider>)
})

function App() {
    return (
        <HashRouter history={history}>
            <Main></Main>
        </HashRouter>
    )
}
export default App;



