import React, { useEffect, useState } from 'react';
import Footer from './../Layout/Footer';
import Header from './../Layout/Header';
import { useDispatch, useSelector } from "react-redux";
import swal from 'sweetalert';

var phone = require('./../../images/contacts/phone.png');
var email = require('./../../images/contacts/email.png');

function Contact() {


	const dispatch = useDispatch();

	const [errors, setErrors] = useState({});
	const [bodyData, setBodyData] = useState({
		"name": "",
		"mailId": "",
		"mobileNo": "",
		"comments": "",
		"remarks": '',

	})

	const { name, mailId, mobileNo, comments, remarks } = bodyData;

	const validate = (e) => {
		e.preventDefault();
		let isValid = true;

		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

		if (!bodyData.name || bodyData.name.length < 3) {
			handleError('Name must be min 3 characters long', 'name');
			isValid = false;
		}

		if (!bodyData.mailId || bodyData.mailId.length < 3) {
			handleError('Mail must be min 3 characters long', 'mailId');
			isValid = false;
		}


		if (!emailRegex.test(bodyData.mailId)) {
			handleError('Please enter a valid email address', 'mailId');
			isValid = false;
		}

		if (
			!bodyData.mobileNo ||
			isNaN(bodyData.mobileNo) ||
			(bodyData.mobileNo.toString().length !== 10 ||
				bodyData.mobileNo.toString().includes('.'))
		) {
			handleError('Enter valid number', 'mobileNo');
			isValid = false;
		}

		if (!bodyData.subject || bodyData.subject.length < 3) {
			handleError('Subject is needed', 'subject');
			isValid = false;
		}

		if (!bodyData.comments || bodyData.comments.length < 3) {
			handleError('Comments is needed', 'comments');
			isValid = false;
		}

		if (isValid) {
			mailHandler();
		}
	}

	const onChange = e => {
		setBodyData({ ...bodyData, [e.target.name]: e.target.value });
	}

	const handleError = (error, input) => {
		setErrors(prevState => ({ ...prevState, [input]: error }));
	};

	const mailHandler = async () => {
		swal("Great!", "You have sent a message, we will contact you soon!", "success");
		setBodyData({
			"name": "",
			"mailId": "",
			"mobileNo": "",
			"comments": "",
			"remarks": '',
		})

	}


	return (
		<>
			<Header />
			<div className="pageContent bg-white">
				<div className="container">
					<div className='text-contact-con'>
						<h2>Get In Touch</h2>
						<hr style={{
							color: 'blue',
							backgroundColor: 'blue',
							height: 5, width: 120,
						}} />
						<p>
							We'd love to hear from you! Whether you have questions about a property, need assistance with listing, or just want to provide feedback, our team is here to help. Reach out to us via email, phone, or our contact form, and we’ll get back to you as soon as possible. Let's connect and make your real estate experience seamless and enjoyable with My Property QR
						</p>
					</div>

					<div className="row">
						<div className="col-lg-8">
							<div className="form-input-con">
								<form onSubmit={validate}>
									<div className="form-row">
										<div className="form-group col-md-6">
											{errors.name ?
												<label htmlFor="name" className="error-text">{errors.name}</label> :
												<label htmlFor="name" className="text-black">Full Name<span className="required">*</span> </label>
											}
											<input
												type="text"
												className="form-control form-input-holder"
												id="name" name='name'
												value={name}
												onChange={e => onChange(e)}
												placeholder="Your full name"
												noValidate
												onFocus={() => handleError(null, 'name')}
											/>
										</div>
										<div className="form-group col-md-6">
											{errors.mobileNo ?
												<label htmlFor="mobileNo" className="error-text">{errors.mobileNo}</label> :
												<label htmlFor="mobileNo" className="text-black">Mobile Number<span className="required">*</span> </label>
											}
											<input
												type="text"
												className="form-control form-input-holder"
												id="mobileNo" name='mobileNo'
												value={mobileNo}
												maxLength={10}
												onChange={e => onChange(e)}
												placeholder="Enter Mobile Number"
												noValidate
												onFocus={() => handleError(null, 'mobileNo')}
											/>
										</div>
									</div>
									<div className="form-row">
										<div className="form-group col-md-6">
											{errors.subject ?
												<label htmlFor="subject" className="error-text">{errors.subject}</label> :
												<label htmlFor="subject" className="text-black">Subject<span className="required">*</span> </label>
											}
											<input
												type="text"
												className="form-control form-input-holder"
												placeholder="Type your Subject"
												id="subject" name="subject"
												value={bodyData.subject}
												onChange={e => onChange(e)}
												onFocus={() => handleError(null, 'subject')}
											/>
										</div>
										<div className="form-group col-md-6">
											{errors.mailId ?
												<label htmlFor="mailId" className="error-text">{errors.mailId}</label> :
												<label htmlFor="mailId" className="text-black">Mail Id<span className="required">*</span> </label>
											}
											<input
												type="text"
												className="form-control form-input-holder"
												placeholder="Type your email address"
												id="mailId" name="mailId"
												value={bodyData.mailId}
												onChange={e => onChange(e)}
												onFocus={() => handleError(null, 'mailId')}
											/>
										</div>
									</div>
									<div className="form-group">
										{errors.comments ?
											<label htmlFor="comments" className="error-text">{errors.comments}</label> :
											<label htmlFor="comments" className="text-black">Comments<span className="required">*</span> </label>
										}
										<textarea
											type="text"
											className="form-control form-input-holder"
											placeholder="Type your comments"
											id="comments" name="comments"
											value={bodyData.comments}
											onChange={e => onChange(e)}
											onFocus={() => handleError(null, 'comments')}
											rows="7"
										/>
									</div>
									<div className="form-group">
										<div className="form-check">
											<input className="form-check-input" type="checkbox" id="gridCheck" />
											<label className="form-check-label" for="gridCheck">
												I read terms and condition
											</label>
										</div>
									</div>
									<button type="submit" className="btn btn-primary w-5">Submit Request</button>
								</form>
							</div>
						</div>
						<div className="col-lg-4 text-center m-t60 ">
							<div className='connect-over-con'>
								<div className="row connect-row-details">
									<div className="col">
										<img src={phone} alt="" width={35} className='mb-4' />
										<h5>Call or Phone</h5>
										<p>+91 8925798627<br />
											+91 6382592189</p>
									</div>
								</div>
								<div className="row connect-row-details">
									<div className="col">
										<img src={email} alt="" width={35} className='mb-4' />
										<h5>Email Address</h5>
										<p>mypropertyqr@gmail.com<br />
											mypropertyragu@gmail.com</p>
									</div>
								</div>
								<div className="row connect-row-detail1">
									<div className="col">
										<img src={phone} alt="" width={35} className='mb-3' />
										<h5>Address</h5>
										<p>My Property QR,<br />
											No. 2/84, Morasappatti Post,<br />
											Veppamarathupatti,<br />
											Edappadi, Salem<br />
											Tamil Nadu - 637101 <br />
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div >
			</div >

			<Footer />
		</>
	)
}

export default Contact;



