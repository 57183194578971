import { VILLAGE_FINDER_SUCCESS, VILLAGE_FINDER_FAIL, VILLAGE_FINDER_VIEW } from "../type/types";

const initialState = {
    loading: true,
    payload: [],
    error: {}
}

export const VillageFinderReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case VILLAGE_FINDER_VIEW:
            return {
                ...state,
                loading: false,
                payload: payload
            }
        case VILLAGE_FINDER_FAIL:
            return {
                ...state,
                loading: false,
                error: payload
            }
        default:
            return state;

    }

}