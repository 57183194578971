import axios from 'axios';
import swal from "sweetalert";
import { Url, getTokenLocalStorage, getTokenLocalUserStorage } from '../../utils/Common';
import { NEED_PROPERTY_SUCCESS, NEED_PROPERTY_FAIL, NEED_PROPERTY_VIEW, NEED_PROPERTY_INSERT, NEED_PROPERTY_DELETE, NEED_PROPERTY_UPDATE, NEED_PROPERTY_MORE_INPUTS_FAIL, NEED_PROPERTY_MORE_INPUTS_INSERT, NEED_PROPERTY_MORE_INPUTS_SUCCESS, NEED_PROPERTY_MORE_INPUTS_UPDATE, NEED_PROPERTY_MORE_INPUTS_VIEW } from "../type/types";

// Need Property Action
export const getNeedProperty = () => async dispatch => {
    const token = getTokenLocalStorage();
    const formData = {
        "action": "View",
        "userRfId": "",
        "zoneId": "",
        "subZoneId": "",
        "layoutType": "",
        "propertyType": "",
        "expectedPriceId": "",
        "search": "",
        "order": "desc"
    };
    const headers = { "x-auth-header": `${token}` }
    axios.post(`${Url}property-need`, formData, { headers }).then((response) => {
        //console.log(`getNeedProperty response = ${JSON.stringify(response?.data?.data)}`)
        if (response?.data?.message == 'Success') {
            dispatch({
                payload: response,
                type: NEED_PROPERTY_VIEW
            })
        };
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: NEED_PROPERTY_FAIL
        })
    })
}

export const addNeedProperty = (formData, history, setSending) => async dispatch => {

    //console.log(`addLegalOpinion formData = ${JSON.stringify(formData)}`)
    setSending(true);
    const token = getTokenLocalStorage();
    const headers = { "x-auth-header": `${token}` }

    axios.post(`${Url}property-need`, formData, { headers }).then((response) => {
        //console.log(`addLegalOpinion response = ${JSON.stringify(response?.data)}`)
        if (response?.data?.message === "Success") {
            swal("success", `Thanks for registering your desired Property need to us. Our Right Property Searching Team will contact you after choosing Right and Suitable Properties.`, "success");
            history.goBack();
            dispatch({
                payload: response,
                type: NEED_PROPERTY_INSERT
            })
            setSending(false);
        } else {
            history.goBack();
            swal("fail", `I need Property is not added, Please try again`, "fail");
            dispatch({
                payload: response,
                type: NEED_PROPERTY_FAIL
            })
            setSending(false);
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: NEED_PROPERTY_FAIL
        })
        setSending(false);
    })
}

// Get More Inputs Data
export const getNeedPropertyMoreInputsData = (rfId = 0) => async dispatch => {
    const token = getTokenLocalStorage();
    const formData = {
        "rfId": "",
        "action": "View",
        "propertNeedRfId": rfId == 0 ? "" : rfId,
        "userRfId": "",
        "order": "desc",
        "limit": ""
    }
    const headers = { "x-auth-header": `${token}` }
    axios.post(`${Url}need-property-more-input`, formData, { headers }).then((response) => {
        //console.log(`getNeedPropertyMoreInputsData response = ${JSON.stringify(response?.data?.data)}`)
        if (response?.data?.message == 'Success') {
            dispatch({
                payload: response,
                type: NEED_PROPERTY_MORE_INPUTS_VIEW
            })
        };
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: NEED_PROPERTY_FAIL
        })
    })
}

// Add Need Property More Inputs
export const addNeedPropertyMoreInputs = (formData, history) => async dispatch => {

    // console.log(`addNeedPropertyMoreInputs formData = ${JSON.stringify(formData)}`)

    const token = getTokenLocalStorage();
    const headers = { "x-auth-header": `${token}` }

    axios.post(`${Url}need-property-more-input`, formData, { headers }).then((response) => {
        //console.log(`addLegalOpinion response = ${JSON.stringify(response?.data)}`)
        if (response?.data?.message === "Success") {
            swal("success", `More Inputs are added for this Property`, "success");
            // history.goBack();
            dispatch({
                payload: response,
                type: NEED_PROPERTY_MORE_INPUTS_INSERT
            })
        } else {
            // history.goBack();
            swal("fail", `More Inputs are not added, Please try again`, "fail");
            dispatch({
                payload: response,
                type: NEED_PROPERTY_MORE_INPUTS_FAIL
            })
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: NEED_PROPERTY_MORE_INPUTS_FAIL
        })
    })
}

// I need Property  Show / Hide Status update

export const showHideINeedPropertyStatus = (rfId, userRfId, visibleStatus) => async dispatch => {

    const loginUserData = await getTokenLocalUserStorage();
    const loginUserId = loginUserData[0].rfId;

    let formData = {
        "action": "Show",
        "rfId": rfId,
        "visibleStatus": visibleStatus == 1 ? 0 : 1,
        "userRfId": userRfId,
        "loginUserId": loginUserId
    }

    await axios.post(`${Url}property-need`, formData).then((response) => {
        //  console.log(`site data response - ${JSON.stringify(response)}`)
        if (response?.data?.message == "Success") {
            swal("Status Changed!", "The selected Property status is changed successfully!", "success");
        } else {
            swal("Fail", `The selected Property is status is not updated!, Please try again`, "fail");
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: NEED_PROPERTY_FAIL
        })
    })
}