import React from 'react';
import ConsultantListTable from './ConsultantListTable'

const ConsultantHome = () => {
    return (
        <>
            <p className='listings-heading'>Consultant</p>
            <ConsultantListTable />
        </>
    )
}

export default ConsultantHome