import React, { useState, useCallback, useRef, useEffect } from 'react';
import { GoogleMapsProvider, useAutocomplete, useGoogleMap } from '@ubilabs/google-maps-react-hooks';
import { GOOGLE_API_KEY } from "../../../../utils/Common";
import { getVillageFinder } from '../../../../store/action/VillageFinderAction';
import { useDispatch, useSelector } from "react-redux";
import PickLocation from '../Others/PickLocation';

export default function VillageFinder() {

    return (
        <>
            <PickLocation />
        </>
    );
}
