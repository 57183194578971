import React, { useState, useEffect } from "react";
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import '../../css/design7.css'
import { getLayoutRegistrationData } from "../../store/action/LayoutAction";
import { commonFilter } from "../../utils/Common";


export default function Design7({ data }) {
    const dispatch = useDispatch();
    const selector = useSelector(state => state?.LayoutReducer);
    const [show, setShow] = useState(false);
    const [modelData, setModelData] = useState({});
    const [layoutData, setLayoutData] = useState([]);
    const handleClose = () => setShow(false);
    const handleShow = (modelLayoutData) => {
        setModelData(modelLayoutData)
        setShow(true);
    }
    useEffect(() => {
        dispatch(getLayoutRegistrationData("SRCLAND000040"));
    }, []);
    useEffect(() => {
        if (selector?.payload?.data?.message === "Success") {
            setLayoutData(selector?.payload?.data?.data)
        }
    }, [selector?.payload?.data])

    const design7 = {
        layout1: [
            {
                itemNo: 'item1',
                box_number: '1',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 1) : 0,
            },
            {
                itemNo: 'item2',
                box_number: '2',
                boxSize_t: '',
                boxSize_r: '14.3',
                boxSize_b: '9.1',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 2) : 0,
            },
            {
                itemNo: 'item3',
                box_number: '3',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 3) : 0,
            },
            {
                itemNo: 'item4',
                box_number: '4',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '9.1',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 4) : 0,
            },
            {
                itemNo: 'item5',
                box_number: '5',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 5) : 0,
            },
            {
                itemNo: 'item6',
                box_number: '6',
                boxSize_t: '',
                boxSize_r: '12.5',
                boxSize_b: '1.5+6.6',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 6) : 0,
            },

        ],
        layout2: [
            {
                itemNo: 'item7',
                box_number: '7',
                boxSize_t: '1.5+12.6',
                boxSize_r: '15.2',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 7) : 0,
            },
            {
                itemNo: 'item8',
                box_number: '8',
                boxSize_t: '9.1',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 8) : 0,
            },
            {
                itemNo: 'item9',
                box_number: '9',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 9) : 0,
            },
            {
                itemNo: 'item10',
                box_number: '10',
                boxSize_t: '9.1',
                boxSize_r: '15.2',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 10) : 0,
            },
            {
                itemNo: 'item11',
                box_number: '11',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 11) : 0,
            },
            {
                itemNo: 'item12',
                box_number: '12',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 12) : 0,
            },
            {
                itemNo: 'item13',
                box_number: '13',
                boxSize_t: '',
                boxSize_r: '12.2',
                boxSize_b: '9.1',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 13) : 0,
            },
            {
                itemNo: 'item14',
                box_number: '14',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 14) : 0,
            },
            {
                itemNo: 'item15',
                box_number: '15',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '9.1',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 15) : 0,
            },
            {
                itemNo: 'item16',
                box_number: '16',
                boxSize_t: '',
                boxSize_r: '12.2',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 16) : 0,
            },
        ],
        layout3: [
            {
                itemNo: 'item17',
                box_number: '17',
                boxSize_t: '',
                boxSize_r: '16.8',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 17) : 0,
            },
            {
                itemNo: 'item18',
                box_number: '18',
                boxSize_t: '7.0',
                boxSize_r: '15.2',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 18) : 0,
            },
            {
                itemNo: 'item19',
                box_number: '19',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 19) : 0,
            },
            {
                itemNo: 'item20',
                box_number: '20',
                boxSize_t: '9.1',
                boxSize_r: '15.2',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 20) : 0,
            },
            {
                itemNo: 'item21',
                box_number: '21',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 21) : 0,
            },
            {
                itemNo: 'item22',
                box_number: '22',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 22) : 0,
            },
            {
                itemNo: 'item23',
                box_number: '23',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '9.1',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 23) : 0,
            },
            {
                itemNo: 'item24',
                box_number: '24',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 24) : 0,
            },
            {
                itemNo: 'item25',
                box_number: '25',
                boxSize_t: '12.2',
                boxSize_r: '',
                boxSize_b: '7.0',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 25) : 0,
            },
            {
                itemNo: 'item25A',
                box_number: '',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 25) : 0,
            },
            {
                itemNo: 'item25B',
                box_number: '',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 25) : 0,
            },
        ],
        layout4: [
            {
                itemNo: 'item26',
                box_number: '26',
                boxSize_t: '1.5+8.8',
                boxSize_r: '12.9',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 26) : 0,
            },
            {
                itemNo: 'item27',
                box_number: '27',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 27) : 0,
            },
            {
                itemNo: 'item28',
                box_number: '28',
                boxSize_t: '8.7+1.5',
                boxSize_r: '1.5+10.7',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 28) : 0,
            },
        ],
        layout5: [
            {
                itemNo: 'item29',
                box_number: '29',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '11.7',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 29) : 0,
            },
            {
                itemNo: 'item30',
                box_number: '30',
                boxSize_t: '',
                boxSize_r: '12.2',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 30) : 0,
            },
            {
                itemNo: 'item31',
                box_number: '31',
                boxSize_t: '',
                boxSize_r: '',
                boxSize_b: '',
                boxSize_l: '',
                status: layoutData.length > 0 ? commonFilter(layoutData, 31) : 0,
            },
        ]
    }

    return (
        <>
            {
                data === "true" && <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="true"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Land Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Site Identification No: {modelData?.box_number} <br />
                        Site Size Details:{modelData?.boxSize_t} {modelData?.boxSize_r} {modelData?.boxSize_b} {modelData?.boxSize_l}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            }

            <div className="container">
                <div className="">
                    <div className='design-7'>
                        <div className='main_layout'>
                            <div className='layout-1'>
                                {
                                    design7.layout1.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemNo} ${data.status == 1 ? 'background-color-success' : 'background-color-fail'} design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxSize_t ? "boxSize_t" : ""}> {data.boxSize_t ? data.boxSize_t : ""}</div>
                                                <div className={data.boxSize_b ? "boxSize_b" : ""}> {data.boxSize_b ? data.boxSize_b : ""}</div>
                                                <div className={data.boxSize_l ? "boxSize_l" : ""}> {data.boxSize_l ? data.boxSize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                            </div>
                            <div className='layout-2'>
                                {
                                    design7.layout2.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemNo} ${data.status == 1 ? 'background-color-success' : 'background-color-fail'} design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxSize_t ? "boxSize_t" : ""}> {data.boxSize_t ? data.boxSize_t : ""}</div>
                                                <div className={data.boxSize_b ? "boxSize_b" : ""}> {data.boxSize_b ? data.boxSize_b : ""}</div>
                                                <div className={data.boxSize_l ? "boxSize_l" : ""}> {data.boxSize_l ? data.boxSize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                            </div>
                            <div className='layout-3'>
                                {
                                    design7.layout3.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemNo} ${data.status == 1 ? 'background-color-success' : 'background-color-fail'} design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxSize_t ? "boxSize_t" : ""}> {data.boxSize_t ? data.boxSize_t : ""}</div>
                                                <div className={data.boxSize_b ? "boxSize_b" : ""}> {data.boxSize_b ? data.boxSize_b : ""}</div>
                                                <div className={data.boxSize_l ? "boxSize_l" : ""}> {data.boxSize_l ? data.boxSize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                            </div>
                            <div className='layout-4'>
                                {
                                    design7.layout4.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemNo} ${data.status == 1 ? 'background-color-success' : 'background-color-fail'} design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxSize_t ? "boxSize_t" : ""}> {data.boxSize_t ? data.boxSize_t : ""}</div>
                                                <div className={data.boxSize_b ? "boxSize_b" : ""}> {data.boxSize_b ? data.boxSize_b : ""}</div>
                                                <div className={data.boxSize_l ? "boxSize_l" : ""}> {data.boxSize_l ? data.boxSize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                            </div>
                            <div className='layout-5'>
                                {
                                    design7.layout5.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemNo} ${data.status == 1 ? 'background-color-success' : 'background-color-fail'} design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxSize_t ? "boxSize_t" : ""}> {data.boxSize_t ? data.boxSize_t : ""}</div>
                                                <div className={data.boxSize_b ? "boxSize_b" : ""}> {data.boxSize_b ? data.boxSize_b : ""}</div>
                                                <div className={data.boxSize_l ? "boxSize_l" : ""}> {data.boxSize_l ? data.boxSize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                            </div>

                            <div className="roadcross1"></div>
                            <div className="roadcross2"></div>
                            <div className="roadcross3"></div>
                            <div className="roadcross4"></div>
                            <div className="roadcross5"></div>
                            <div className="roadcross6"></div>
                            <div className="roadcross7"></div>


                            <div className="road-1">9.0M Layout Road </div>
                            <div className="road-2">10.0M Layout Road </div>
                            <div className="road-3">9.0M Layout Road </div>
                            <div className="road-4">9.0M Layout Road </div>



                            <div className="whitebox1"></div>
                            <div className="whitebox2"></div>
                            <div className="whitebox3"></div>
                            <div className="whitebox4"></div>
                            <div className="whitebox5"></div>
                            <div className="whitebox6"></div>


                            <div className="sf1">54.8M</div>
                            <div className="sf2">12.8M</div>
                            <div className="sf3">71.4M</div>
                            <div className="sf4">60.6M</div>
                            <div className="sf5">22.4M</div>
                            <div className="sf6">34.8M</div>
                            <div className="sf7">21.2M</div>
                            <div className="sf8">22.0M</div>
                            <div className="sf9">71.6M</div>
                            <div className="sf10">15.8M</div>


                            <div className="sm1">Sathy - Bahavanisagar Main Road</div>
                            <div className="sm2">15.0 M Panchayat Road</div>




                            <div className="rs1">R.S NO:282/1 A 1</div>
                            <div className="rs2">R.S NO:282/1 B</div>
                            <div className="rs3">R.S NO:282/2 B </div>
                            <div className="rs4">R.S NO:282/3 C</div>
                            <div className="rs5">R.S NO:282/3 A 2</div>
                            <div className="rs6">R.S NO:282/3 B</div>
                            <div className="rs7">R.S NO:283</div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}