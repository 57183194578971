import React, { useEffect, useState, useRef } from 'react';
import { GoogleMap, useJsApiLoader, Marker, Autocomplete } from '@react-google-maps/api';
import { GOOGLE_API_KEY } from '../../../../utils/Common';
import { getVillageFinder } from '../../../../store/action/VillageFinderAction';
import { useDispatch, useSelector } from "react-redux";
import Loader1 from '../../../../utils/Loader1'

const center = {
    lat: 11.342423,
    lng: 77.728165
};

function PickLocation({ onChangeLocation, districtData }) {

    const dispatch = useDispatch();
    const selector = useSelector(state => state?.VillageFinderReducer);


    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: GOOGLE_API_KEY,
        libraries: ['places']
    });

    const [map, setMap] = useState(null);
    const [markerPosition, setMarkerPosition] = useState(center);
    const [autocomplete, setAutocomplete] = useState(null);
    const [address, setAddress] = useState('');
    const [VillageFinderData, setVillageFinderData] = useState({});
    const [loading, setLoading] = useState(false);

    const mapRef = useRef(null);
    const geocoder = useRef(null);

    useEffect(() => {
        if (selector?.payload?.data?.message === "Success") {
            // console.log(`AutoComplete Data = ${JSON.stringify(selector?.payload?.data?.data?.data)}`)
            setVillageFinderData(selector?.payload?.data?.data?.data);
        }
    }, [selector?.payload?.data])

    const onLoad = React.useCallback(function callback(map) {
        setMap(map);
        mapRef.current = map;
        geocoder.current = new window.google.maps.Geocoder();

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const currentPosition = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };
                    setMarkerPosition(currentPosition);
                    mapRef.current.panTo(currentPosition);
                    geocodeLatLng(currentPosition);
                },
                (error) => {
                    console.error("Error getting current position:", error);
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
    }, []);


    const onUnmount = React.useCallback(function callback(map) {
        setMap(null);
    }, []);

    const onMapClick = React.useCallback((e) => {
        const position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
        setMarkerPosition(position);
        geocodeLatLng(position);
    }, []);

    const onLoadAutocomplete = (autocompleteInstance) => {
        setAutocomplete(autocompleteInstance);
    };

    const onPlaceChanged = async () => {
        if (autocomplete !== null) {
            const place = autocomplete.getPlace();
            if (place.geometry) {
                setLoading(true)
                const location = {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng()
                };
                setMarkerPosition(location);
                mapRef.current.panTo(location);
                await dispatch(getVillageFinder(location.lat, location.lng))
                await setAddress(place.formatted_address);
                setLoading(false)
            }
        } else {
            console.log('Autocomplete is not loaded yet!');
        }
    };

    const geocodeLatLng = (position) => {
        setVillageFinderData({});
        setAddress("");
        geocoder.current.geocode({ location: position }, async (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    // console.log(`results[0] = ${JSON.stringify(results[0])}`)
                    setLoading(true)
                    await dispatch(getVillageFinder(position.lat, position.lng))
                    await setAddress(results[0].formatted_address);
                    await commonFunction(results[0], position.lat, position.lng);
                    setLoading(false)
                } else {
                    console.log('No results found');
                    setLoading(false)
                }
            } else {
                console.log('Geocoder failed due to: ' + status);
            }
        });
    };

    const commonFunction = async (results, lat, lng) => {

        let addressData = {
            address: "",
            distict: "",
            state: "",
            country: "",
            pincode: "",
            districtId: "",
            latitude: "",
            longitude: "",
            location: ""
        };

        await results?.address_components?.map((details, index) => {
            if (details?.types[0] === "postal_code") {
                addressData.pincode = details?.long_name;
            } else if (details?.types[0] === "country") {
                addressData.country = details?.long_name;
            } else if (details?.types[0] === "administrative_area_level_1") {
                addressData.state = details?.long_name;
            } else if (details?.types[0] === "administrative_area_level_3" || details?.types[0] === "locality") {
                addressData.distict = details?.long_name;
            } else if (details?.types[0] == "political") {
                addressData.location = details?.long_name;
            } else {
                addressData.address = addressData.address.concat(details?.long_name + " , ")
            }
        });

        if (districtData && districtData.length > 0) {
            let districtRfId = districtData.filter((filterData) => {
                if (filterData.districtName === addressData.distict?.toUpperCase()) {
                    return filterData.rfId;
                }
            });
            if (districtRfId?.length > 0) {
                addressData.districtId = districtRfId[0]?.rfId;
            }
        }
        if (onChangeLocation) {
            await onChangeLocation(lat, lng, addressData.address, addressData.pincode, addressData.districtId, addressData.location);
        }
        //console.log(`addressData = ${JSON.stringify(addressData)}`)
    };

    return isLoaded ? (
        <div className='model-content-container VillageFinder'>
            <GoogleMap
                mapContainerStyle={{ width: '96%', height: '95%' }}
                center={markerPosition}
                zoom={9.2}
                onLoad={onLoad}
                onUnmount={onUnmount}
                onClick={onMapClick}
                options={{
                    streetViewControl: false,
                    fullscreenControl: false,
                }}
            >
                <Autocomplete
                    onLoad={onLoadAutocomplete}
                    onPlaceChanged={onPlaceChanged}
                >
                    <input
                        type="text"
                        placeholder="Enter a location"
                        className="autocomplete-input"
                    />
                </Autocomplete>
                <Marker position={markerPosition} />
            </GoogleMap>
            <div className='SelectedAddress_Con'>
                {loading ?
                    <div className='p-4'>
                        <Loader1 />
                    </div>
                    :
                    <div className='SelectedAddress_Inner_Con'>
                        <p className='AddressTxt1'>{address}</p>
                        {VillageFinderData?.municipality_name != null &&
                            <p className='AddressTxt1'>Municipality Name = <span>{VillageFinderData?.municipality_name}
                            </span>
                            </p>
                        }
                        {VillageFinderData?.panchayat_village_name != null &&
                            <p className='AddressTxt1'>Panchayat Name = <span>{VillageFinderData?.panchayat_village_name}
                            </span>
                            </p>
                        }
                        {VillageFinderData?.taluk_name != null &&
                            <p className='AddressTxt1'>Taluk Name = <span>{VillageFinderData?.taluk_name}
                            </span>
                            </p>
                        }
                        {VillageFinderData?.district_name != null &&
                            <p className='AddressTxt1'>District Name = <span>{VillageFinderData?.district_name}
                            </span>
                            </p>
                        }
                    </div>
                }
            </div>
        </div>
    ) : <></>;
}

export default React.memo(PickLocation);

