import React from 'react'
import { ThreeCircles } from 'react-loader-spinner'

function Loader1() {
    return (
        <ThreeCircles
            visible={true}
            height="50"
            width="50"
            color="#3786fb"
            ariaLabel="three-circles-loading"
            wrapperStyle={{ flex: 1, justifyContent: 'center' }}
            wrapperClass=""
        />
    )
}

export default Loader1