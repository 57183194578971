import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import '../../css/design3.css';
import Design1 from '../Layout/Design1';
import Design10 from '../Layout/Design10';
import Design2 from '../Layout/Design2';
import Design3 from '../Layout/Design3';
import Design4 from '../Layout/Design4';
import Design5 from '../Layout/Design5';
import Design6 from '../Layout/Design6';
import Design7 from '../Layout/Design7';
import Design8 from '../Layout/Design8';
import Design9 from '../Layout/Design9';


export default function CarouselProperty() {

    return (
        <>
            <div className="container ">
                <div className="row ">
                    <div className="col-sm-12 d-flex ">
                        <div className="style-box"></div>
                        <p className='Featured-listing'>Featured Listing of the Week</p>
                    </div>
                </div>
                <div className="row mb-lg-5">
                    <Carousel>
                        <Carousel.Item>
                            <div className="carousel-item active">
                                <Design1 data="true" />
                            </div>
                        </Carousel.Item  >
                        <Carousel.Item>
                            <div className="carousel-item active">
                                <Design2 data="true" />
                            </div>
                        </Carousel.Item  >
                        {/* <Carousel.Item>
                    <div className="carousel-item active">
                        <Design3 data="true" />
                    </div>
                </Carousel.Item  >
                <Carousel.Item>
                    <div className="carousel-item active">
                        <Design4 data="true" />
                    </div>
                </Carousel.Item  >
                <Carousel.Item>
                    <div className='carosel-item active'>
                        <Design5 data="true" />
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className='carosel-item active'>
                        <Design6 data="true" />
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className='carosel-item active'>
                        <Design7 data="true" />
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className='carosel-item active'>
                        <Design8 data="true" />
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className='carosel-item active'>
                        <Design9 data="true" />
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className='carosel-item active'>
                        <Design10 data="true" />
                    </div>
                </Carousel.Item> */}
                    </Carousel>
                </div>
            </div>
        </>
    )
}
