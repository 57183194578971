import { LEGAL_OPINION_SUCCESS, LEGAL_OPINION_FAIL, LEGAL_OPINION_VIEW, LEGAL_OPINION_INSERT, LEGAL_OPINION_UPDATE, LEGAL_OPINION_DELETE, LEGAL_OPINION_FILE_DOWNLOAD_SUCCESS, SET_LOADING } from "../type/types";

const initialState = {
    loading: true,
    viewList: [],
    insertData: [],
    downloadFileUrl: [],
    error: {}
}

export const LegalOpinionReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_LOADING:
            return {
                ...state,
                loading: payload
            };
        case LEGAL_OPINION_VIEW:
            return {
                ...state,
                loading: false,
                viewList: payload
            }
        case LEGAL_OPINION_INSERT:
            return {
                ...state,
                loading: false,
                insertData: payload
            }
        case LEGAL_OPINION_FILE_DOWNLOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                downloadFileUrl: payload
            }
        case LEGAL_OPINION_FAIL:
            return {
                ...state,
                loading: false,
                error: payload
            }
        default:
            return state;

    }

}