import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

const minDistance = 10;

export default function ExpectedPrice({ selectedValue, minAmount, maxAmount }) {


    const [value, setValue] = useState([minAmount, maxAmount]);

    useEffect(() => {
        setValue([minAmount, maxAmount]);
    }, [minAmount, maxAmount]);


    const handleChange = (event, newValue, activeThumb) => {
        if (!Array.isArray(newValue)) {
            return;
        }

        if (activeThumb === 0) {
            setValue([Math.min(newValue[0], value[1] - minDistance), value[1]]);
        } else {
            setValue([value[0], Math.max(newValue[1], value[0] + minDistance)]);
        }

        selectedValue(newValue);
    };

    function valuetext(value) {
        return `₹${value.toLocaleString('en-IN')}`;
    }

    return (
        <div>
            <label className="text-black">Expected Price<span className="required">*</span> </label>
            <Box sx={{ width: 300, marginTop: 6, marginLeft: 2 }}>
                <Slider
                    getAriaLabel={() => 'Expected Price Range'}
                    value={value}
                    onChange={handleChange}
                    valueLabelDisplay="on"
                    getAriaValueText={valuetext}
                    min={0}
                    max={100000000}
                    step={500000}
                />
            </Box>
        </div>
    );
}
