import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Button from '@mui/material/Button';
import swal from 'sweetalert';
import { useDispatch, useSelector } from "react-redux";
import { getTokenLocalUserStorage } from '../../../../utils/Common';
import { addFmbPattaList, getFmbPattaList, downloadFmbPatta, getSurveyNumber } from '../../../../store/action/FmbPattaAction';
import { useHistory } from 'react-router-dom';
import FmbPattaDetailPage from './FmbPattaDetailPage';
import { IoMdAddCircle } from 'react-icons/io';
import { setLoading } from '../../../../store/action/LoadingAction';
import LoadingButton from '@mui/lab/LoadingButton';
import { CircularProgress } from '@mui/material';

function EditFmbPattaStatus({ item, open, handleClose }) {

    //console.log(`EditFmbPattaStatus item = ${JSON.stringify(item)}`)

    let history = useHistory();
    const dispatch = useDispatch();
    const selector = useSelector(state => state?.FmbPattaReducer);
    const loginUserData = getTokenLocalUserStorage();
    const loginUserId = loginUserData[0].rfId;

    const [sending, setSending] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [errors, setErrors] = useState({});
    const [bodyData, setBodyData] = useState({
        "action": "Insert",
        "rfId": "",
        "userRfId": "",
        "name": "",
        "surveyNumber": "",
        "downloadDocument": "",
        "stateId": "1",
        "districtId": "",
        "districtName": "",
        "talukId": "",
        "talukName": "",
        "villageId": "",
        "villageName": "",
        "surveyStatus": "",
        "address": "",
        "location": "",
        "pincode": "",
        "latitude": "",
        "longitude": "",
        "mailId": "",
        "inputSource": "Android",
        "loginUserId": loginUserId,
        "remarks": ""
    });

    useEffect(() => {
        if (item) {
            setBodyData({
                ...bodyData,
                "rfId": item?.rfId,
                "userRfId": item?.userRfId,
                "name": item?.name,
                "surveyNumber": item?.surveyNumber || "",
                "downloadDocument": item?.downloadDocument || "",
                "districtId": item?.districtId,
                "districtName": item?.districtName,
                "talukId": item?.talukId,
                "talukName": item?.talukName,
                "villageId": item?.villageId,
                "villageName": item?.villageName,
                "surveyStatus": item?.surveyStatus,
                "address": item?.address,
                "location": item?.location,
                "pincode": item?.pincode,
                "latitude": item?.latitude,
                "longitude": item?.longitude,
                "mailId": item?.mailId,
            });
        }
    }, [item]);

    const handleError = (error, input) => {
        setErrors(prevState => ({ ...prevState, [input]: error }));
    };

    const onChange = e => {
        setBodyData({ ...bodyData, [e.target.name]: e.target.value });
    }

    const onChangeUpload = async (name, value) => {
        await setBodyData((prevState) => ({ ...prevState, [name]: value }));
        value.length > 0 && handleError("", name);
    }

    const validateUpdateStatus = async () => {
        let isValid = true;

        if (!bodyData.surveyNumber) {
            handleError('Enter Survey Number', 'surveyNumber');
            isValid = false;
        }
        // if (!bodyData?.downloadDocument) {
        //     handleError('Required', 'downloadDocument');
        //     isValid = false;
        // }
        if (isValid) {
            setSending(true);
            // console.log(`bodyData = ${JSON.stringify(bodyData)}`);
            // await dispatch(addFmbPattaList(bodyData, history));
            await dispatch(downloadFmbPatta(item?.rfId, item?.userRfId, bodyData?.surveyNumber));
            setSending(false);
            handleClose();
            await dispatch(getFmbPattaList());
            // await handleClose();
        }
    }

    const findSurveyHandler1 = async () => {
        const URL = `https://prod-api.mypropertyqr.in/rural/${bodyData.districtName}/${bodyData.talukName}/${bodyData.villageName}/${bodyData.surveyNumber}`;
        await navigator.clipboard.writeText(URL).then(() => {
            swal({
                icon: 'success',
                title: 'URL copied to clipboard',
                text: URL
            });
        });
    }

    const getSurveyNo = async () => {
        await dispatch(getSurveyNumber(bodyData?.latitude, bodyData?.longitude, setProcessing));
    }

    useEffect(() => {
        if (selector?.surveyNumber?.data?.message === "Success") {
            // setFmbPattaList(selector?.surveyNumber?.data?.data);
            console.log(`selector?.surveyNumber?.data = ${JSON.stringify(selector?.surveyNumber?.data?.data?.number)}`)
            setBodyData({
                ...bodyData,
                "surveyNumber": selector?.surveyNumber?.data?.data?.number
            })
        }
    }, [selector?.surveyNumber?.data])

    return (
        <div>
            <Modal
                show={open}
                onHide={handleClose}
                backdrop="true"
                keyboard={false}
                size='lg'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Edit FMB & Patta Application Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='DetailPage-container'>
                        <FmbPattaDetailPage item={item} />
                        <div className="row mt-5 property-form">
                            <div className="col-md-4">
                                {errors.surveyNumber ?
                                    <label htmlFor="surveyNumber" className="error-text">{errors.surveyNumber}</label> :
                                    <label htmlFor="surveyNumber" className="text-black">Survey Number<span className="required">*</span> </label>
                                }
                                <input
                                    type="text"
                                    className="form-control form-input-holder"
                                    id="surveyNumber" name='surveyNumber'
                                    value={bodyData?.surveyNumber}
                                    onChange={e => onChange(e)}
                                    placeholder="Enter Survey Number"
                                    noValidate
                                    onFocus={() => handleError(null, 'surveyNumber')}
                                    disabled={bodyData?.surveyNumber}
                                />
                            </div>
                            {
                                bodyData?.surveyNumber == "" &&
                                <div className="col-md-4 align-content-end mt-4">
                                    <LoadingButton
                                        loading={processing}
                                        onClick={getSurveyNo}
                                        variant="contained"
                                        style={{ backgroundColor: '#3786FB', height: '45px', textTransform: 'none' }}
                                        loadingIndicator={<CircularProgress size={15} sx={{ color: 'white' }} />}
                                    >
                                        Get Survey No
                                    </LoadingButton>
                                </div>
                            }

                            {bodyData?.surveyNumber && (
                                <div className="col-md-4 align-content-end mt-4">
                                    <Button
                                        variant="contained"
                                        onClick={findSurveyHandler1}
                                        size="large"
                                        style={{ textTransform: 'none', color: "white", backgroundColor: '#3786FB', height: '45px', }} >
                                        Verify Survey No
                                    </Button>
                                </div>
                            )}
                            {/* <div className='upload-conatiner-postProperty'>
                                <UploadFile
                                    fileOnSet={onChangeUpload}
                                    name="downloadDocument"
                                    error={errors?.downloadDocument}
                                    editData={bodyData?.downloadDocument}
                                >
                                    Upload FMB & Patta
                                </UploadFile>
                            </div> */}
                        </div>

                        <div className="col-md-12 text-center my-5">
                            {/* <button className="btn btn-primary px-5" >Send</button> */}
                            <LoadingButton
                                loading={sending}
                                onClick={() => validateUpdateStatus()}
                                variant="contained"
                                style={{ backgroundColor: '#3786FB', height: '45px', textTransform: 'none' }}
                                loadingIndicator={<CircularProgress size={15} sx={{ color: 'white' }} />}
                            >
                                Get FMB & Patta
                            </LoadingButton>
                        </div>


                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default EditFmbPattaStatus;
