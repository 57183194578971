import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import Modal from 'react-bootstrap/Modal';
import '../../css/design1.css';
import { useEffect } from 'react';
import { getLayoutRegistrationData } from "../../store/action/LayoutAction";
import { commonFilter } from "../../utils/Common";
import { Link } from 'react-router-dom';

export default function Design1({ data }) {

    const dispatch = useDispatch();
    const selector = useSelector(state => state?.LayoutReducer);
    const [show, setShow] = useState(false);
    const [modelData, setModelData] = useState({});
    const [layoutData, setLayoutData] = useState([]);

    const handleClose = () => setShow(false);
    const handleShow = (modelLayoutData) => {
        setModelData(modelLayoutData)
        setShow(true);
    }

    const design1 = {
        layout1: [
            {
                itemNo: 'item1',
                box_number: 1,
                boxSize_t: '49.9"',
                boxSize_r: '36.3"',
                boxSize_l: '28.6"',
                boxSize_b: '39.0"',
            },
            {
                itemNo: 'item2',
                box_number: 2,
                boxSize_r: '36.3"',
                boxSize_b: '39.0"',
                boxSize_l: '35.0"',
            },
            {
                itemNo: 'item3',
                box_number: 3,
                boxSize_r: '40.0"',
                boxSize_b: '32.6"',
                boxSize_l: '35.0"',
            },
            {
                itemNo: 'item4',
                box_number: 4,
                boxSize_r: '36.3"',
                boxSize_b: '29.0"',
            },
            {
                itemNo: 'item5',
                box_number: 5,
                boxSize_r: '12.0"',
            },
        ],
        layout2: [
            {
                itemNo: 'item6',
                box_number: 6,
                boxSize_t: '40.0"',
                boxSize_b: '34.6"',
                boxSize_l: '35.0"',
            },
            {
                itemNo: 'item7',
                box_number: 7,
                boxSize_t: '40.0"',
                boxSize_b: '34.6"',
                boxSize_l: '35.0"',
            },
            {
                itemNo: 'item8',
                box_number: 8,
                boxSize_t: '40.0"',
                boxSize_b: '34.6"',
                boxSize_l: '35.0"',
            },
            {
                itemNo: 'item9',
                box_number: 9,
                boxSize_t: '40.0"',
                boxSize_b: '34.6"',
                boxSize_l: '35.0"',
            },
            {
                itemNo: 'item10',
                box_number: 10,
                boxSize_t: '40.0"',
                boxSize_b: '34.6"',
                boxSize_l: '35.0"',
            },
            {
                itemNo: 'item11',
                box_number: 11,
                boxSize_t: '40.0"',
                boxSize_b: '34.6"',
                boxSize_l: '35.0"',
            },

        ],
        layout3: [
            {
                itemNo: 'item12',
                box_number: 12,
                boxSize_t: '40.0"',
                boxSize_b: '34.6"',
                boxSize_l: '35.0"',
            },
            {
                itemNo: 'item13',
                box_number: 13,
                boxSize_t: '40.0"',
                boxSize_b: '34.6"',
                boxSize_l: '35.0"',
            },
            {
                itemNo: 'item14',
                box_number: 14,
                boxSize_t: '40.0"',
                boxSize_b: '34.6"',
                boxSize_l: '35.0"',
            },
            {
                itemNo: 'item15',
                box_number: 15,
                boxSize_t: '40.0"',
                boxSize_b: '34.6"',
                boxSize_l: '35.0"',
            },
            {
                itemNo: 'item16',
                box_number: 16,
                boxSize_t: '40.0"',
                boxSize_b: '34.6"',
                boxSize_l: '35.0"',
            },
            {
                itemNo: 'item17',
                box_number: 17,
                boxSize_t: '40.0"',
                boxSize_b: '34.6"',
                boxSize_l: '35.0"',
            },
            {
                itemNo: 'item18',
                box_number: 18,
                boxSize_t: '40.0"',
                boxSize_b: '34.6"',
                boxSize_l: '35.0"',
            },
            {
                itemNo: 'item19',
                box_number: 19,
                boxSize_t: '40.0"',
                boxSize_b: '34.6"',
                boxSize_l: '35.0"',
            },
            {
                itemNo: 'item20',
                box_number: 20,
                boxSize_t: '40.0"',
                boxSize_b: '34.6"',
                boxSize_l: '35.0"',
            },
            {
                itemNo: 'item21',
                box_number: 21,
                boxSize_t: '40.0"',
                boxSize_b: '34.6"',
                boxSize_l: '35.0"',
            },
            {
                itemNo: 'item22',
                box_number: 22,
                boxSize_t: '40.0"',
                boxSize_b: '34.6"',
                boxSize_l: '35.0"',
            },
            {
                itemNo: 'item23',
                box_number: 23,
                boxSize_t: '40.0"',
                boxSize_b: '34.6"',
                boxSize_l: '35.0"',
            },
        ],
    }

    // console.log(`data - ${ JSON.stringify(design1) }`)

    return (
        <>
            {
                data === "true" && <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="true"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Land Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Site Identification No: {modelData?.box_number} <br />
                        Site Size Details:{modelData?.boxSize_t} {modelData?.boxSize_r} {modelData?.boxSize_b} {modelData?.boxSize_l}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
            <div className="container">
                <div className="">
                    <div className='design-1'>
                        <div className='main_layout'>
                            <div className='layout_1'>
                                {
                                    design1.layout1.map((data, index) => {
                                        return (<>
                                            <button key={index}
                                                className={`${data.itemNo} design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxSize_t ? "boxSize_t" : ""}> {data.boxSize_t ? data.boxSize_t : ""}</div>
                                                <div className={data.boxSize_r ? "boxSize_r" : ""}> {data.boxSize_r ? data.boxSize_r : ""}</div>
                                                <div className={data.boxSize_b ? "boxSize_b" : ""}> {data.boxSize_b ? data.boxSize_b : ""}</div>
                                                <div className={data.boxSize_l ? "boxSize_l" : ""}> {data.boxSize_l ? data.boxSize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                            </div>
                            <div className='layout_2'>
                                {
                                    design1.layout2.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemNo} design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxSize_t ? "boxSize_t" : ""}> {data.boxSize_t ? data.boxSize_t : ""}</div>
                                                <div className={data.boxSize_b ? "boxSize_b" : ""}> {data.boxSize_b ? data.boxSize_b : ""}</div>
                                                <div className={data.boxSize_l ? "boxSize_l" : ""}> {data.boxSize_l ? data.boxSize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                            </div>
                            <div className="roadcross1"></div>
                            <div className='layout_3'>
                                {
                                    design1.layout3.map((data, index) => {
                                        return (<>
                                            <button key={index} className={`${data.itemNo} design1-btn`} onClick={() => handleShow(data)}>
                                                <div className={data.box_number ? "box_number" : ""}> {data.box_number ? data.box_number : ""} </div>
                                                <div className={data.boxSize_t ? "boxSize_t" : ""}> {data.boxSize_t ? data.boxSize_t : ""}</div>
                                                <div className={data.boxSize_b ? "boxSize_b" : ""}> {data.boxSize_b ? data.boxSize_b : ""}</div>
                                                <div className={data.boxSize_l ? "boxSize_l" : ""}> {data.boxSize_l ? data.boxSize_l : ""}</div>
                                            </button>
                                        </>
                                        )
                                    })
                                }
                                <div className="roadcross3"></div>
                                <div className="roadcross2"></div>
                            </div>

                            <div className='road_1'>
                                <div className='left'>7.2 M. ROAD </div>
                                <div className='right'>7.2 M. ROAD </div>
                            </div>
                            <div className='road_2'>
                                10.0 M. ROAD
                            </div>
                            <div className='road_3'>
                                7.2 M. ROAD
                            </div>
                            <div className='whitebox1'>
                            </div>
                            <div className='whitebox2'>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mb-lg-4">
                <div className="col-lg-9 col-md-12 site-details-info">
                    <div className="row text-left pl-3">
                        <div className="col">
                            <h6>Budget</h6>
                            <p>₹ 4100 / Sq.Ft</p>
                        </div>
                        <div className="col">
                            <h6>Size</h6>
                            <p>2600 <span>&#13217;</span></p>
                        </div>
                        <div className="col">
                            <h6>Type</h6>
                            <p>Home</p>
                        </div>
                        <div className="col">
                            <h6>Status</h6>
                            <p>Available</p>
                        </div>
                        <div className="col">
                            <h6>Location</h6>
                            <p>Salem</p>
                        </div>
                    </div>

                </div>
                <div className="col-lg-3  col-md-12 col-sm-12 mb-sm-3 mb-md-5 pr-md-3">
                    <Link to={'/Login-page'}>
                        <button type="button" className='btn btn-primary'>YES! I WANT TO BOOK</button>
                    </Link>
                </div>
            </div>

        </>
    )
}
