import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { TiArrowBack } from 'react-icons/ti';
import { IoMdAddCircle } from 'react-icons/io';
import { getDistrictData, getBindMemberSingleData } from '../../../../store/action/BindAction';
import UploadFile from '../Others/UploadFile';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from "react-select";
import Modal from 'react-bootstrap/Modal';
import AddOwner from '../LandOwners/AddOwner';
import PickLocation from '../Others/PickLocation';
import { addVastuPlan } from '../../../../store/action/VastuPlanAction';
import LoadingButton from '@mui/lab/LoadingButton';
import { CircularProgress } from '@mui/material';

function AddVastuPlan({ data }) {

    let history = useHistory();
    const dispatch = useDispatch();
    const selector = useSelector(state => state?.BindReducer);

    const [sending, setSending] = useState(false);
    const [errors, setErrors] = useState({});
    const [districtData, setDistrictData] = useState([]);
    const [memberData, setMemberData] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [showAddLandOwnerModel, setShowAddLandOwnerModel] = useState(false);
    const [showMapModel, setShowMapModel] = useState(false);
    const [bodyData, setBodyData] = useState({
        "action": "Insert",
        "rfId": data?.rfId || "",
        "userRfId": data?.userRfId || "",
        "name": data?.name || "",
        "siteDrawing": data?.siteDrawing || "",
        "downloadDocument": "",
        "stateId": "1",
        "districtId": "",
        "districtName": "",
        "talukId": "",
        "talukName": "",
        "villageId": "",
        "villageName": "",
        "address": "",
        "location": "",
        "pincode": "",
        "latitude": "",
        "longitude": "",
        "mailId": "",
        "inputSource": "Android",
        "loginUserId": "1",
        "remarks": ""
    })

    useEffect(() => {
        dispatch(getDistrictData(1));
        dispatch(getBindMemberSingleData())
    }, []);

    useEffect(() => {
        if (selector?.districtData?.data?.alert == "District Details") {
            setDistrictData(selector?.districtData?.data?.data)
        }
        if (selector?.memberData?.data?.alert === "Real Estate Member Details") {
            let a = {}
            // setMemberData(selector?.memberData?.data?.data)
            setMemberData(selector?.memberData?.data?.data?.map((res, index) => {
                if (res.rfId == userRfId) {
                    a.label = res.combines;
                    a.value = res.rfId;
                    a.mobileNo = res.mobileNo;
                    a.rfId = res.rfId;
                }
                return {
                    label: res.combines,
                    value: res.rfId,
                    mobileNo: res.mobileNo,
                    rfId: res.rfId
                }
            }))
            data != "" && setSelectedOption(a)
        }
    }, [selector?.districtData?.data, selector?.memberData?.data])

    //console.log(`Member Data = ${JSON.stringify(memberData)}`)

    const onChangeLocation = (lat, lng, address, pincode, districtId, location) => {
        setBodyData({ ...bodyData, address: address, pincode: pincode, districtId: districtId, latitude: lat, longitude: lng, location: location });
    }

    const onChange = e => {
        setBodyData({ ...bodyData, [e.target.name]: e.target.value });
    }

    const handleError = (error, input) => {
        setErrors(prevState => ({ ...prevState, [input]: error }));
    };

    const { rfId, userRfId, name, address, location, districtId, pincode } = bodyData;

    const validate = (e) => {
        e.preventDefault();
        let isValid = true;

        if (!bodyData?.userRfId) {
            handleError('Select Landowner Name', 'userRfId');
            isValid = false;
        }

        if (!bodyData?.name || bodyData?.name.length < 3) {
            handleError('Name must be min 3 character long', 'name');
            isValid = false;
        }
        if (!bodyData?.address) {
            handleError('Enter address', 'address');
            isValid = false;
        }

        if (!bodyData?.location) {
            handleError('Enter location', 'location');
            isValid = false;
        }

        if (!bodyData?.districtId) {
            handleError('Select District', 'districtId');
            isValid = false;
        }

        if (
            !bodyData?.pincode ||
            isNaN(bodyData?.pincode) ||
            (bodyData?.pincode.toString().length !== 6 ||
                bodyData?.pincode.toString().includes('.'))
        ) {
            handleError('Enter Pincode', 'pincode');
            isValid = false;
        }

        if (!bodyData?.siteDrawing) {
            handleError('Required', 'siteDrawing');
            isValid = false;
        }

        if (isValid) {
            handleAddFormSubmit();
        }
    }

    //console.log(`body DATA = ${JSON.stringify(bodyData)}`)

    const handleAddFormSubmit = async () => {
        await dispatch(addVastuPlan(bodyData, history, setSending))
    }

    const onChangeDistrict = async (districtId) => {
        setBodyData({ ...bodyData, districtId: districtId });
    };

    const onChangeUpload = async (name, value) => {
        await setBodyData((prevState) => ({ ...prevState, [name]: value }))
        value.length > 0 && handleError("", name);
    }

    return (
        <>
            <div className='d-flex mt-2'>
                <Button className='p-0'><TiArrowBack onClick={() => history.goBack()} size={35} /></Button>
                <p className='listings-headingWithBack'>{bodyData?.rfId !== "" ? "Update Vastu plan" : "Add Vastu plan"}</p>
            </div>

            <form className="row g-3 property-form ml-2 mr-2 mt-3"
                noValidate
            >
                <div className="col-md-5">
                    {errors.userRfId ?
                        <label htmlFor="userRfId" className="error-text required">{errors.userRfId}</label> :
                        <label htmlFor="userRfId" className="text-black">Land Owner Name<span className="required">*</span> </label>
                    }
                    <Select
                        defaultValue={data?.userRfId == "" ? selectedOption : data?.userRfId}
                        onChange={(selectedOption) => setBodyData({ ...bodyData, userRfId: selectedOption.value })}
                        options={memberData}
                        onFocus={() => handleError(null, 'userRfId')}
                        className='Landowner-select-container'
                    />
                </div>
                <div className="col-md-1 btn-box" >
                    <Button className='btn btn-add' onClick={() => setShowAddLandOwnerModel(!showAddLandOwnerModel)}>
                        <IoMdAddCircle size={23} color='white' />
                    </Button>
                </div>
                <div className="col-md-6">
                    {errors.name ?
                        <label htmlFor="name" className="error-text required">{errors.name}</label> :
                        <label htmlFor="name" className="text-black">Land Name<span className="required">*</span> </label>
                    }
                    <input
                        type="text"
                        className="form-control form-input-holder"
                        id="name" name='name'
                        value={name}
                        onChange={e => onChange(e)}
                        placeholder="Enter Land Name"
                        noValidate
                        onFocus={() => handleError(null, 'name')}
                    />
                </div>
                <div className="col-md-4">
                    {errors.address ?
                        <label htmlFor="address" className="error-text">{errors.address}</label> :
                        <label htmlFor="address" className="text-black">Address<span className="required">*</span> </label>
                    }
                    <input
                        type="text"
                        className="form-control form-input-holder"
                        id="address" name='address'
                        value={address}
                        onChange={e => onChange(e)}
                        placeholder="Enter Address"
                        noValidate
                        onFocus={() => handleError(null, 'address')}
                    />
                </div>
                <div className="col-md-2 btn-box">
                    <button type="button" className="btn btn-info btn-pickLocation" onClick={() => setShowMapModel(!showMapModel)}>
                        Pick Location
                    </button>
                </div>

                <div className="col-md-6">
                    {errors.location ?
                        <label htmlFor="location" className="error-text">{errors.location}</label> :
                        <label htmlFor="location" className="text-black">Location<span className="required">*</span> </label>
                    }
                    <input
                        type="text"
                        className="form-control form-input-holder"
                        id="location" name='location'
                        value={location}
                        onChange={e => onChange(e)}
                        placeholder="Location"
                        noValidate
                        onFocus={() => handleError(null, 'location')}
                    />
                </div>
                <div className="col-md-4">
                    {errors.districtId ?
                        <label htmlFor="districtId" className="error-text required">{errors.districtId}</label> :
                        <label htmlFor="districtId" className="text-black">District<span className="required">*</span> </label>
                    }
                    <select className="form-select" name="districtId" style={{ fontSize: '14px' }}
                        value={bodyData?.districtId}
                        onChange={e => onChangeDistrict(e.target.value)}
                        onFocus={() => handleError(null, 'districtId')}>
                        <option value=""> Select District </option>
                        {
                            districtData.length > 0 && districtData?.map((data, index) => {
                                return <option key={index} value={data.rfId}> {data.districtName} </option>
                            })
                        }
                    </select>
                </div>
                <div className="col-md-2">
                    {errors.pincode ?
                        <label htmlFor="pincode" className="error-text">{errors.pincode}</label> :
                        <label htmlFor="pincode" className="text-black">Pincode<span className="required">*</span> </label>
                    }
                    <input
                        type="text"
                        className="form-control form-input-holder"
                        id="pincode" name='pincode'
                        value={pincode}
                        onChange={e => onChange(e)}
                        placeholder="Enter Pincode"
                        noValidate
                        onFocus={() => handleError(null, 'pincode')}
                    />
                </div>
                <div className='upload-conatiner-postProperty'>
                    <UploadFile
                        fileOnSet={onChangeUpload}
                        name="siteDrawing"
                        error={errors?.siteDrawing}
                    >
                        Upload Site Drawing
                    </UploadFile>
                </div>
                <Modal
                    show={showMapModel}
                    onHide={() => setShowMapModel(!showMapModel)}
                    backdrop="true"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title">Google Map</h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <PickLocation onChangeLocation={onChangeLocation} districtData={districtData} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => setShowMapModel(!showMapModel)}>
                            Confirm Location
                        </Button>
                        <Button variant="secondary" onClick={() => setShowMapModel(!showMapModel)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={showAddLandOwnerModel}
                    onHide={() => setShowAddLandOwnerModel(!setShowAddLandOwnerModel)}
                    backdrop="true"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title">Add Landowner</h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AddOwner setShowAddLandOwnerModel={setShowAddLandOwnerModel} />
                    </Modal.Body>
                    <Modal.Footer>
                        {/* <Button variant="primary" onClick={() => setShowAddLandOwnerModel(!setShowAddLandOwnerModel)}>
                            Add
                        </Button> */}
                        <Button variant="secondary" onClick={() => setShowAddLandOwnerModel(!setShowAddLandOwnerModel)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <div className="col-md-12 text-center my-5">
                    <LoadingButton
                        loading={sending}
                        onClick={validate}
                        variant="contained"
                        sx={{ px: 6 }}
                        style={{ backgroundColor: '#3786FB', height: '45px', textTransform: 'none' }}
                        loadingIndicator={<CircularProgress size={15} sx={{ color: 'white' }} />}
                    >
                        {bodyData?.rfId !== "" ? "Update Vastu plan" : "Add Vastu plan"}
                    </LoadingButton>
                </div>
            </form>
        </>
    )
}

export default AddVastuPlan