import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import { BiSolidDashboard } from "react-icons/bi";
import { FaBars, FaClipboardList, FaRegWindowClose, FaUserCircle, FaUserTie, FaTh, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { LuArrowBigLeft, LuArrowBigRight } from "react-icons/lu";
import { IoIosPeople, IoMdSettings } from 'react-icons/io';
import { MdPayments } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from "../../../store/action/AuthActions";
import { getTokenLocalUserStorage } from '../../../utils/Common';
import { NavLink, useHistory, withRouter } from 'react-router-dom';
import AppLogo from '../../../images/website-logo.png';
import BuildingApproval from '../../../images/Services/buildingapproval.png';
import finder1 from '../../../images/Services/finder1.png';
import propertyNeed from '../../../images/Services/propertyNeed.png';
import legalOpinion from '../../../images/Services/notaryClearance.png';
import landSurvey from '../../../images/Services/survey.png';
import Property from '../../../images/Services/Property.png';
import agent from '../../../images/Services/agent.png';
import consultation from '../../../images/Services/consultation.png';
import docWriter from '../../../images/Services/docWriter.png';
import fmbPatta from '../../../images/Services/fmbPatta.png';
import map from '../../../images/Services/map.png'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MdLibraryBooks } from "react-icons/md";
import { getDashboardData } from '../../../store/action/DashboardAction';
import VastuPlan from '../../../images/Services/vastuPlan.png'

const AdminMaincontent = ({ children, location }) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const selector = useSelector(state => state?.DashboardReducer);

    const [dashBoardData, setDashboardData] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        dispatch(getDashboardData())
    }, [])


    useEffect(() => {
        if (selector?.payload?.data?.alert === "Real Estate Dashboard Details") {
            setDashboardData(selector?.payload?.data?.data)
        }

    }, [selector?.payload?.data]);

    const toggle = () => setIsOpen(!isOpen);

    const menuItem1 = [
        {
            path: "/Dashboard",
            name: "Dashboard",
            icon: <FaTh />,
        },
        {
            path: "/BookingsHome",
            name: "Bookings",
            icon: <MdLibraryBooks size={25} />
        },
        {
            path: "/LandOwners",
            name: "LandOwners",
            icon: <FaUserTie size={22} />
        },
        {
            path: "/Staff",
            name: "Staff",
            icon: <IoIosPeople size={27} />
        },
        {
            path: "/Payments",
            name: "Payment",
            icon: <MdPayments size={24} />
        },
        {
            path: "/Settings",
            name: "Settings",
            icon: <IoMdSettings size={25} />
        }
    ];

    const menuItem2 = [
        {
            path: "/Property",
            name: "Property",
            icon: Property,
            count: dashBoardData[0]?.plotsCount + dashBoardData[0]?.landCount + dashBoardData[0]?.houseCount
        },
        {
            path: "/NeedProperty",
            name: "I Need Property",
            icon: propertyNeed,
            count: dashBoardData[0]?.needProperty
        },
        {
            path: "/LandSurvey",
            name: "Land Survey",
            icon: landSurvey,
            count: dashBoardData[0]?.landSurvey
        },
        {
            path: "/LegalOpinion",
            name: "Legal Opinion",
            icon: legalOpinion,
            count: dashBoardData[0]?.legalOpinion
        },
        {
            path: "/Fmb_Patta",
            name: "FMB & Patta",
            icon: fmbPatta,
            count: dashBoardData[0]?.pattaFmb
        },
        {
            path: "/BuildingApproval",
            name: "Building Approval",
            icon: BuildingApproval,
            count: dashBoardData[0]?.buildingApproval
        },
        {
            path: "/VastuPlan",
            name: "Vastu Plan",
            icon: VastuPlan,
            count: dashBoardData[0]?.vasthuApproval
        },
        {
            path: "/WriterHome",
            name: "Document Writer",
            icon: docWriter,
            count: dashBoardData[0]?.documentWritterCount
        },
        {
            path: "/AgentHome",
            name: "Agent",
            icon: agent,
            count: dashBoardData[0]?.agentCount
        },
        {
            path: "/ConsultantHome",
            name: "Consultant",
            icon: consultation,
            count: dashBoardData[0]?.consultantCount
        },

        {
            path: "/VillageFinder",
            name: "Village Finder",
            icon: finder1,
        },
        {
            path: "/PlotFinderHome",
            name: "Map",
            icon: map,

        }
    ];

    useEffect(() => {
        history.push('/Property');
    }, [history]);

    const loginUserData = getTokenLocalUserStorage();

    const updateWindowSize = () => {
        if (window.innerWidth <= 768) {
            setIsOpen(false);
        } else {
            setIsOpen(true);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', updateWindowSize);
        return () => {
            window.removeEventListener('resize', updateWindowSize);
        };
    }, []);

    const LogoutHandler = () => {
        dispatch(logout(history, 0));
    };

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", background: "red" }}
                onClick={onClick}
            >
                <LuArrowBigRight size={45} color='#229efd' />
                <div className="link_text3">Next</div>
            </div>
        );
    }

    var settings = {
        dots: true,
        infinite: false,
        slidesToShow: 10,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <div className='AdminNavigation'>
                <div className="top_section">
                    <div className='AdminHeader-Container'>
                        <img src={AppLogo} style={{ background: 'white' }} alt="My Property" className='logo' />
                        <FaUserCircle size={35} color='yellow' style={{ marginLeft: 10 }} />
                        <div className='userNameContainer'>
                            <p className='userName'>{loginUserData[0].userName}</p>
                            <p className='userName-mobile'>{loginUserData[0].mobileNo}</p>
                        </div>
                    </div>
                    <div className='d-flex flex-row justify-content-end flex-1'>
                        <nav className="navbar">
                            <form className="form-inline">
                                <input className="form-control mr-sm-2" type="search" placeholder="Search" />
                                <button className="btn btn-light my-sm-0" type="submit">Search</button>
                            </form>
                        </nav>
                        <div className='AdminHeader-Container'>
                            <div className='userNameContainer'>
                                <p className='userName'>All</p>
                                {/* <p className='userName-mobile'>{loginUserData[0].mobileNo}</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="AdminNavigation">

                <div className='AdminNavigation-Container p-0'>
                    <div>
                        <div className="bars">
                            {isOpen ? <FaRegWindowClose onClick={toggle} style={{ width: 28, height: 28 }} /> : <FaBars onClick={toggle} />}
                        </div>
                        <div style={{ width: isOpen ? "250px" : "50px", display: isOpen ? 'block' : 'none' }} className="sidebar">

                            <div className='NavLink-Container'>
                                {
                                    menuItem1.map((item, index) => (
                                        <NavLink to={item.path} key={index} className="link" activeClassName="active" style={{ borderWidth: 1 }}>
                                            <div className="icon">{item.icon}</div>
                                            <div style={{ display: isOpen ? "block" : "none" }} className="link_text">{item.name}</div>
                                        </NavLink>
                                    ))
                                }
                            </div>

                            <div className='logoutBtn-Container'>
                                <Button className='logoutBtn' style={{ textTransform: 'none' }} onClick={LogoutHandler}>Logout</Button>
                            </div>
                        </div>
                    </div>
                    <main style={{ width: isOpen ? '90%' : '100%' }}>
                        <div className="NavLink-Container2">
                            <Slider {...settings}>
                                {
                                    menuItem2.map((item, index) => {
                                        const isActive = location.pathname.startsWith(item.path);
                                        return (
                                            <NavLink
                                                to={item.path}
                                                key={index}
                                                className={`link2 ${isActive ? 'active' : ''}`}
                                                activeClassName="active"
                                            >
                                                <img src={item.icon} style={{ width: 45, height: 45, alignSelf: 'center' }} alt="My Property" className='Services' />
                                                <div className="link_text2">{item.name}</div>
                                                <div style={{ color: '#3786FB', fontWeight: 'bold' }}>{item.count}</div>
                                            </NavLink>
                                        );
                                    })
                                }
                            </Slider>
                        </div>
                        <div style={{ paddingTop: 10, marginRight: 25 }} >
                            {children}
                        </div>
                    </main>
                </div>
            </div>
        </>
    );
};

export default withRouter(AdminMaincontent);
